import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Box, Checkbox, HStack, Text } from '@chakra-ui/react'

import { LENGTH_UNITS, SIZE_UNITS } from '../../../shared/constants/common'
import { Dropdown } from '../../../shared/components'
import { FormikInput } from '../../../components/elements/input'
// import { Checkbox } from '../../../components/elements/checkbox'
import styles from '../detailPage.module.css'
import { APP_PATHS } from 'paths'
import { Link } from 'react-router-dom'

export const LastStep = ({ formik, fieldErrors }) => {
  const [sizeUnitIndex, setSizeUnitIndex] = useState(0)
  const [lengthUnitIndex, setLengthUnitIndex] = useState(0)

  const handleLengthChange = (e) => {
    const inputLength = e.target.value
    if (!inputLength) {
      formik.setFieldValue('initialHeightFormatted', null)
      formik.setFieldValue('initialHeight', null)
      return
    }
    const lengthUnit = LENGTH_UNITS[lengthUnitIndex]
    formik.setFieldValue('initialHeightFormatted', +inputLength)
    formik.setFieldValue('initialHeight', inputLength / lengthUnit.multiplier)
  }

  const toggleLengthUnit = () => {
    const nextIndex = (lengthUnitIndex + 1) % LENGTH_UNITS.length
    const lengthUnit = LENGTH_UNITS[lengthUnitIndex]
    const nextLengthUnit = LENGTH_UNITS[nextIndex]
    formik.setFieldValue(
      'initialHeightFormatted',
      (get(formik.values, 'initialHeightFormatted') * nextLengthUnit.multiplier) /
        lengthUnit.multiplier
    )
    setLengthUnitIndex(nextIndex)
  }

  const handleSizeChange = (e, field) => {
    const inputSize = e.target.value
    if (!inputSize) {
      formik.setFieldValue(`${field}Formatted`, null)
      formik.setFieldValue(field, null)
      return
    }
    const sizeUnit = SIZE_UNITS[sizeUnitIndex]
    formik.setFieldValue(`${field}Formatted`, +inputSize)
    formik.setFieldValue(field, inputSize / sizeUnit.multiplier)
  }

  const toggleSizeUnit = () => {
    const nextIndex = (sizeUnitIndex + 1) % SIZE_UNITS.length
    const sizeUnit = SIZE_UNITS[sizeUnitIndex]
    const nextSizeUnit = SIZE_UNITS[nextIndex]
    formik.setFieldValue(
      'targetWeightFormatted',
      (get(formik.values, 'targetWeightFormatted') * nextSizeUnit.multiplier) /
        sizeUnit.multiplier
    )
    formik.setFieldValue(
      'initialWeightFormatted',
      (get(formik.values, 'initialWeightFormatted') * nextSizeUnit.multiplier) /
        sizeUnit.multiplier
    )
    setSizeUnitIndex(nextIndex)
  }

  return (
    <>
      <Dropdown
        name="gender"
        label="Sex Assigned At Birth"
        required
        showLabel
        options={[
          {
            key: 'MALE',
            text: 'Male',
          },
          { key: 'FEMALE', text: 'Female' },
        ]}
        value={formik.values.gender}
        errorMessage={fieldErrors?.gender || formik.errors.gender}
        onChange={(value) => formik.setFieldValue('gender', value)}
      />
      <FormikInput
        name="prefGenderPronouns"
        label="Preferred Gender Pronouns"
        type="text"
        formik={formik}
        errors={fieldErrors}
        color="white"
      />
      <div className={styles.plitka}>
        <HStack alignItems="flex-start">
          <FormikInput
            label="Current Weight"
            placeholder={SIZE_UNITS[sizeUnitIndex].label}
            name="initialWeightFormatted"
            type="number"
            formik={formik}
            required={true}
            onChange={(e) => handleSizeChange(e, 'initialWeight')}
            errors={{ ...fieldErrors, initialWeightFormatted: formik.errors.initialWeight }}
            color="white"
          />
          <Box
            borderRadius="10px"
            bgColor="var(--accent)"
            w="50px"
            h="44px"
            lineHeight="40px"
            color="white"
            fontSize="20px"
            cursor="pointer"
            userSelect="none"
            mt="1.8rem !important"
            onClick={() => toggleSizeUnit()}
          >
            {SIZE_UNITS[sizeUnitIndex].label}
          </Box>
        </HStack>
        <HStack alignItems="flex-start">
          <FormikInput
            label="Target Weight"
            placeholder={SIZE_UNITS[sizeUnitIndex].label}
            name="targetWeightFormatted"
            type="number"
            formik={formik}
            required={true}
            onChange={(e) => handleSizeChange(e, 'targetWeight')}
            errors={{ ...fieldErrors, targetWeightFormatted: formik.errors.targetWeight }}
            color="white"
          />
          <Box
            borderRadius="10px"
            bgColor="var(--accent)"
            w="50px"
            h="44px"
            lineHeight="40px"
            color="white"
            fontSize="20px"
            cursor="pointer"
            userSelect="none"
            mt="1.8rem !important"
            onClick={() => toggleSizeUnit()}
          >
            {SIZE_UNITS[sizeUnitIndex].label}
          </Box>
        </HStack>
        <HStack alignItems="flex-start">
          <FormikInput
            label="Current Height"
            placeholder={LENGTH_UNITS[lengthUnitIndex].label}
            name="initialHeightFormatted"
            type="number"
            formik={formik}
            required={true}
            onChange={(e) => handleLengthChange(e)}
            errors={{ ...fieldErrors, initialHeightFormatted: formik.errors.initialHeight }}
            color="white"
          />
          <Box
            borderRadius="10px"
            bgColor="var(--accent)"
            w="50px"
            h="44px"
            lineHeight="40px"
            color="white"
            fontSize="20px"
            cursor="pointer"
            userSelect="none"
            mt="1.8rem !important"
            onClick={() => toggleLengthUnit()}
          >
            {LENGTH_UNITS[lengthUnitIndex].label}
          </Box>
        </HStack>
      </div>
      <HStack pt={'20px'}>
        <Text fontSize={'16px'} lineHeight={'20px'} color={'#4F5B76'}>
          Click to accept Our{' '}
          <Link
            to={APP_PATHS.termsAndConditions}
            target="__blank"
            style={{ textDecoration: 'underline', fontWeight: '700' }}
          >
            Terms and Conditions
          </Link>
        </Text>
        <Checkbox
          name="acceptedTermsVersion"
          size="lg"
          colorScheme="pink"
          borderColor={'var(--accent)'}
          required={true}
          checked={formik.values.acceptedTermsVersion}
          onChange={(event) => {
            formik.setFieldValue('acceptedTermsVersion', +event.target.checked)
          }}
          sx={{
            'span.chakra-checkbox__control': {
              borderRadius: '5px',
            },
          }}
        />
      </HStack>
      <HStack>
        <Text fontSize={'16px'} lineHeight={'20px'} color={'#4F5B76'}>
          I agree to receive news and offers.
        </Text>
        <Checkbox
          name="isNewslettersOn"
          size="lg"
          colorScheme="pink"
          borderColor={'var(--accent)'}
          isChecked={formik.values.isNewslettersOn}
          onChange={(event) => {
            formik.setFieldValue('isNewslettersOn', event.target.checked)
          }}
          sx={{
            'span.chakra-checkbox__control': {
              borderRadius: '5px',
            },
          }}
        />
      </HStack>
    </>
  )
}

LastStep.propTypes = {
  formik: PropTypes.object.isRequired,
}
