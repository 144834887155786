import { APP_PATHS } from '../../paths'

export const MetaTitlesMap = {
  [APP_PATHS.contact]: 'Contact',
  [APP_PATHS.faq]: 'FAQ',
  [APP_PATHS.treatmentPlans]: 'Treatment Plans',
  [APP_PATHS.howitWorks]: 'How it Works',
  [APP_PATHS.whyMaion]: 'Why Maion',
  [APP_PATHS.orderPayment]: 'Order Payment',
  [APP_PATHS.orderSubscription]: 'Order Subscription',
  [APP_PATHS.physician]: 'Physician',
  [APP_PATHS.newAppointment]: 'New Appointment',
  [APP_PATHS.myProfile]: 'Profile',
  [APP_PATHS.basket]: 'Basket',
  [APP_PATHS.registration]: 'Registration',
  [APP_PATHS.schedule]: 'Schedule',
  [APP_PATHS.referrals]: 'Referrals',
  [APP_PATHS.termsAndConditions]: 'Terms And Conditions',
  [APP_PATHS.authWeb2Provider]: 'Auth',
}

export const MetaKeywordsList = [
  'Maion',
  'semaglutide',
  'tirzepatide',
  'GLP-1',
  'weight loss',
  'USP797',
  'sterile pharmacy',
  'health',
  'healthcare',
  'pharmacy',
  'care',
  'subscription',
  'prescription',
  'medication',
  'free delivery',
  'delivery',
  'support',
  'personalized health solutions',
  'metabolic health',
  'advanced weight loss',
  'obesity treatment',
  'medical weight management',
  'innovative health solutions',
  'wellness optimization',
  'body composition improvement',
  'hormone-based weight loss',
  'metabolic therapy',
  'sustainable weight management',
  'pharmaceutical weight loss',
]

export const MetaDescription = `Maion is a pioneering healthcare company dedicated to transforming weight loss through advanced GLP-1 medications, including semaglutide and tirzepatide. We specialize in providing cutting-edge weight loss peptides and personalized health solutions to help you achieve your optimal metabolic health. Discover the Maion difference and start your journey to a healthier, happier you today.`
