import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Label } from 'components/layout/label'
import { FileUpload } from 'components/elements/imageUpload'

import styles from './styles.module.css'
import { UploadIdFailed } from './components/complete/UploadIdFailed'
import { UploadIdComplete } from './components/complete/uploadIdComplete'
import { useMediaQuery } from '../../shared/hooks/useMediaQuery'
import { useAttachPhotosToOrderMutation } from 'shared/mutations/order'

export const OrderUploadId = () => {
  const navigate = useNavigate()
  const { orderId } = useParams()
  const isMobile = useMediaQuery('(max-width: 800px)')
  const [showComplete, setShowComplete] = useState(false)
  const [showError, setShowError] = useState(false)
  const { mutate: uploadPhotos } = useAttachPhotosToOrderMutation(orderId, {
    onSuccess: () => {
      setShowComplete(true)
    },
    onError: () => {
      setShowError(true)
    },
  })
  const [selectedImage, setSelectedImage] = useState(null)
  if (showComplete) {
    return <UploadIdComplete />
  }

  if (showError) {
    return <UploadIdFailed handleBack={() => setShowError(false)} />
  }
  return (
    <div className={styles.wrapper}>
      <Label>Upload Id</Label>
      <div className={styles.wrapper_data}>
        <p className={styles.label_text}>Please upload your relevant state ID</p>
        <p className={styles.label_body}>Your photo was attached to the prescription request</p>
        <FileUpload
          image={selectedImage}
          onChange={setSelectedImage}
          formControlProps={{
            width: isMobile ? 'auto' : '700px',
            height: isMobile ? 'auto' : '400px',
          }}
        />
      </div>
      <div className={styles.buttons}>
        <button className={styles.back} onClick={() => navigate(-1)}>
          Back
        </button>
        <button
          className={styles.continue}
          onClick={() => {
            uploadPhotos({ photos: [{ base64Image: selectedImage, isId: true }] })
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  )
}
