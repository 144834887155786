import React from 'react'
export const HumanSvg = ({ ...args }) => {
  return (
    <svg
      width="26"
      height="21"
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...args}
    >
      <path
        d="M16.57 19.73V18.15C16.57 16.05 15.74 14.04 14.25 12.56C12.77 11.08 10.76 10.24 8.66 10.24C6.56 10.24 4.55 11.07 3.07 12.56C1.59 14.04 0.75 16.05 0.75 18.15V19.73H24.33V18.15C24.33 16.89 23.83 15.68 22.94 14.79C22.05 13.9 20.84 13.4 19.58 13.4M19.58 13.4C18.74 13.4 17.94 13.07 17.34 12.47C16.75 11.88 16.41 11.07 16.41 10.23C16.41 9.38999 16.74 8.58999 17.34 7.98999C17.93 7.39999 18.74 7.05999 19.58 7.05999C20.42 7.05999 21.22 7.38999 21.82 7.98999C22.41 8.57999 22.75 9.38999 22.75 10.23C22.75 11.07 22.42 11.87 21.82 12.47C21.23 13.06 20.42 13.4 19.58 13.4ZM8.65 10.24C7.39 10.24 6.18 9.73999 5.29 8.84999C4.4 7.95999 3.9 6.74999 3.9 5.48999C3.9 4.22999 4.4 3.01999 5.29 2.12999C6.18 1.23999 7.39 0.73999 8.65 0.73999C9.91 0.73999 11.12 1.23999 12.01 2.12999C12.9 3.01999 13.4 4.22999 13.4 5.48999C13.4 6.74999 12.9 7.95999 12.01 8.84999C11.12 9.73999 9.91 10.24 8.65 10.24Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="16"
        strokeLinecap="round"
      />
      <path d="M4.78003 20.02V16.28" stroke="currentColor" strokeWidth="1.5" />
      <path d="M12.8301 20.02V16.28" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  )
}
