import React, { useState } from 'react'
import { useCreateNewsletterSubscription } from 'shared/mutations/newsLetter'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Modal } from 'features/modal'
import MainPageImage from 'shared/images/mainPageImage.jpg'
import { Label } from 'components/layout/label'
import styles from './styles.module.css'
import { Image } from '@chakra-ui/react'

export const HereToHelp = ({ className }) => {
  const [isModalOpen, setModalIsOpen] = useState(false)

  const closeModal = () => setModalIsOpen(false)

  const { mutate: crateNewsLetterSubscription } = useCreateNewsletterSubscription({
    onSuccess: () => setModalIsOpen(true),
  })
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Email Address is required'),
    }),
    onSubmit: async (values) => {
      await crateNewsLetterSubscription(values)
      await formik.setFieldValue('email', '')
      await formik.setErrors({ email: '' })
    },
  })

  return (
    <>
      <section className={`${className} ${styles.help_section}`}>
        <Image src={MainPageImage} borderRadius="20px" />
        <div>
          <Label>Here to help</Label>
          <p>
            Enter your email to get our Maion medically approved guide to help you understand
            your medication and have success in your weight loss journey.
          </p>
          <form className={styles.subscribe_input} onSubmit={formik.handleSubmit}>
            <input
              placeholder={'Enter your email address'}
              spellCheck={false}
              type={'email'}
              name={'email'}
              value={formik.values.email}
              onChange={(e) => {
                formik.handleChange(e)
              }}
            />
            <button type={'submit'}>Subscribe</button>
          </form>
          {!!formik.errors.email && <p className={styles.error}>{formik.errors.email}</p>}
        </div>
      </section>
      <Modal
        modalIsOpen={isModalOpen}
        close={closeModal}
        onConfirm={closeModal}
        type={'newsLetters'}
      />
    </>
  )
}
