import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  HStack,
  VStack,
  Link as ChakraLink,
  useMediaQuery,
} from '@chakra-ui/react'
import { STATE_TO_NAME } from 'shared/constants/states'
import { Label } from 'components/layout/label'
import { APP_PATHS } from 'paths'
import { useUpdateMyUserProfileStateMutation } from 'shared/mutations/userProfile'

const ModalUnavailable = ({ isOpen, onClose }) => {
  const navigate = useNavigate()

  const handleClose = () => {
    onClose()
    navigate(APP_PATHS.home)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl" blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent
        alignItems={'center'}
        style={{
          background:
            'linear-gradient(90deg, #1D96DE 0%, #080267 52%, #9600DA 100%),' +
            'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
          backgroundBlendMode: 'overlay',
        }}
        borderRadius={'lg'}
      >
        <ModalHeader />
        <ModalCloseButton
          bgColor={'white'}
          boxSize={'23px'}
          top={'4'}
          right={'4'}
          _hover={{ bg: 'var(--accent)' }}
        />
        <ModalBody>
          <VStack
            maxW={'450px'}
            textAlign={'center'}
            fontSize={'16px'}
            color={'white'}
            spacing={'6'}
          >
            <Text>
              Unfortunately, at this time we can’t service your state. However, our Compliance
              Team are working hard to give every American access to our medication.
            </Text>
            <Text>
              However, we will let you know when new states and/or relevant medication becomes
              available.
            </Text>
            <Text color={'var(--accent)'}>
              If you would like to see a full list of the States we currently service please
              visit our Our Products page section the{' '}
              <ChakraLink
                as={Link}
                to={APP_PATHS.whyMaion}
                fontWeight={'600'}
                textDecoration={'underline'}
                color={'white'}
              >
                Why Maion
              </ChakraLink>{' '}
              page on our site.
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const ShipStatePage = ({ setShowStateSelect, availabelStates, refetchUserProfile }) => {
  const [selectedState, setSelectedState] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const registrationToken = localStorage.getItem('REGISTRATION_TOKEN')

  const { mutateAsync: updateMyUserProfileState } = useUpdateMyUserProfileStateMutation()

  const handleNext = async () => {
    await updateMyUserProfileState({
      token: registrationToken,
      addressRegion: selectedState,
    })
    if (!availabelStates.includes(selectedState)) {
      onOpen()
    } else {
      refetchUserProfile()
      setShowStateSelect(false)
    }
  }

  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)')
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const [isLargerThan375] = useMediaQuery('(min-width: 375px)')

  let columnCount = 6
  if (isLargerThan1280) {
    columnCount = 6
  } else if (isLargerThan1024) {
    columnCount = 4
  } else if (isLargerThan768) {
    columnCount = 3
  } else if (isLargerThan375) {
    columnCount = 2
  } else {
    columnCount = 1
  }
  const verticalCount = Math.ceil(Object.keys(STATE_TO_NAME).length / columnCount)

  const columns = Array.from({ length: columnCount }, (_, columnIndex) => (
    <VStack key={columnIndex} spacing={2} align="stretch">
      {Object.entries(STATE_TO_NAME)
        .slice(columnIndex * verticalCount, (columnIndex + 1) * verticalCount)
        .map(([abbreviation, stateName]) => (
          <Button
            key={abbreviation}
            onClick={() => setSelectedState(abbreviation)}
            colorScheme="pink"
            borderRadius="8px"
            w={'150px'}
            bg={selectedState === abbreviation ? 'var(--accent)' : 'white'}
            color={selectedState === abbreviation ? 'white' : 'black'}
          >
            {stateName}
          </Button>
        ))}
    </VStack>
  ))

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="30px"
      padding="7% 8% 60px 8%"
      textAlign={'start'}
    >
      <VStack align="center" spacing={4} maxW={'470px'}>
        <Label>Your State</Label>
        <Text color={'var(--accent)'} w={'100%'} pt={4}>
          Thank you. Your email has now been verified.
        </Text>
        <Text mb={8}>
          Let us know which state you live in so we can check to see if we’re able to ship GLP-1
          medication to you.
        </Text>
      </VStack>
      <VStack maxW={'1000px'}>
        <HStack spacing={4} align="start">
          {columns}
        </HStack>
      </VStack>
      <HStack mt={8} w={'100%'} maxW={'1000px'} justifyContent={'space-between'}>
        <Box fontSize={{ base: '10px', xl: '14px' }} alignContent={'start'}>
          <Text as={'italic'} maxW={'540px'} fontWeight={'600'}>
            Why do we need to know what state you live in?
          </Text>
          <Text maxW={'540px'}>
            Different states have different rules and regulations when it comes to selling
            prescription medication. If we currently can’t serve your state please add your
            details in the pop up and we’ll let you know when we can.
          </Text>
        </Box>
        <Button
          mt={8}
          colorScheme="pink"
          bg={'var(--accent)'}
          onClick={handleNext}
          width="100px"
          borderRadius="8px"
          isDisabled={!selectedState}
        >
          Next
        </Button>
      </HStack>
      <ModalUnavailable isOpen={isOpen} onClose={onClose} />
    </Box>
  )
}

export default ShipStatePage
