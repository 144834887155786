import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './styles.module.css'
import { Input } from '../../elements/input'
import { Button } from 'shared/components'
import { useUserContextStateDispatch } from 'shared/contexts/user-context-provider'
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { APP_PATHS } from '../../../paths'
import { useBasketStore } from 'shared/stores/basketStore'

export const Auth = ({ onClose }) => {
  const { items } = useBasketStore()
  const [params] = useSearchParams()
  const { token } = useParams()

  const toBuy = params.get('toBuy')
  const navigate = useNavigate()
  const [errorMessage, serErrorMessage] = useState('')

  const onRegisterClick = () => {
    // navigate(`/registration/details${token ? `?token=${token}` : ''}`)
    navigate(
      token
        ? `/registration/details${token ? `?token=${token}` : ''} `
        : `/treatments/plans?selectPopUp=show`
    )

    onClose()
  }

  const { onLogin } = useUserContextStateDispatch()
  const handleAfterLogin = () => {
    const product = items?.[0]
    if (product && toBuy) {
      const paymentPath = generatePath(APP_PATHS.orderSubscription, {
        id: product.id,
      })
      navigate(paymentPath)
    } else {
      navigate(APP_PATHS.treatmentPlans)
    }
  }
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Email Address is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      await onLogin(
        values,
        () => {
          handleAfterLogin()
        },
        () => {},
        (error) => {
          serErrorMessage(error.message)
        }
      )
    },
  })
  return (
    <form className={styles.col} onSubmit={formik.handleSubmit}>
      <Input
        name={'email'}
        placeholder={'Email'}
        type={'email'}
        required
        value={formik.values.email}
        onChange={formik.handleChange}
      />
      <Input
        name={'password'}
        placeholder={'Password'}
        type={'password'}
        required
        value={formik.values.password}
        onChange={formik.handleChange}
      />
      <div className={styles.row}>
        <button onClick={onRegisterClick}>Sign Up</button>
        <Button type={'submit'}>Sign In</Button>
      </div>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </form>
  )
}
