import React from 'react'
export const BlockSvg = ({ ...args }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...args}
    >
      <path
        d="M21 8.04346L18.75 6.73096M21 8.04346V10.2935M21 8.04346L18.75 9.35596M3 8.04346L5.25 6.73096M3 8.04346L5.25 9.35596M3 8.04346V10.2935M12 13.2935L14.25 11.981M12 13.2935L9.75 11.981M12 13.2935V15.5435M12 22.2935L14.25 20.981M12 22.2935V20.0435M12 22.2935L9.75 20.981M9.75 4.10596L12 2.79346L14.25 4.10596M21 14.7935V17.0435L18.75 18.356M5.25 18.356L3 17.0435V14.7935"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
