import React, { useCallback, useState } from 'react'

import Doctor1Image from 'shared/images/doctors/doctor1.png'
import Doctor2Image from 'shared/images/doctors/doctor2.png'
import Doctor3Image from 'shared/images/doctors/doctor3.png'
import Doctor4Image from 'shared/images/doctors/doctor4.png'
import Doctor5Image from 'shared/images/doctors/doctor5.png'
import { Slide } from 'react-slideshow-image'
import './styles.css'
import styles from './styles.module.css'
import 'react-slideshow-image/dist/styles.css'
// import { Link } from 'react-router-dom'
import { useMediaQuery } from '../../shared/hooks/useMediaQuery'
import LinkedinLogo from 'shared/icons/socials/linkedln.svg'

import ArrowPrevIcon from 'shared/icons/doctorsArrows/arrowLeft.svg'
import ArrowNextIcon from 'shared/icons/doctorsArrows/arrowRight.svg'
import { Link } from 'react-router-dom'

const ArrowPrev = (
  <img
    src={ArrowPrevIcon}
    alt={'Arrow prev'}
    style={{ marginLeft: '-20px', marginTop: 'calc(3rem + 10.5%)', alignSelf: 'flex-start' }}
  />
)
const ArrowNext = (
  <img
    src={ArrowNextIcon}
    alt={'Arrow next'}
    style={{ marginRight: '-20px', marginTop: 'calc(3rem + 10.5%)', alignSelf: 'flex-start' }}
  />
)

const initialDoctorsList = [
  {
    image: Doctor1Image,
    name: 'Dr. David H. Rahm, M.D.',
    description:
      'I began my career in healthcare in 1997 as an EMT/firefighter in Central Texas. I obtained my RN in 2008 from Austin College',
    readMoreLink: '#',
    linkedin: 'https://www.linkedin.com/in/david-rahm-87bb68b/',
  },
  {
    image: Doctor2Image,
    name: 'Grace Rochman, MSN-NP-PC',
    description:
      'I began my career in healthcare in 1997 as an EMT/firefighter in Central Texas. I obtained my RN in 2008 from Austin College',
    readMoreLink: '#',
  },
  {
    image: Doctor3Image,
    name: 'Dr. Ross Nathan, M.D.',
    description:
      'I began my career in healthcare in 1997 as an EMT/firefighter in Central Texas. I obtained my RN in 2008 from Austin College',
    readMoreLink: '#',
    linkedin: 'https://www.linkedin.com/in/ross-nathan-5bb09049/',
  },
  {
    image: Doctor4Image,
    name: 'Gavin Sambles',
    description:
      'I began my career in healthcare in 1997 as an EMT/firefighter in Central Texas. I obtained my RN in 2008 from Austin College',
    readMoreLink: '#',
    linkedin: 'https://www.linkedin.com/in/gavin-sambles/',
  },
  {
    image: Doctor5Image,
    name: 'Randy Acosta',
    description:
      'I began my career in healthcare in 1997 as an EMT/firefighter in Central Texas. I obtained my RN in 2008 from Austin College',
    readMoreLink: '#',
    linkedin: 'https://www.linkedin.com/in/randy-acosta-8ba15a17/',
  },
]

const indicators = () => <div className="indicator" />

export const DoctorsList = ({ list = initialDoctorsList, clear = true, className = '' }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const isMobile = useMediaQuery('(max-width: 800px)')

  const onStartChange = useCallback(
    (from, to) => {
      typeof activeIndex === 'number' && setActiveIndex(to)
    },
    [list]
  )

  return (
    <div className={`${styles.wrapper} ${className}`}>
      {!clear && (
        <>
          <div className={styles.label}>Meet our experts</div>
          <div className={styles.description}>
            Our team of healthcare professionals ensure that Maion always operates in the best
            interest of our customers.
          </div>
        </>
      )}
      <div className="slide-container" style={{ width: '100%' }}>
        <Slide
          autoplay={initialDoctorsList.length > (isMobile ? 2 : 4)}
          infinite={true}
          canSwipe={false}
          arrows={initialDoctorsList.length > (isMobile ? 2 : 4)}
          prevArrow={ArrowPrev}
          nextArrow={ArrowNext}
          indicators={initialDoctorsList.length > 4 ? indicators : null}
          pauseOnHover
          slidesToScroll={isMobile ? 2 : 4}
          slidesToShow={isMobile ? 2 : 4}
          onStartChange={onStartChange}
          easing={'ease'}
        >
          {list.map((slideImage, index) => (
            <div key={index} style={{ marginInline: '20px' }}>
              <div className="slide_item_doctors">
                <img src={slideImage.image} alt={`Slider ${index} image`} />
                <span>{slideImage.name}</span>
                {slideImage.linkedin && (
                  <Link to={slideImage.linkedin} target="__blank" referrerPolicy={'no-referrer'}>
                    <img src={LinkedinLogo} width={28} height={28} style={{ width: '28px' }} />
                  </Link>
                )}
                {/*<p>
                  {slideImage.description}&nbsp;
                  <Link to={slideImage.readMoreLink}>Read more...</Link>
                </p>*/}
              </div>
            </div>
          ))}
        </Slide>
        {/*<div className="bottom_wrapper_doctors">*/}
        {/*  <div className="bottom_indicators">*/}
        {/*    <div*/}
        {/*      className="bottom_indicator_item"*/}
        {/*      style={{*/}
        {/*        width: `${(isMobile ? 200 : 400) / list.length}%`,*/}
        {/*        left: `${activeIndex * (100 / list.length)}%`,*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}
