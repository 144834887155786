import React, { useState } from 'react'

import Figure from 'shared/icons/figures/footer-fig.svg'
import Logo from 'shared/icons/logo.svg'
import CertificateAnalysisImg from 'shared/icons/certificate_of_analysis.png'
import CertificateAnalysis2Img from 'shared/icons/certificate_of_analysis_2.png'
import PCCALogoImg from 'shared/icons/PCCA_logo_blue.png'
import CompoundedUSAImg from 'shared/icons/compounded_in_USA.png'
import HippaCompliantImg from 'shared/icons/hippa_compliant.png'

import styles from './styles.module.css'
import { APP_PATHS } from '../../../paths'
import { Link } from 'react-router-dom'
import { useCreateNewsletterSubscription } from 'shared/mutations/newsLetter'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Modal } from '../../../features/modal'
import { HStack, Image, Stack, Link as ChakraLink, Spacer } from '@chakra-ui/react'

const links = [
  {
    label: 'Privacy Policy',
    link: APP_PATHS.privacyPolicy,
  },
  {
    label: 'Terms of Use',
    link: APP_PATHS.termsAndConditions,
  },
  // {
  //   label: 'Sales and Refunds',
  //   link: '#',
  // },
  // {
  //   label: 'Safety Information',
  //   link: '#',
  // },
  // {
  //   label: 'Legal',
  //   link: '#',
  // },
  // {
  //   label: 'Site Map',
  //   link: '#',
  // },
]
const hideOn = ['/registration']
export const Footer = () => {
  const [isModalOpen, setModalIsOpen] = useState(false)

  const closeModal = () => setModalIsOpen(false)

  const notVisible = hideOn.includes(window.location.pathname)
  const { mutate: crateNewsLetterSubscription } = useCreateNewsletterSubscription({
    onSuccess: () => setModalIsOpen(true),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Email Address is required'),
    }),
    onSubmit: async (values) => {
      await crateNewsLetterSubscription(values)
      await formik.setFieldValue('email', '')
      await formik.setErrors({ email: '' })
    },
  })

  if (notVisible) return <></>

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.wrapper_data}>
          {links.map((link) => (
            <Link key={link.label} to={link.link}>
              {link.label}
            </Link>
          ))}
        </div>
        <div className={styles.figure}>
          <div>
            <div className={styles.col}>
              <img src={Logo} alt={'logo'} />
              <p className={styles.text_left}>
                Maion Inc. <br /> 2024 © All rights reserved, Maion, Inc.
              </p>
            </div>
            <HStack>
              <div className={styles.col} style={{ alignSelf: 'flex-end' }}>
                <p>Subscribe to our newsletter</p>
                <form className={styles.subscribe_input} onSubmit={formik.handleSubmit}>
                  <input
                    placeholder={'Enter your email address'}
                    spellCheck={false}
                    type={'email'}
                    name={'email'}
                    value={formik.values.email}
                    onChange={(e) => {
                      formik.handleChange(e)
                    }}
                  />
                  <button type={'submit'}>Subscribe</button>
                </form>
                {!!formik.errors.email && <p className={styles.error}>{formik.errors.email}</p>}
              </div>
              <Spacer />
              <Stack
                direction={{ base: 'column', xl: 'row' }}
                spacing={{ base: '8px', xl: '16px' }}
                alignItems="center"
              >
                <HStack spacing={{ base: '8px', xl: '16px' }}>
                  <Image
                    src={CertificateAnalysisImg}
                    alt={'Certificate Analysis'}
                    className={styles.certificate_1_img}
                  />
                  <Image
                    src={CertificateAnalysis2Img}
                    alt={'Certificate Analysis'}
                    className={styles.certificate_2_img}
                  />
                  <Image src={PCCALogoImg} alt={'PCCA'} className={styles.pcca_img} />
                </HStack>
                <HStack sspacing={{ base: '8px', xl: '16px' }}>
                  <Image
                    src={CompoundedUSAImg}
                    alt={'Compounded USA'}
                    className={styles.compounded_usa_img}
                  />
                  <Image
                    src={HippaCompliantImg}
                    alt={'Hippa Compliant'}
                    className={styles.hippa_img}
                  />
                </HStack>
              </Stack>
              <ChakraLink
                href="https://www.legitscript.com/websites/?checker_keywords=maion.ai"
                isExternal
                _focus={{ boxShadow: 'none' }}
                className={styles.legal_img}
              >
                <Image
                  src="http://static.legitscript.com/seals/23617867.png"
                  alt={'LegitScript approved'}
                  className={styles.legal_img}
                />
              </ChakraLink>
            </HStack>
            {/*<div className={styles.col} style={{ alignItems: 'center', alignSelf: 'flex-end' }}>*/}
            {/*  <div className={styles.col} style={{ display: 'none' }}>*/}
            {/*    <p>Follow us</p>*/}
            {/*    <div className={styles.row} style={{ gap: '12px' }}>*/}
            {/*      <img src={FacebookLogo} alt={'FacebookLogo'} />*/}
            {/*      <img src={XLogo} alt={'XLogo'} />*/}
            {/*      <img src={InstagramLogo} alt={'InstagramLogo'} />*/}
            {/*      <img src={LinkedlnLogo} alt={'LinkedlnLogo'} />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <img src={Figure} alt={''} className={styles.figure_img} />
        </div>
      </div>
      <Modal
        modalIsOpen={isModalOpen}
        close={closeModal}
        onConfirm={closeModal}
        type={'newsLetters'}
      />
    </>
  )
}
