import React, { useState } from 'react'
import styles from './styles.module.css'
import { Label } from 'components/layout/label'
import { Button } from '../../shared/components'
import { generatePath, useNavigate } from 'react-router-dom'
import { APP_PATHS } from '../../paths'
import { TreatmentsProducts } from '../../features/treatments/treatmentsProducts'
import { Box, Text, VStack } from '@chakra-ui/react'
import { Banners, HereToHelp, MainPageDoctorsSlider, WhyMaion } from './components'

export const HomePage = () => {
  const navigate = useNavigate()

  const onChoseClick = () => navigate(APP_PATHS.treatmentPlans)
  const [activeItem, setActiveItem] = useState()

  const onSlideChange = ({ item }) => {
    setActiveItem(item)
  }

  const originalPriceInDollar = (activeItem) => {
    return activeItem?.priceInCents / 100
  }

  const priceWithCouponInDollar = (activeItem) => {
    const originalPrice = originalPriceInDollar(activeItem)
    const discountAmount = activeItem?.defaultCoupon?.discount
      ? originalPrice * activeItem?.defaultCoupon?.discount
      : activeItem?.defaultCoupon?.discountAmount
    const totalAmountWithDiscount = originalPrice - discountAmount / 100
    return Math.round(totalAmountWithDiscount)
  }
  const isCouponDefault = !!activeItem?.defaultCoupon

  return (
    <>
      <div className={styles.wrapper}>
        <section
          className={`${styles.second_part} ${styles.paddings_main_slider}`}
          style={{ paddingTop: '6%', paddingBottom: '2%', marginTop: '-4px' }}
        >
          <div className={styles.label_main_slider}>
            <Label className={styles.dark_label}>Weight loss</Label>
            <Label className={styles.white_label}>
              from a USP797 compliant US, sterile pharmacy
            </Label>
            <Button
              onClick={onChoseClick}
              // style={{ fontWeight: 600, width: '150px' }}
              className={styles.button}
            >
              Explore Plans
            </Button>
          </div>
          {/* <WeightLossCalculator withText className={styles.calculator} /> */}
          <TreatmentsProducts clear onChange={onSlideChange} className={styles.slider} />
          {/*<img src={Product} alt={'Products'} className={styles.products_image} />*/}

          <div className={styles.amount_button}>
            <p className={styles.text}>
              {activeItem?.title}
              <br />
              offer
            </p>
            <button
              className={styles.offer}
              onClick={() =>
                navigate(
                  generatePath(APP_PATHS.product, {
                    id: activeItem?.id,
                  })
                )
              }
              disabled={activeItem?.status !== 'IN_STOCK'}
            >
              {isCouponDefault ? (
                <Box>
                  <VStack
                    alignItems="flex-start"
                    spacing={-1}
                    ml="-1"
                    className={styles.price_label}
                  >
                    <Text fontSize="md" fontWeight="bold" ml="-4">
                      Was:
                    </Text>
                    <Text fontSize="xl" fontWeight="bold" ml="-6" className={styles.cross}>
                      ${originalPriceInDollar(activeItem)}
                    </Text>
                  </VStack>
                  <Text fontSize="4xl" fontWeight="bold">
                    ${priceWithCouponInDollar(activeItem)}
                  </Text>
                  <Text fontSize="xl">{activeItem?.priceLabelBottomText}</Text>
                </Box>
              ) : (
                <>
                  <Text fontSize="4xl" fontWeight="bold">
                    ${originalPriceInDollar(activeItem)}
                  </Text>
                  {activeItem?.priceLabelBottomText && (
                    <Text fontSize="xl">{activeItem?.priceLabelBottomText}</Text>
                  )}
                </>
              )}
            </button>
          </div>
        </section>
        <section
          className={styles.paddings}
          style={{ paddingTop: '20px', paddingBottom: '20px' }}
        >
          <Banners />
        </section>
        <section
          className={styles.paddings}
          style={{ paddingTop: '0px', paddingBottom: '20px' }}
        >
          <WhyMaion />
        </section>
        <section className={`${styles.second_part}`}>
          <div className={styles.label}>
            <Label>Meet the team</Label>
            <Text maxW={'520px'}>
              Our team of healthcare professionals ensure that Maion always operates in the best
              interest of our customers.
            </Text>
          </div>
          <MainPageDoctorsSlider />
          {/* <div className={styles.doctor_wrapper}>
            <div className={styles.texts}>
              <p>
                “At Maion, our goal is to provide superior quality GLP-1 medications, combined
                with community-centric support and personalized data-driven health care solutions
                to assist patients on their weight loss journey.”
              </p>
              <span>Dr. David H. Rahm, M.D.</span>
            </div>
            <img src={Doctor} alt={'Dr. David H. Rahm, M.D. Photo'} />
          </div> */}
        </section>
        <HereToHelp className={styles.paddings} />
        {/* <section>
          <div className={styles.paddings}>
            <Label>Advisory Board</Label>
            <i className={styles.third_section_text}>
              Maion’s Advisory Board is composed of distinguished medical professionals -
              including expert physicians, Government Healthcare Advisors, pharmaceutical
              consultants and administrators - who have been instrumental to setting the tone and
              path for Maion from it’s inception.
              <br />
              With their participation, and Maion’s proprietary tech offering, patients receive
              care not only from their physician but from an entire team of experts who
              understand every facet of their experience. The way we utilize the knowledge and
              advice of our advisory board is a key element of what distinguishes Maion and makes
              us special.
            </i>
          </div>
          <DoctorsList clear className={styles.doctors} />
        </section> */}
      </div>
    </>
  )
}
