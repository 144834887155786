/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import styles from './styles.module.css'
import { Label } from 'components/layout/label'
import { Link } from 'react-router-dom'

export const Page = () => {
  return (
    <div className={styles.wrapper}>
      <Label>Privacy Policy</Label>
      <div className={styles.text_wrapper}>
        <span>Effective Date: May 7th, 2024</span>
        <p>Introduction</p>
        <span>
          This Privacy Policy ("Privacy Policy") describes the data protection practices of Maion
          Inc. and its affiliates (collectively, "Maion," "we ", "us", or "our"), who own and
          operate the websites located at www.maion.ai, losers.ai (collectively, the "Websites"),
          and their associated mobile applications ("Apps"). We refer to the Websites, Apps, and
          other services provided by Maion together herein as the "Services." In these Terms of
          Use, the terms "you" and "yours" refer to the person using the Services.
        </span>
        <span>
          PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW WE HANDLE YOUR INFORMATION.
          IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT USE THE SERVICES.
        </span>

        <span>
          This Privacy Policy contains the following sections:
          <br />
          1. The Information We Collect and the Sources of Such Information
          <br />
          2. Purposes for How We Use Your Information
          <br />
          3. Online Analytics and Advertising
          <br />
          4. How We Disclose Your Information
          <br />
          5. Your Rights and Choices
          <br />
          6. Third Party Services and Notice About Health Information
          <br />
          7. How We Protect Your Information
          <br />
          8. Privacy Information for Nevada Residents
          <br />
          9. Retention of Your Information
          <br />
          10. Minors
          <br />
          11. Revisions to Our Privacy Policy
          <br />
          12. Contacting Us
        </span>
        <p>1. The Information We Collect and the Sources of Such Information</p>
        <span>
          We obtain information about you through the means discussed below when you use the
          Services. Please note that we need certain types of information so that we can provide
          the Services to you. If you do not provide us with such information, or ask us to
          delete it, you may no longer be able to access or use part or all of our Services.
        </span>

        <span>1. Information You Provide to Us</span>

        <span>
          We collect a variety of information that you provide directly to us. For example, we
          collect information from you through:
        </span>

        <span>
          <ul>
            <li>Account and product registration and administration of your account</li>
            <li>
              Processing your orders and requests for treatment, including orders and requests
              made by your provider in connection with your care
            </li>
            <li>Questions, communications, or feedback you submit to us via forms or email</li>
            <li>Your participation in research, surveys, contests, and sweepstakes</li>
            <li>
              Requests for customer support and technical assistance, including through online
              chat functionalities
            </li>
            <li>Uploads or posts to the Services</li>
          </ul>
        </span>
        <span>
          The specific types of information we collect will depend upon the Services you use, how
          you use them, and the information you choose to provide. The types of data we collect
          directly from you includes:
        </span>
        <span>
          <ul>
            <li>
              Identifiers, such as name, address, telephone number, date of birth, and email
              address
            </li>
            <li>
              Billing information, such as shipping address, credit or debit card number,
              verification number, and expiration date, collected by our payment processors on
              our behalf
            </li>
            <li>
              Commercial information, such as information about purchases or other transactions
              with us, including information about your healthcare provider, if applicable
            </li>
            <li>
              Customer service information, such as information about your customer service and
              maintenance interactions with us
            </li>
            <li>
              Demographic information such as your gender, age, marital status, and similar
              information in connection with the Services
            </li>
            <li>
              Information you provide in Apps, such as videos (and in some cases the recordings
              of such videos), photos, and signatures
            </li>
            <li>General geolocation information, such as city, state, or zip code.</li>
            <li>
              Information about others, such as if you provide a family or friend's email address
              or contact information to allow access to your information or name them as an
              emergency contact
            </li>
            <li>
              User-generated content you post in public online forums on our Services or disclose
              to other users or your healthcare providers
            </li>
            <li>
              Sensitive Personal Information
              <ul>
                <li>
                  Health information, such as information about your symptoms, medical history,
                  lifestyle, prescriptions, mental health, drug or alcohol use, genetics,
                  treatment options, and relevant physical characteristics (e.g., your height and
                  weight) as well as medical photos you upload, lab results, and your insurance
                  information.
                </li>
                <li>Log-in credentials, if you create an account</li>
                <li>Sensitive demographic data, such as race and ethnicity</li>
                <li>
                  Identity verification information (e.g. driver's licence or other
                  government-issued ID card or number), and your signature (if required for
                  notarization purposes)
                </li>
                <li>Contents of communications made via the Services</li>
              </ul>
            </li>
            <li>
              Any other information you choose to directly provide to us in connection with your
              use of the Services.
            </li>
          </ul>
        </span>
        <span>2. Information We Collect Through Automated Means</span>

        <span>
          We collect certain information about your use of the Services and the devices you use
          to access the Services. As discussed further below, we and our service providers (which
          are third party companies that work on our behalf), may use a variety of technologies,
          including cookies, SDKs (software development kits), and similar tools, to assist in
          collecting this information. In some instances, such information may be considered
          sensitive personal information.
        </span>

        <span>
          Our Websites. When you use our Websites, we collect and analyse information such as
          your IP address, browser types, browser language, operating system, the state or
          country from which you accessed the Services, software and hardware attributes
          (including device IDs) referring and exit pages and URLs, platform type, the number of
          clicks, files you download, domain names, landing pages, pages viewed and the order of
          those pages, the amount of time spent on particular pages, the terms you use in
          searches on our sites, the date and time you used the Services, error logs, and other
          similar information.
        </span>
        <span>
          Our Apps. When you use our Apps, we automatically receive certain information about the
          mobile phone, tablet, or computer used to access the Apps, including a mobile device
          identifier, advertising identifiers (in accordance with your device settings), IP
          address, operating system, version, Internet service provider, browser type, domain
          name and other similar information, whether and when you update the Apps, date and time
          of use, and how you use the Apps, including time spent in different portions of the
          Apps.
        </span>
        <span>
          Location Information. When you use the Services, we and our service providers may
          automatically collect general location information (e.g., IP address, city/state and or
          postal code associated with an IP address) from your computer or mobile device. This
          information allows us to enable access to content that varies based on a user's general
          location so that we can provide you with helpful information (e.g., to provide you with
          accurate sales tax information and to deliver content customised to your location).
        </span>
        <span>
          Our Use of Cookies and Similar Online Tools. To collect the information discussed in
          this Section, we and our service providers use web server logs, cookies, tags, SDKs,
          tracking pixels, and other similar tracking technologies. We use these technologies to
          offer you a more tailored experience.
        </span>

        <span>
          <ul>
            <li>A web server log is a file where website activity is stored.</li>
            <li>
              An SDK is a set of tools and/or code that we embed in our Apps and software to
              allow third parties to collect information about how users interact with the
              Services.
            </li>
            <li>
              A cookie is a small text file that is placed on your computer or mobile device when
              you visit a site, that enables us to: (i) recognize your computer/device; (ii)
              store your preferences and settings; (iii) understand the parts of the Services you
              have visited and used; (iv), enhance your user experience by delivering and
              measuring the effectiveness of content and advertising tailored to your interests;
              (v) perform searches and analytics; and (vi) assist with security and
              administrative functions.
            </li>
            <li>
              Tracking pixels (sometimes referred to as web beacons or clear GIFs) are tiny
              electronic tags with a unique identifier embedded in websites, online ads and/or
              email that are designed to: (1) collect usage information like ad impressions or
              clicks and email open rates; (2) measure popularity of the Services and associated
              advertising; and (3) access user cookies.
            </li>
          </ul>
        </span>

        <span>
          As we adopt additional technologies, we may also gather information through other
          methods.
        </span>

        <span>
          Please note that you can change your settings to notify you when a cookie is being set
          or updated, or to block cookies altogether. Please consult the "Help" section of your
          browser for more information. Please note that by blocking, disabling, or managing any
          or all cookies, you may not have access to certain features of the website or offerings
          of the Services.
        </span>

        <span>3. Information We Collect From Social Media and Other Content Platforms</span>
        <span>
          When you "like" or "follow" us on Facebook, Instagram, X (Twitter), or other social
          media platforms, we may collect some information from you including your name, email
          address, and any comments or content you post relevant to us. We also collect your
          information if you sign up for one of our promotions or submit information to us
          through social media platforms.
        </span>

        <span>
          If you access the Services through a third-party connection or log-in (e.g., through a
          social network or third-party authentication tool), you may allow us to have access to
          and store certain information from such third parties depending on your settings on
          such services. If you do not wish to have this information disclosed, do not use these
          connections to access the Services. For a description of how these third parties handle
          your information, please refer to their privacy policies and terms of use, which may
          permit you to modify your privacy settings.
        </span>

        <span>
          Similarly, if you choose to connect your App to third-party platforms or tools, or with
          other apps on your device (e.g., health or fitness monitoring platforms or tools, or
          your contacts, photos, or calendar), such third parties and tools may allow us to have
          access to and store additional information as it relates to your use of the Services
          (e.g., to integrate your fitness data within the Services or access your calendar to
          help you schedule an appointment). If you do not wish to have this information
          disclosed, do not initiate these connections.
        </span>

        <span>4. Information We Receive From Other Sources</span>
        <span>
          We work closely with third parties (including, for example, third party intermediaries,
          such as physicians, medical professionals, and pharmacies to provide you with the
          Services, as well as with advertising networks, analytics providers, marketing
          partners, and search information providers). Such third parties will sometimes provide
          us with additional information about you.
        </span>

        <p>2. Purposes for How We Use Your Information</p>
        <span>
          In connection with providing you with the Services, we may use your information for the
          following business purposes:
        </span>
        <span>
          <ul>
            <li>
              Provide and Manage the Services:
              <ul>
                <li>
                  Carry out, improve, and manage the Services and, as applicable, facilitate the
                  provision of health care services to you by physicians or other health care
                  providers and ensure that the physicians or health care providers have the
                  services and support necessary for health care operations.
                </li>
                <li>
                  Provide you with technical support and customer service, and troubleshoot any
                  technical issues or errors.
                </li>
                <li>
                  Communicate with you about the Services, your use of the Services, or your
                  inquiries related to the Services and send you communications on behalf of
                  physicians or other health care providers utilising the Services to meet your
                  needs.
                </li>
                <li>
                  Verify your identity and administer your account, including processing your
                  payments, fulfilling your orders, and verifying the authenticity of your
                  government-issued identification photo.
                </li>
              </ul>
            </li>
            <li>
              Analyse and Improve the Services:{' '}
              <ul>
                <li>
                  Engage in internal research to understand the effectiveness of our Services,
                  improve our Services, and better understand our user base. If we publish or
                  provide the results of this research to others, such research will be presented
                  in a de-identified and aggregate form such that individual users cannot be
                  identified.
                </li>
                <li>
                  Ensure that content from our Services is presented in the most effective manner
                  for you and for your computer or device, allow you to participate in
                  interactive features of our Services (when you choose to do so), and as part of
                  our efforts to keep our Services safe and secure.
                </li>
                <li>
                  Help us better understand your interests and needs, such as by engaging in
                  analysis and research regarding use of the Services.
                </li>
              </ul>
            </li>
            <li>
              Advertising and Marketing:
              <ul>
                <li>
                  Communicate with you (in accordance with applicable legal requirements) by
                  email, postal mail, or phone about surveys, promotions, special events or our
                  products and Services and those of our subsidiaries, affiliates, and parent
                  companies and any of their related businesses and those of our third-party
                  partners.
                </li>
                <li>
                  Provide you (in accordance with applicable legal requirements) with more
                  relevant advertisements and personalised content, and measure or understand the
                  effectiveness of advertising and content we serve to you and others, and to
                  deliver and customise relevant advertising and content to you
                </li>
              </ul>
            </li>
            <li>
              Legal Purposes:
              <ul>
                <li>
                  Comply in good faith with any procedures, laws, and regulations which apply to
                  us where it is necessary for our legitimate interests or the legitimate
                  interests of others.
                </li>
                <li>
                  Establish, exercise, or defend our legal rights where it is necessary for our
                  legitimate interests or the legitimate interests of others, such as protecting
                  against malicious, fraudulent, or illegal activity.
                </li>
              </ul>
            </li>
          </ul>
        </span>
        <span>
          Combined Information. For the purposes discussed in this Privacy Policy, we may combine
          the information that we collect through the Services with information that we receive
          from other sources, both online and offline, and use and disclose such combined
          information in accordance with this Privacy Policy.
        </span>
        <span>
          Aggregate/De-Identified Data. We may aggregate and/or de-identify any information
          collected through the Services so that such information can no longer be linked to you
          or your device ("Aggregate/De-Identified Information"). We may use
          Aggregate/De-Identified Information for any purpose, including for research and
          marketing purposes, and may also disclose such data to any third parties, including
          advertisers, promotional partners, and sponsors.
        </span>

        <p>5. Online Analytics and Advertising</p>
        <span>1. Online Analytics</span>

        <span>
          We may use third-party web analytics services (such as those of Google Analytics
          -including Google Signals, Google User-ID, and other Google Analytics features-
          Metabase, Amplitude, and MixPanel) on our Services to collect and analyse usage
          information through cookies and similar tools; engage in auditing, research, or
          reporting; assist with fraud prevention; try to locate the same unique users across
          multiple browsers or devices to better tailor services and features; and provide
          certain features to you. If you have a Google account with personalised advertising
          enabled, through Google Signals, Google will also be able to gather for us analytics
          and engagement information from across the various devices you use to access the
          Services. To prevent Google from using your information for analytics (including
          cross-device tracking for personalization purposes), you may install the Google
          Analytics Opt-out Browser Add-on by{' '}
          <Link
            to={'https://tools.google.com/dlpage/gaoptout'}
            target="__blank"
            referrerPolicy="no-referrer"
          >
            clicking here
          </Link>
          . And to opt out of Google Signals, please open your "Settings" app, locate and tap
          "Google," select "Ads," and turn ON "Opt out of Ads Personalization." You may also be
          able to disable cross-device tracking through your Android or Apple device-based
          settings.
        </span>

        <span>
          If you receive email from us, we may use certain analytics tools, such as clear GIFs to
          capture data such as when you open our message or click on any links or banners our
          email contains. This data allows us to gauge the effectiveness of our communications
          and marketing campaigns.
        </span>

        <span>2. Online Advertising</span>

        <span>
          The Services may integrate third-party advertising technologies (e.g., ad networks and
          ad servers such as Facebook, Google Ad Words, TikTok and others) that use cookies,
          pixels, and other technologies to deliver relevant content and advertising for Maion
          products and research on the Services, as well as on other websites you visit and other
          applications you use. The ads may be based on various factors such as the content of
          the page you are visiting, information you enter such as your searches, demographic
          data, and other information we collect from you. These ads may be based on your current
          activity or your activity over time and across other websites and online services.
        </span>
        <span>
          We sometimes provide our customer information (such as email addresses) to service
          providers, who may "match" this information in de-identified form to cookies (or mobile
          ad identifiers) and other proprietary IDs, in order to provide you with more relevant
          ads when you visit other websites and mobile applications. We may also disclose
          customer information such as your email address with third-party advertising networks
          that use such information to better target advertising to you regarding Maion products
          and services, as well as other products and services, as We do not control these
          opt-out settings (mechanisms) of third-parties or whether any particular company
          chooses to participate in the industry opt-out programs listed above. We are not
          responsible for any choices you make using these mechanisms or the continued
          availability or accuracy of these mechanisms.
        </span>
        <span>
          Please note that if you exercise the opt out choices above, you will still see
          advertising when you use the Services, but it will not be tailored to you based on your
          online behavior over time.
        </span>

        <span>3. Mobile Advertising</span>

        <span>
          When using our Apps or others, you may also receive tailored in-application
          advertisements. We may use third-party service providers to deliver advertisements on
          mobile applications or for mobile application analytics. Each operating system, iOS for
          Apple phones, Android for Android devices, and Windows for Microsoft devices provides
          its own instructions on how to prevent the delivery of tailored in-application
          advertisements. We do not control how the applicable platform operator allows you to
          control receiving personalised in-application advertisements; thus, you should contact
          the platform provider for further details on opting out of tailored in-application
          advertisements. You may review the support materials and/or the device settings for the
          respective operating systems to opt-out of tailored in-app advertisements.
        </span>

        <span>4. Notice Concerning Do Not Track</span>

        <span>
          Do Not Track ("DNT") is a privacy preference that users can set in certain web
          browsers. We are committed to providing you with meaningful choices about the
          information collected on our Websites for third party purposes, and that is why we
          provide the variety of opt-out mechanisms listed above. However, we do not currently
          recognize or respond to browser-initiated DNT signals. To{' '}
          <Link to={'https://allaboutdnt.com/'} target="__blank" referrerPolicy="no-referrer">
            learn more about Do Not Track, you can do so here
          </Link>
          . Please note that “Do Not Track” is a distinct privacy mechanism from the
          browser-based opt out signals referenced below in the "Your Rights and Choices"
          section, which Maion does honor in accordance with applicable law.
        </span>

        <p>4. How We Disclose Your Information</p>
        <span>
          We may disclose your information for our business purposes in the following ways:
        </span>

        <span>
          <ul>
            <li>
              Affiliates and Subsidiaries. We may disclose information we collect within any
              Maion member or group (i.e., our subsidiaries and affiliates, including our
              ultimate holding company and its subsidiaries) to deliver products and services to
              you, ensure a consistent level of service across our products and services, and
              enhance our products, services, and your customer experience.
            </li>
            <li>
              Health Care Providers and Services. We disclose your information to health care
              providers: (i) to facilitate the provision of health care services as part of the
              Services, (ii) to whom you send messages through our Services, and (iii) for other
              treatment, payment or health care operations purposes, including pharmacy services,
              upon your request.
            </li>
            <li>
              Service Providers. We provide access to or disclose your information to select
              third parties who use the information to perform services on our behalf. They
              provide a variety of services to us, including billing, content/service
              enhancements, partner labs, sales, marketing, advertising, analytics, research,
              customer service, shipping and fulfilment, data hosting and storage, IT and
              security, fraud prevention, payment processing, and auditing, consulting, and legal
              services. These entities may also include health care organisations, pharmacies,
              and other third parties we use to support our business or in connection with the
              administration and support of the Services.
            </li>
            <li>
              Advertising Networks. Please see the "Online Advertising" section above for details
              about how we disclose information to advertising partners.
            </li>
            <li>
              Joint Product/Marketing Partners. These are entities that jointly offer or provide
              services or products with us. These entities collect and use data in accordance
              with their own terms and privacy policies.
            </li>
            <li>
              Protection of Maion and Others. By using the Services, you acknowledge and agree
              that we may access, retain and disclose the information we collect and maintain
              about you if required to do so by law or in a good faith belief that such access,
              retention or disclosure is reasonably necessary to: (a) comply with legal process
              (e.g. a subpoena or court order); (b) enforce our Terms of Use, this Privacy
              Policy, or other contracts with you, including investigation of potential
              violations thereof; (c) respond to claims that any content violates the rights of
              third parties; (d) respond to your requests for customer service; and/or (e)
              protect the rights, property or personal safety of Maion, its agents and
              affiliates, its users and/or the public. This includes exchanging information with
              other companies and organisations for fraud protection, and spam/malware
              prevention, and similar purposes.
            </li>
            <li>
              Business Transfers. As we continue to develop our business, we may buy, merge, or
              partner with other companies. In such transactions, (including in contemplation of
              such transactions) user information may be among the transferred assets. If a
              portion or all of our assets are sold or transferred to a third-party, customer
              information (including your email address) would likely be one of the transferred
              business assets. If such transfer is subject to additional mandatory restrictions
              under applicable laws, we will comply with such restrictions.
            </li>
            <li>
              Public Forums/User Interactions. Certain features (current and planned in future
              versions) of our Services make it possible for you to disclose comments publicly or
              with other users. Any information that you post publicly is not confidential, and
              we may use it for any purpose (including in testimonials or other marketing
              materials). For example, if you submit a product review on one of our Websites, we
              may display your review (along with the name provided, if any) on other Maion
              Websites and on third-party websites. Any information you post openly in these ways
              will be available to the users you disclosed it to and potentially the public at
              large, and may be accessible through third-party search engines. Accordingly,
              please take care when using these features. We are not responsible for how others
              use the information about you that you disclose to them through the Services.
            </li>
            <li>
              Consent. We may also disclose your information in other ways you direct us to and
              when we have your consent.
            </li>
            <li>
              Aggregate/De-Identified Information. We reserve the right to create
              Aggregate/De-Identified Data from the information we collect through the Services
              and our disclosure of such Aggregate/De-Identified Data is in our discretion.
            </li>
          </ul>
        </span>

        <p>7. Your Rights and Choices</p>
        <span>
          Depending on the state in which you live, you may have legal rights with respect to
          your information. The types of requests may be as follows:
        </span>

        <span>
          <ul>
            <li>
              Information about the categories of information we process, our uses of your
              information, our sources of such information and our disclosure of such information
            </li>
            <li>
              Access to the information that Maion has collected about you and a copy of certain
              information in a portable format
            </li>
            <li>Correct certain information we have about you</li>
            <li>Deletion of the information we have about you</li>
            <li>Control sharing of information we have about you</li>
            <li>File a complaint about a privacy violation</li>
            <li>Right to know who has accessed a patient file</li>
            <li>Request restriction on use of your information.</li>
          </ul>
        </span>

        <span>
          You may make a request by emailing us at legal@maion.ai. Before we disclose, correct or
          delete information in response to any of these requests, we will need to verify your
          identity. Depending on the nature of your request, we may contact you for further
          information if appropriate to verify your identity. Note, however, that Maion will
          never ask you for sensitive personal or financial information when verifying your
          identity, and no Maion employee will ask you to tell them your password. If you are an
          authorised agent submitting a request on their behalf, we may require proof of the
          written authorization you have received before processing the request.
        </span>

        <span>
          Certain information may be exempt from such requests under applicable law. For example,
          if the request prevents us from completing a transaction you initiated, interferes with
          our legal obligations, affects legal matters including a Maion user’s rights to data
          contained in their account, we cannot verify your identity, or compliance with your
          request is not legally required and would involve disproportionate cost or effort. But
          in any event we will respond to your request within a reasonable timeframe and provide
          you with an explanation. If we deny your request in whole or in part, the laws in your
          jurisdiction may provide you with rights to appeal our response. If applicable, we will
          provide you with information about your appeal options in our response to you.
        </span>

        <span>
          Maion will not discriminate against anyone that makes a rights request, but in some
          cases we will not be able to provide our Services to you without that information.
        </span>

        <span>
          Opt out of sale of and disclosure of information for online targeted advertising.
          Subject to applicable laws as discussed further below, Maion allows third parties to
          receive certain information such as cookies, IP address, device identifiers, hashed
          contact information, browsing behaviour, and/or other activity to enable the delivery
          of targeted advertising to you. These activities may qualify as the “sale” of personal
          information or "sharing" or processing of personal information for targeted
          advertising, as defined in applicable law.
        </span>

        <span>
          In addition to advertising activities using cookies and pixels, we may also share user
          email addresses with advertising partners to provide you with more relevant
          advertising. To opt out of this activity, make a request by emailing us at
          legal@maion.ai.
        </span>

        <span>
          If you opt out, you may still receive advertising, it just may not be tailored to your
          interests. Please note, if you use different browsers, devices, or services you will
          need opt out on each browser or device where you want your choice to apply.
        </span>
        <span>
          Sensitive Data Opt Out/Opt-In Right. To provide you with more relevant online
          advertising regarding Maion's products and services, as described immediately above, we
          may use information about the treatments you have requested or received and similar
          information. This may include the processing of information that is considered
          "sensitive data" or "sensitive personal information" under applicable laws. You have a
          right to limit our use of sensitive data for purposes other than to provide the
          services or goods you request or as otherwise permitted by law, including our and our
          partner's processing of sensitive data for tailored advertising purposes. To opt-out
          from such additional purposes, make a request by emailing us at legal@maion.ai.
          Depending on your jurisdiction, we may ask for your consent before using your sensitive
          data.
        </span>
        <span>
          Marketing preferences. You may instruct us not to use your contact information to
          contact you by email, postal mail, or phone regarding products, services, promotions
          and special events that might appeal to your interests by contacting us using the
          information below. Please note that, regardless of your request, we may still use and
          disclose certain information as permitted by this Privacy Policy or as required by
          applicable law. For example, you may not opt out of certain operational emails, such as
          those reflecting our relationship or transactions with you.
        </span>

        <p>6. Third Party Services and Notice About Health Information</p>
        <span>
          This Privacy Policy does not address, and we are not responsible for, the privacy,
          information, or other practices, including data privacy and security process and
          standards of any third parties, including physicians and other health care providers
          using the Services, the manufacturer of your mobile device and other IT hardware and
          software, and any other third party mobile application, website, or service to which
          our Services may contain a link. These third parties may at times gather information
          from or about you. We have no control over the privacy practices of these third
          parties. The collection, use, and disclosure of your information will be subject to the
          privacy policies of the third-party websites or services, and not this Privacy Policy.
          We urge you to read the privacy and security policies of these third parties.
        </span>

        <p>7. How We Protect Your Information</p>
        <span>
          Maion takes a variety of technical and organisational security measures to protect your
          information against accidental or unlawful destruction or accidental loss, alteration,
          unauthorised disclosure or access. However, no method of transmission over the
          Internet, and no means of electronic or physical storage, is absolutely secure. As
          such, you acknowledge and accept that we cannot guarantee the security of your
          information transmitted to, through, or on our Services or via the Internet and that
          any such transmission is at your own risk. Where we have given you (or where you have
          chosen) a password that enables you to access the Services, you are responsible for
          keeping this password confidential. We ask you not to provide your password to anyone.
          The information you disclose in public areas may be viewed by anyone.
        </span>

        <p>8. Privacy Information for Nevada Residents</p>
        <span>
          Under Nevada law, certain Nevada consumers may opt out of the sale of "personally
          identifiable information" for monetary consideration (as such terms are defined under
          Nevada law) to a person for that person to licence or sell such information to
          additional persons. We do not engage in such activity; however, if you are a Nevada
          resident who has purchased services from us, you may submit a request to opt out of any
          potential future sales under Nevada law by emailing legal@maion.ai. Please note we will
          take reasonable steps to verify your identity and the authenticity of the request. Once
          verified, we will maintain your request in the event our practices change.
        </span>

        <p>9. Retention of Your Information</p>
        <span>
          We keep your information for no longer than necessary for the purposes for which it is
          processed. The length of time for which we retain information depends on the purposes
          for which we collected and use it, the nature and sensitivity of the information, the
          potential risk of harm from unauthorised use or disclosure of the information, the
          purposes for which we process the information, and our legitimate interests, and/or as
          required to comply with applicable laws.
        </span>

        <p>10. Minors</p>
        <span>
          We do not knowingly collect personal data from anyone under the age of 18 through our
          Services, and our Services are not directed to minors under the age of 18. If we
          discover we have received any “personal information” from a minor under the age of 18
          in violation of this Privacy Policy, we will take reasonable steps to delete that
          information as quickly as possible.
        </span>

        <p>11. Revisions to Our Privacy Policy</p>
        <span>
          We reserve the right to change this Privacy Policy at any time to reflect changes in
          the law, our data collection and use practices, the features of our Services, or
          advances in technology. We will make the revised Privacy Policy accessible through the
          Services, so you should review it periodically. The date this Privacy Policy was last
          revised as identified at the top of the document. You are responsible for periodically
          monitoring and reviewing any updates to the Privacy Policy. If we make a material
          change to the Privacy Policy, we will provide you with appropriate notice in accordance
          with legal requirements. Your continued use of our Websites or Apps after such
          amendments (and notice, where applicable) will be deemed your acknowledgment of these
          changes to this Privacy Policy.
        </span>

        <p>12. Contacting Us</p>
        <span>
          If you have any questions about this Privacy Policy or Maion's privacy practices,
          please contact us at:
        </span>

        <span>Maion Inc. 701 Tillery Street, Unit 12, 1803 Austin, TX 78702</span>
        <span>GoGoMeds, 525 Alexandria Pike, Southgate, KY 41071, United State</span>
        <span>Jungle Jims Pharmacy, 5484 Dixie Highway Fairfield, OH 45014</span>
      </div>
    </div>
  )
}
