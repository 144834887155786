import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { isEmpty, trimStart } from 'lodash'
import {
  Button,
  CircularProgress,
  Flex,
  Text,
  useMediaQuery,
  useToast,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment'
import * as Yup from 'yup'
import 'yup-phone'

import { useCompleteUserProfileMutation } from 'shared/mutations/profile'
import { sleep } from 'shared/utils/sleep'
import { APP_PATHS } from 'paths'

import styles from './detailPage.module.css'
import { AddressStep } from './steps/AddressStep'
import { useIndexMySubscriptions } from 'shared/queries/subscription'
import { useReadProduct } from 'shared/queries/product'
import { useIndexMyOrders } from 'shared/queries/order'
import { useReadMyUser } from 'shared/queries/user'
import { useReadMyState } from 'shared/queries/userProfile'

function fullBirthDate({ month, day, year }) {
  const addLeadingZero = (num) => (num < 10 ? `0${num}` : num)
  const birthDate = `${year}-${addLeadingZero(month)}-${addLeadingZero(day)}T00:00:00.000Z`

  return birthDate
}
function isValidBirthdayDate() {
  const { month, day, year } = this.parent

  if (!month || !day || !year) {
    return false
  }

  return moment().diff(fullBirthDate({ month, day, year }), 'years') >= 18
}

const validationSchema = Yup.object({
  address: Yup.object({
    addressLocality: Yup.string().required('City is required'),
    houseNo: Yup.string().required('Apt/House No is required'),
    addressRegion: Yup.string().required('State is required'),
    postalCode: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(5, 'Must be exactly 5 digits')
      .max(5, 'Must be exactly 5 digits')
      .required('Zip is required'),
    streetAddress: Yup.string().required('Street Address is required'),
  }),
  birthDate: Yup.date().when(['month', 'day', 'year'], {
    is: (month, day, year) => month && day && year,
    then: (schema) =>
      schema.test('is-correct-date', 'You must be 18-years old', isValidBirthdayDate),
    otherwise: (schema) => schema.required('Date of Birth is required'),
  }),
})

export const CompleteRegistrationPage = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const toast = useToast()
  const [fieldErrors, setFieldErrors] = useState({})
  const [processing, setProcessing] = useState(false)
  const [userProfileComplete, setUserProfileComplete] = useState(false)
  const [isMobile] = useMediaQuery('(max-width: 800px)')

  const { isLoading: isUserLoading, refetch: refetchMyUser } = useReadMyUser({}) // Update user info in memory
  const { data: myState, isLoading: isMyStateLoading } = useReadMyState({ token })

  const {
    data: subscriptions,
    isLoading: isSubscriptionsLoading,
    refetch: refetchSubscriptions,
  } = useIndexMySubscriptions({ statuses: ['ACTIVE', 'PENDING'] })

  const { data: orders, isLoading: isOrdersLoading } = useIndexMyOrders(
    { subscriptionIds: [subscriptions?.[0]?.id] },
    !!subscriptions?.[0]?.id && !isSubscriptionsLoading
  )

  const { data: product, isLoading: isProductLoading } = useReadProduct(
    {
      id: subscriptions?.[0]?.items?.[0]?.productId,
    },
    !!subscriptions?.[0]?.items?.[0]?.productId && !isSubscriptionsLoading
  )

  const isLoading =
    isUserLoading ||
    isSubscriptionsLoading ||
    isOrdersLoading ||
    isProductLoading ||
    isMyStateLoading

  useEffect(async () => {
    const prescriptionNotRequiredOrRequested =
      !product?.isPrescriptionRequired || orders?.[0]?.belugaVisitId

    const prescriptionRequiredAndNotRequested =
      !orders?.[0]?.belugaVisitId && product?.isPrescriptionRequired

    const isFirstOrderFailed = ['PAYMENT_FAILED', 'ERROR'].includes(orders?.[0]?.status)

    if (isFirstOrderFailed) {
      localStorage.removeItem('REGISTRATION_TOKEN')
      await sleep(1000)
      navigate(APP_PATHS.myProfileBillingPage)
    }

    if (
      !isLoading &&
      userProfileComplete &&
      (subscriptions?.length === 0 ||
        (subscriptions?.length > 0 && prescriptionNotRequiredOrRequested && !isFirstOrderFailed))
    ) {
      localStorage.removeItem('REGISTRATION_TOKEN')
      await sleep(1000)
      navigate(APP_PATHS.myProfile)
    }

    if (
      !isLoading &&
      userProfileComplete &&
      orders?.length > 0 &&
      prescriptionRequiredAndNotRequested &&
      !isFirstOrderFailed
    ) {
      const path = generatePath(APP_PATHS.myProfileInitialPrescription, { id: product.id })
      const searchParams = new URLSearchParams({ orderId: orders[0]?.id })

      localStorage.removeItem('REGISTRATION_TOKEN')
      navigate(`${path}?${searchParams.toString()}`)
    }
  }, [subscriptions, product, orders, isLoading, userProfileComplete])

  const { mutate: onSetProfile } = useCompleteUserProfileMutation({
    onSuccess: async ({ token }) => {
      localStorage.setItem('AUTH_TOKEN', token)
      refetchMyUser()
      refetchSubscriptions()
      setUserProfileComplete(true)
    },
    onError: ({ error }) => {
      setProcessing(false)
      const { code, fields } = error
      if (code === 'COM-9') {
        let validationErrors = {}
        fields.forEach(({ dataPath, message }) => {
          validationErrors[trimStart(dataPath, '.')] = message
        })
        setFieldErrors(validationErrors)
      }
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
  })

  const formik = useFormik({
    initialValues: {
      token,
      address: {
        addressCountry: 'US',
        addressLocality: '',
        addressRegion: myState?.addressRegion || '',
        postalCode: '',
        streetAddress: '',
        houseNo: '',
      },
      isNewslettersOn: true,
    },
    validationSchema,
    onSubmit: (values) => {
      setProcessing(true)
      values.address.postalCode = String(values.address.postalCode)

      const { month, day, year } = values

      values.birthDate = fullBirthDate({ month, day, year })
      if (values.address.houseNo) {
        values.address.streetAddress = String(
          values.address.streetAddress + ', ' + values.address.houseNo
        )
        delete values.address.houseNo
      }

      onSetProfile(values)
    },
  })

  useEffect(async () => {
    if (myState?.addressRegion) {
      formik.setFieldValue('address.addressRegion', myState?.addressRegion)
    }
  }, [myState])

  if (isLoading) {
    return (
      <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} py="9%" px="8%">
        <CircularProgress isIndeterminate color="var(--accent)" />
      </Flex>
    )
  }

  return (
    <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
      <Text fontSize={'32px'} fontWeight={'600'} textAlign={'center'}>
        Delivery Information and <br /> Age Verification
      </Text>
      <VStack
        width={isMobile ? '100%' : '450px'}
        alignItems="flex-start"
        textAlign="start"
        fontSize="14px"
        lineHeight={'normal'}
        fontWeight={'400'}
      >
        <Text>Please enter your DOB and delivery address for your medication:</Text>
      </VStack>
      <div className={styles.form}>
        <AddressStep formik={formik} fieldErrors={fieldErrors} myState={myState} />
      </div>

      <div className={styles.buttons}>
        <Button
          colorScheme="pink"
          bg={'var(--accent)'}
          width="160px"
          borderRadius="8px"
          isDisabled={true}
          _disabled={{ backgroundColor: 'var(--secondary-gray)', pointerEvents: 'none' }}
        >
          Back
        </Button>
        <Button
          type="submit"
          isDisabled={!isEmpty(formik.errors)}
          isLoading={processing}
          loadingText="Processing"
          colorScheme="pink"
          bg={'var(--accent)'}
          width="160px"
          borderRadius="8px"
        >
          Confirm
        </Button>
      </div>
    </form>
  )
}
