// import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'
import { QueriesKeysEnum } from './queries-keys-enum'

export const useIndexMyOrders = (params = {}, enabled = true) => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.order, params],
    () => axios.get('/indexMyOrders', { params }),
    {
      retry: false,
      enabled,
      onError: (error) => {
        toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
      },
    }
  )
}

export const useReadMyOrder = (params = {}, enabled = true) => {
  const toast = useToast()
  return useQuery([QueriesKeysEnum.order, params], () => axios.get('/readMyOrder', { params }), {
    retry: false,
    enabled,
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
  })
}

export const useIndexOrders = (params = {}, enabled = true) => {
  const toast = useToast()
  return useQuery([QueriesKeysEnum.order, params], () => axios.get('/indexOrders', { params }), {
    retry: false,
    enabled,
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
  })
}

export const useReadOrder = (params = {}, enabled = true) => {
  const toast = useToast()
  return useQuery([QueriesKeysEnum.order, params], () => axios.get('/readOrder', { params }), {
    retry: false,
    enabled,
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
  })
}
