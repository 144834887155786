import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import {
  Box,
  Button,
  HStack,
  PinInput,
  PinInputField,
  Text,
  useBreakpointValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import 'yup-phone'

import { Label } from 'components/layout/label'
import {
  useResendVerificationSmsMutation,
  useVerifyPhoneNumberMutation,
} from 'shared/mutations/user'
import { useUserContextStateDispatch } from '../../shared/contexts/user-context-provider'
// import { environment } from 'environments'
// import TagManager from 'react-gtm-module'

import { APP_PATHS } from 'paths'

// const tagManagerArgs = {
//   gtmId: environment.GOOGLE_TM_LEAD,
// }

// TagManager.initialize(tagManagerArgs)

export const VerifyPhoneNumberPage = () => {
  const { token, phoneNumber } = useParams()
  const { parseLogIn } = useUserContextStateDispatch()
  const toast = useToast()
  const [timer, setTimer] = useState(0)
  const [errorCounter, setErrorCounter] = useState(0)
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState()
  const formikRef = React.useRef(null)

  useEffect(() => {
    setTimer(10)

    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1)
    }, 1000)

    return () => clearInterval(countdown)
  }, [])

  useEffect(() => {
    if (timer <= 0) {
      clearInterval(timer)
    }
  }, [timer])

  const { mutate: verifyPhoneNumber, isLoading } = useVerifyPhoneNumberMutation({
    onSuccess: async (data) => {
      console.log(data)

      parseLogIn(
        data,
        async () => await navigate(APP_PATHS.myProfile),
        () => {},
        (error) => {
          setErrorMessage(error.message)
        }
      )
    },
    onError: (error) => {
      setErrorMessage(error.message)

      // TODO Change validate error.message to error.code
      if (error.message === 'Invalid token code.') {
        formikRef.current.setFieldValue('code', '')
        setErrorCounter(errorCounter + 1)
      }

      if (
        error.message === 'Code invalid tries exceed limit.' ||
        error.message === 'Requested document not found.' ||
        errorCounter === 3
      ) {
        toast({
          title: 'The maximum number of invalid codes has been used, please log in again.',
          status: 'info',
          position: 'top-right',
          duration: 10000,
          isClosable: true,
        })
        navigate(APP_PATHS.registration)
      }
      if (error.message === 'Invalid token.') {
        toast({
          title: 'Expired registration token, please log in again.',
          status: 'info',
          position: 'top-right',
          duration: 10000,
          isClosable: true,
        })
        navigate(APP_PATHS.registration)
      }
    },
  })

  const { mutate: resendSms } = useResendVerificationSmsMutation({
    onSuccess: async () => {
      setTimer(15)

      setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)

      toast({
        title: 'SMS with verification code send.',
        status: 'success',
        position: 'top-right',
        duration: 7000,
        isClosable: true,
      })
    },
    onError: (error) => {
      setErrorMessage(error.message)
    },
  })

  const pinInputSize = useBreakpointValue({ base: 'sm', md: 'md', lg: 'lg' })

  return (
    <Box w="100%" padding="7% 8% 60px 8%">
      <VStack maxW="400px" margin="0 auto" textAlign="center" spacing={10}>
        <Label>Phone Number Verification</Label>
        <Text fontSize="md" mb={6}>
          Please enter the <strong>6 digit code</strong> we have sent to your phone.
        </Text>

        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={{ token, code: '' }}
          onSubmit={({ code }) => verifyPhoneNumber({ token, code })}
          validationSchema={Yup.object({
            code: Yup.string()
              .required('Verification code is required')
              .matches(/^\d{6}$/, 'Verification code must be exactly 6 digits'),
          })}
        >
          {(formik) => (
            <Form
              noValidate
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <VStack spacing={12}>
                <Field name="code">
                  {({ field, form }) => (
                    <HStack spacing={3}>
                      <PinInput
                        otp
                        {...field}
                        onChange={(value) => {
                          form.setFieldValue('code', value)
                          setErrorMessage(null)
                        }}
                        value={formik.values.code}
                        size={pinInputSize}
                        isInvalid={Boolean(form.touched.code && form.errors.code)}
                      >
                        <PinInputField bgColor={'#CBCBCB'} />
                        <PinInputField bgColor={'#CBCBCB'} />
                        <PinInputField bgColor={'#CBCBCB'} />
                        <PinInputField bgColor={'#CBCBCB'} />
                        <PinInputField bgColor={'#CBCBCB'} />
                        <PinInputField bgColor={'#CBCBCB'} />
                      </PinInput>
                    </HStack>
                  )}
                </Field>

                {(errorMessage || formik.errors.code) && (
                  <Box color="red.500" fontSize="sm">
                    {errorMessage || formik.errors.code}
                  </Box>
                )}

                <Button
                  disabled={isLoading}
                  type="submit"
                  colorScheme="pink"
                  mb={4}
                  size="md"
                  h={'36px'}
                  bgColor="var(--accent)"
                  px="28px"
                >
                  Verify
                </Button>

                <Box>
                  <Text fontSize="sm" mb={4}>
                    If you no longer have access to the code or it has timed out, please click
                    the{' '}
                    <Text as="span" color="var(--accent)" fontWeight={'bold'}>
                      ‘Resend Code’
                    </Text>{' '}
                    link below.
                  </Text>

                  <Button
                    variant="outline"
                    colorScheme="pink"
                    size="md"
                    h={'36px'}
                    onClick={() => resendSms({ phoneNumber })}
                    isDisabled={timer > 0 || isLoading}
                    color="var(--accent)"
                    bgColor="white"
                  >
                    Resend Code
                  </Button>
                </Box>

                <Box mt={14} textAlign="start" fontSize="12px" lineHeight={'normal'}>
                  <Text fontWeight="bold" fontStyle={'italic'}>
                    Why do we need to verify your phone number?
                  </Text>
                  <Text>
                    Our qualified physicians will use your phone number as the primary contact
                    mechanism for engaging with you.
                  </Text>
                </Box>
              </VStack>
            </Form>
          )}
        </Formik>
      </VStack>
    </Box>
  )
}
