export const States = [
  { key: 'AK', text: 'AK' },
  { key: 'AL', text: 'AL' },
  { key: 'AR', text: 'AR' },
  { key: 'AS', text: 'AS' },
  { key: 'AZ', text: 'AZ' },
  { key: 'CA', text: 'CA' },
  { key: 'CM', text: 'CM' },
  { key: 'CO', text: 'CO' },
  { key: 'CT', text: 'CT' },
  { key: 'DC', text: 'DC' },
  { key: 'DE', text: 'DE' },
  { key: 'FL', text: 'FL' },
  { key: 'GA', text: 'GA' },
  { key: 'GU', text: 'GU' },
  { key: 'HI', text: 'HI' },
  { key: 'IA', text: 'IA' },
  { key: 'ID', text: 'ID' },
  { key: 'IL', text: 'IL' },
  { key: 'IN', text: 'IN' },
  { key: 'KS', text: 'KS' },
  { key: 'KY', text: 'KY' },
  { key: 'LA', text: 'LA' },
  { key: 'MA', text: 'MA' },
  { key: 'MD', text: 'MD' },
  { key: 'ME', text: 'ME' },
  { key: 'MI', text: 'MI' },
  { key: 'MN', text: 'MN' },
  { key: 'MO', text: 'MO' },
  { key: 'MS', text: 'MS' },
  { key: 'MT', text: 'MT' },
  { key: 'NC', text: 'NC' },
  { key: 'ND', text: 'ND' },
  { key: 'NE', text: 'NE' },
  { key: 'NH', text: 'NH' },
  { key: 'NJ', text: 'NJ' },
  { key: 'NM', text: 'NM' },
  { key: 'NV', text: 'NV' },
  { key: 'NY', text: 'NY' },
  { key: 'OH', text: 'OH' },
  { key: 'OK', text: 'OK' },
  { key: 'OR', text: 'OR' },
  { key: 'PA', text: 'PA' },
  { key: 'PR', text: 'PR' },
  { key: 'RI', text: 'RI' },
  { key: 'SC', text: 'SC' },
  { key: 'SD', text: 'SD' },
  { key: 'TN', text: 'TN' },
  { key: 'TX', text: 'TX' },
  { key: 'TT', text: 'TT' },
  { key: 'UT', text: 'UT' },
  { key: 'VT', text: 'VT' },
  { key: 'VA', text: 'VA' },
  { key: 'VI', text: 'VI' },
  { key: 'WA', text: 'WA' },
  { key: 'WV', text: 'WV' },
  { key: 'WI', text: 'WI' },
  { key: 'WY', text: 'WY' },
]

export const STATE_TO_NAME = {
  AL: 'Alabama',
  AK: 'Alaska',
  // AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  // PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  DC: 'Washington DC',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}
