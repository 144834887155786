// import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'

export const useActualizeMyMetricsMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/actualizeMyMetrics', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}
