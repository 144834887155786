import React from 'react'
import PropTypes from 'prop-types'

import { FormikInput, PasswordInput } from '../../../components/elements/input'
import { Link } from 'react-router-dom'
import { APP_PATHS } from 'paths'
import { Checkbox, HStack, Text } from '@chakra-ui/react'

export const InitialStep = ({ formik, fieldErrors }) => (
  <>
    <FormikInput
      name="firstName"
      label="Legal First Name"
      placeholder="First Name"
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
      color="white"
    />
    <FormikInput
      name="lastName"
      label="Legal Last Name"
      placeholder="Last Name"
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
      color="white"
    />
    <FormikInput
      name="email"
      label="Email Address"
      placeholder="Email"
      required
      type="email"
      formik={formik}
      errors={fieldErrors}
      color="white"
    />
    <PasswordInput
      name="password"
      label="Password"
      placeholder="Password"
      required
      formik={formik}
      errors={fieldErrors}
      color="white"
    />
    <PasswordInput
      name="confirmPassword"
      label="Confirm Password"
      placeholder="Password"
      required
      formik={formik}
      errors={fieldErrors}
      color="white"
    />
    <HStack pt={'20px'}>
      <Text fontSize={'16px'} lineHeight={'20px'} color={'#4F5B76'}>
        Click to accept Our{' '}
        <Link
          to={APP_PATHS.termsAndConditions}
          target="__blank"
          style={{ textDecoration: 'underline', fontWeight: '700' }}
        >
          Terms and Conditions
        </Link>
      </Text>
      <Checkbox
        name="acceptedTermsVersion"
        size="lg"
        colorScheme="pink"
        borderColor={'var(--accent)'}
        required={true}
        checked={formik.values.acceptedTermsVersion}
        onChange={(event) => {
          formik.setFieldValue('acceptedTermsVersion', +event.target.checked)
        }}
        sx={{
          'span.chakra-checkbox__control': {
            borderRadius: '5px',
          },
        }}
      />
    </HStack>
  </>
)

InitialStep.propTypes = {
  formik: PropTypes.object.isRequired,
}
