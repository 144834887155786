/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import styles from './styles.module.css'
import { Label } from 'components/layout/label'

export const Page = () => {
  return (
    <div className={styles.wrapper}>
      <Label>Maion.ai Terms and Conditions</Label>
      <div className={styles.text_wrapper}>
        <p>Terms and Conditions of Use</p>
        <span>Date of Last Revision: May 07, 2024</span>
        <p>
          AMONG OTHER ACTIVITIES, MAION’S (“MAION,” “US,” “OUR”) SERVICES (THE “SERVICES”) ENABLE
          COORDINATION AND COMMUNICATION BETWEEN A PATIENT (“PATIENT,” “YOU,” “YOUR”) WITH A
          HEALTH CARE PROVIDER. THEY DO NOT REPLACE YOUR RELATIONSHIP WITH ANY PHYSICIAN. MAION
          DOES NOT PROVIDE MEDICAL ADVICE.
          <br />
          <br />
          MAION SERVICES MIGHT NOT BE APPROPRIATE FOR ALL MEDICAL CONDITIONS OR CONCERNS. IF YOU
          THINK YOU MAY HAVE A MEDICAL EMERGENCY OR MENTAL HEALTH CRISIS, CALL YOUR DOCTOR OR 911
          IMMEDIATELY. DO NOT ACCESS THE SERVICES FOR EMERGENCY OR CRISIS CARE.
          <br />
          <br />
          THIS AGREEMENT CONTAINS PROVISIONS THAT LIMIT OUR LIABILITY TO YOU AND REQUIRE YOU TO
          RESOLVE ANY DISPUTE WITH US THROUGH FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL
          BASIS, NOT THROUGH A LAWSUIT OR COURT PROCESS. THIS MEANS THAT (I) YOU ARE GIVING UP,
          AND YOU WAIVE, THE RIGHT TO HAVE ANY SUCH DISPUTE DECIDED IN A COURT OF LAW BEFORE A
          JURY OR A JUDGE (EXCEPT AS SET FORTH EXPRESSLY IN SECTION 16), AND (II) THAT YOU ARE
          GIVING UP, AND YOU WAIVE, THE ABILITY TO PURSUE ANY SUCH DISPUTE IN A CLASS,
          CONSOLIDATED, OR REPRESENTATIVE ACTION OR PROCEEDING. PLEASE SEE SECTIONS 10, 12, 13
          AND 16 FOR MORE INFORMATION.
        </p>
        <br />
        <br />
        <p>Introduction</p>
        <span>
          Maion Inc. and its affiliates (collectively, "Maion," "we ", "us", or "our") own and
          operate the websites located at www.maion.ai, losers.ai (collectively, the "Websites"),
          and their associated mobile applications ("Apps"). We refer to the Websites, Apps, and
          other services provided by Maion together herein as the "Services." In these Terms of
          Use, the terms "you" and "yours" refer to the person using the Services.
        </span>
        <span>
          These terms and conditions of use ("Terms of Use") describe your rights and
          responsibilities with regard to the Services. Your access to and use of the Services is
          subject to these Terms of Use, our Privacy Policy, as well as all applicable laws and
          regulations. In using certain parts of the Services, you may be presented with
          additional or supplementary terms regarding the use of those specific Services, and you
          agree to review and be bound by such additional terms.
        </span>
        <p>
          PLEASE READ THESE TERMS OF USE CAREFULLY BECAUSE THEY SET FORTH THE IMPORTANT TERMS YOU
          WILL NEED TO KNOW ABOUT THE SERVICES.
        </p>
        <br />
        <p>
          YOU UNDERSTAND THAT BY ACCESSING OR USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE
          READ, UNDERSTOOD, AND AGREE TO BE LEGALLY BOUND BY AND COMPLY WITH THESE TERMS OF USE.
          IF YOU DO NOT OR CANNOT AGREE WITH ANY PART OF THESE TERMS OF USE, YOU MAY NOT USE THE
          SERVICES.
        </p>
        <br />
        <p>THE TERMS OF USE ARE SUBJECT TO CHANGE AS PROVIDED HEREIN.</p>
        <span>These Terms of Use Contain the Following Sections:</span>
        <span>
          Introduction
          <br />
          1. Services Overview, Availability, and Eligibility
          <br />
          2. Telehealth and Pharmacy
          <br />
          3. Registration and Account Creation
          <br />
          4. Payments
          <br />
          5. Termination
          <br />
          6. Communications by Text Message and Email
          <br />
          7. Privacy
          <br />
          8. Ownership and License to Use the Services
          <br />
          9. Restrictions on Use
          <br />
          10. User Content and Maion's License to Use Such Content
          <br />
          11. Copyright Notices
          <br />
          12. Disclaimer of Warranties
          <br />
          13. Limitation of Liability
          <br />
          14. Indemnification
          <br />
          15. Changes to the Terms of Use and the Services
          <br />
          16. Governing Law; Dispute Resolution; Arbitration; Venue; Severability
          <br />
          17. Miscellaneous Terms
          <br />
          18. Contact Us
          <br />
          19. Notice to Patients - Open Payments Database
        </span>
        <p>1. Services Overview, Availability, and Eligibility</p>
        <span>
          Overview of the Services. The Services may include (i) providing individuals with
          information on health care and wellness; (ii) providing individuals with pharmacy
          services; (iii) providing individuals with access to technology-oriented tools for
          addressing certain health issues; (iv) development and gathering of health care records
          and health care information with retention of the same for use in health care provider
          appointments, communications, and pharmacy services; (v) administrative support in
          connection with scheduling, payment for health care provider services, and payment for
          pharmacy services; and (vi) telecommunications support for using the Services as a
          means of direct access to a health care provider for communication, consultations,
          assessments, and treatment by such health care organisations and their providers.
        </span>

        <span>
          Availability - Certain services are currently only available to individuals located in
          certain states. You will be provided with notice of such limitations before any
          payments are taken.
        </span>

        <span>
          Maion provides Services for use only by persons located in the United States. We make
          no claims or representations that the Services or any material included in the Services
          are accessible or appropriate outside of the United States. Access to the Services may
          not be legal by certain persons or in certain countries. If you access the Services
          from outside the United States, you do so at your own risk and you are solely
          responsible for compliance with local laws, including export laws as applicable.
        </span>
        <span>
          Eligibility - Our Services are intended for use by you only if you are 18 years of age
          or over. To qualify to use the Services, the following must be true, and by accessing
          or using the Services, you represent and warrant that they are true:
        </span>
        <span>
          <ul>
            <li>
              You are age 18 or over or, if you are accessing the Services in violation of the
              Eligibility requirement related to age, you otherwise have the express consent of
              your parent or legal guardian.
            </li>
            <li>
              You are located in a state where we operate (depending on the type of Services).
            </li>
            <li>You agree to be legally bound by and comply with these Terms of Use.</li>
          </ul>
        </span>
        <span>
          You understand and agree that satisfying the above requirements does not guarantee that
          you will receive Services. In addition to the above requirements, Maion and certain
          affiliated professional entities reserve the right to change or include new
          requirements as deemed appropriate in their sole discretion without providing prior
          notice to you.
        </span>
        <span>
          To access or use the Services, you must have compatible devices, access to the
          Internet, and certain necessary software. Fees and charges may apply to your use of
          mobile services and to the Internet.
        </span>
        <p>2. Telehealth and Pharmacy</p>

        <span>
          Telehealth Services. Telemedicine involves the delivery of health care services using
          electronic communications, information technology, or other means between a health care
          provider and a patient who are not in the same physical location. Telemedicine may be
          used for diagnosis, treatment, follow-up and/or related patient education, and may
          include, but is not limited to:
        </span>

        <span>
          <ul>
            <li>
              Electronic transmission of medical records, photo images, personal health
              information, or other data between a patient and health care provider;
            </li>
            <li>
              Interactions between a patient and health care provider via audio, video, and/or
              synchronous and asynchronous data communications; and
            </li>
            <li>Use of output data from medical devices, sound, and video files.</li>
          </ul>
        </span>

        <span>
          The electronic systems used in the Services will incorporate network and software
          security protocols to protect the privacy and security of health information, and will
          include measures to safeguard your data to ensure its integrity against intentional or
          unintentional corruption or unlawful access. By accepting the Terms of Use, you agree
          and consent to Maion, the affiliated physician practices, the health care providers
          contracted or employed by the affiliated physician practices, and any other health care
          organisation(s) or provider(s) with whom we partner to provide the Services to you
          sending you disclosures, notices, messages, reports, and other communications either in
          writing or electronically including without limitation through your account or by email
          or SMS (text). It is your responsibility to monitor these communications. You
          acknowledge and agree that you will not hold us, any affiliated physician practice, any
          health care provider contracted or employed by an affiliated physician practice, or any
          healthcare organisation(s) or provider(s) with whom we partner to offer the Services to
          you liable for any loss, injury, or claim of any kind resulting from your failure to
          read these communications or for your failure to comply with any treatment
          recommendations contained in these communications. Moreover, you acknowledge and agree
          that no means of electronic transmission can be guaranteed to be 100% secure. By
          accessing or using the Services, you agree to us transmitting health and other
          information to you electronically and that any information sent to or signed by you or
          us electronically shall be deemed equivalent to as if such information was provided or
          signed in writing.
        </span>

        <span>
          Maion Role - Maion does not provide any medical services, including via the Services.
          Rather, Maion provides a technology platform for you to access a health care provider
          who is employed or contracted with a Maion affiliated physician practice and obtain
          access to additional information, which you may or may not choose to utilise in
          planning your health care and wellness. The health and wellness resources made
          available through our Services are for informational purposes only and are not a
          substitute for direct in-person health care services in all cases; nor are they an
          indicator of specific results. The decision to focus on diagnosis, treatment
          recommendations, or both, rests with you and the health care provider. You understand
          that by coordinating and consulting with an affiliated physician practice or its health
          care providers through the Services, you are not entering into a provider-patient
          relationship with Maion.
        </span>

        <span>
          NONE OF THE INFORMATION YOU RECEIVE THROUGH MAION SERVICES SHOULD BE CONSIDERED OR IS
          MEDICAL ADVICE. Only the Maion-affiliated physician practices and their health care
          providers can legally provide medical advice.
        </span>

        <span>
          No Insurance Accepted; Notice of Your Financial Responsibility. Maion and the
          affiliated physician practices do not accept commercial health insurance plans, are not
          in-network with any commercial health insurance plans, and are not enrolled with
          federal or state healthcare programs, such as Medicare and Medicaid. By choosing to use
          the Services, you are specifically choosing to obtain products and services on a
          cash-pay basis outside of any commercial health insurance plan or federal or state
          health care program. Thus, you are solely responsible for the costs of any services or
          products provided to you. If you are a federal health program beneficiary, you agree
          that neither you, Maion, the affiliated physician practices, or any of the health care
          organization(s) or provider(s) with whom we partner to provide health care and pharmacy
          services to you will submit a claim for reimbursement to any federal or state
          healthcare program for the costs of the services and products provided to you through
          the Services.
        </span>

        <span>
          You acknowledge that your medication will not be shipped in child-resistant packaging
          and that you must keep it out of the reach of children.
        </span>

        <p>3. Registration and Account Creation</p>
        <span>
          You will be required to create an account to access and use certain parts of the
          Services. If you create an account, you agree to provide information that is accurate,
          complete and correct, and to accurately maintain and update any information about
          yourself that you have provided to Maion. If you do not maintain such information, or
          Maion has reasonable grounds to suspect as much, Maion has the right to suspend or
          terminate your account and your use of the Services. You agree to keep confidential
          your username and password and to exit from your account at the end of each session.
          You are responsible for all activities that occur under your account and for
          maintaining the confidentiality of your password. You also agree to immediately notify
          Maion of any unauthorised use of your username, password or any other breach of
          security that you become aware of involving or relating to the Services by emailing
          Maion at support@maion.ai. Maion may take any and all actions it deems necessary or
          reasonable to maintain the security of the Services.
        </span>

        <span>
          You are responsible for changing your password promptly if you think it has been
          compromised. You may not transfer or share your password with anyone or create more
          than one account. You may not use anyone else's account at any time. Maion explicitly
          disclaims liability for any and all losses and damages arising from your failure to
          comply with this section.
        </span>

        <span>
          Our Apps are intended for use only on mobile phones that run an unmodified manufacturer
          approved operating system. Using the Apps on a mobile phone with a modified operating
          system may undermine security features that are intended to protect your protected
          health information and other information from unauthorised or unintended disclosure.
          Use of the App on a mobile phone with a modified operating system is a material breach
          of these Terms of Use.
        </span>

        <p>4. Payments</p>
        <span>
          You agree to pay all fees due for services requested at checkout, which occurs after
          completion of the intake questionnaire, and pursuant to all payment terms presented to
          you when engaging in transactions. Prices are subject to change at any point in our
          sole discretion. You will see a prompt for your payment details, such as your credit
          card information and any promotional codes you may have. By entering your payment
          information and submitting your request, you authorise us, our affiliates, or our
          third-party payment processors to charge the amount due, including recurring fees
          associated with Subscription Services (as defined below).
        </span>
        <span>
          You understand and agree that you are responsible for all fees due to receive health
          care services and pharmacy services, including any fees charged by the health care
          organization(s) or provider(s) that provide services to you in connection with the
          Services. Your payments to Maion may include fees charged by healthcare organization(s)
          or provider(s) for health care services and/or pharmacy services, which Maion collects
          on their behalf. In the event that your credit card expires or Maion, our affiliates,
          or our third-party payment processors are unable to process your payment, you may
          receive notice for you to provide an alternative payment method. Maion and/or the
          healthcare organization(s) and/or provider(s) have no obligation to provide any health
          care services or pharmacy services unless and until full payment has been received
          and/or verified. You are responsible for keeping your payment information (such as
          credit card number and billing address) accurate and up to date at all times.
        </span>
        <span>
          Certain products or services offered on the Services may be offered on a subscription
          basis ("Subscription Services"). For Subscription Services, your payment method will be
          automatically charged at regular intervals as described during the checkout process for
          the applicable Subscription Services. Depending on the specific Service you select, you
          may be charged at the time you submit a request (for instance, a treatment request),
          and the products or services purchased will begin to be provided after all onboarding
          steps are complete (for instance, after your treatment request is approved by a
          provider and you provide all required information). For other Services, you may not be
          charged until all required onboarding steps are completed.
        </span>
        <span>
          You may cancel a Subscription Service to prevent future renewals at any time before the
          cancellation cut off date as indicated to you at the time you purchase a Subscription
          Service. You will also be informed about how you can cancel these Subscription
          Services.
        </span>
        <span>
          EXCEPT AS OTHERWISE SET FORTH IN ANY RETURN OR REFUND POLICY PROVIDED TO YOU ON THE
          SERVICES AND AS EXPLAINED BELOW, YOU ACKNOWLEDGE AND AGREE THAT DUE TO THE NATURE OF
          THE PRODUCTS AND SERVICES PURCHASABLE THROUGH THE SERVICES ANY APPLICABLE FEES AND
          OTHER CHARGES ARE NOT REFUNDABLE IN WHOLE OR IN PART. YOU ARE FULLY LIABLE FOR ALL
          CHARGES TO YOUR ACCOUNT.
        </span>
        <span>
          You will automatically be refunded if we have already charged your payment method and
          you are subsequently deemed unsuitable for treatment or we reject your treatment
          request for any other reason. These refunds (along with all refunds described in these
          Terms or otherwise provided by Maion) may take 5 business days or longer to process.
        </span>
        <span>
          When you submit a treatment request, an alternative treatment plan that better aligns
          with your health needs might be suggested. When this occurs, you will be prompted to
          consider the suggested alternative. If you accept the suggested alternative treatment
          plan and your payment method has already been charged, the initial charge will be
          refunded automatically, and you will incur a new charge for the alternative treatment
          plan. If you take no action and do not accept the alternative treatment plan, your
          treatment request may expire (at which point you will automatically be refunded if your
          payment method has already been charged). Once expired, in order to receive the desired
          Services, you will need to initiate a new treatment request.
        </span>

        <p>5. Termination</p>
        <span>
          The Terms of Use will remain in full force and effect as long as you continue to access
          or use the Services. You may terminate the Terms of Use at any time by discontinuing
          use of the Services. Your permission to use the Services automatically terminates if
          you violate these Terms of Use.
        </span>

        <span>
          Maion may terminate or suspend any of the rights granted by these Terms of Use and your
          access to and use of the Services with or without prior notice, for any reason, and at
          any time, including for violations of these Terms of Use. The following provisions
          survive the expiration or termination of these Terms of Use for any reason whatsoever:
          Disclaimer of Warranties; Limitation of Liability; Indemnification; Governing Law,
          Dispute Resolution, Arbitration, Venue, Severability of Provisions; No Waiver; and
          Assignment.
        </span>

        <span>
          Subject to applicable law, Maion reserves the right to maintain, delete or destroy all
          communications and materials posted or uploaded to the Services pursuant to its
          internal record retention and/or content destruction policies. After such termination,
          Maion will have no further obligation to provide the Services, except to the extent an
          affiliated professional entity is obligated to provide you access to your health
          records or is required to provide you with continuing care under applicable legal,
          ethical and professional obligations to you. You agree that if your use of the Services
          is terminated pursuant to these Terms of Use, you will not attempt to use the Services
          in any way, and further agree that if you violate this restriction after such
          termination, you will indemnify and hold Maion harmless from any and all liability that
          Maion may incur as a result.
        </span>

        <p>6. Communications by Text Message and Email</p>
        <span>
          Acceptance of these terms and conditions constitutes opting-in to the use of SMS (
          “SMS-Enrollment”) and you consent to receiving text messages regarding your Maion
          account and use of the Services. These text messages may include order confirmations,
          shipping notifications, messages from your healthcare provider, and other transactional
          messages, as well as promotional and marketing notifications, to the extent you have
          opted-in to receive such messages from Maion.
        </span>

        <span>
          With your SMS Enrollment, you represent and understand that: (1) you are the owner or
          authorised user of the mobile device you used in order to initiate the SMS Enrollment,
          (2) you approve any applicable charges in connection with the text messages you send to
          and receive from Maion, (3) you will be responsible for all messaging and other data
          charges that may apply for any text messages sent to you from Maion, or from Maion to
          you, and (4) neither Maion, nor your or Maion's mobile carriers, will be liable for
          delayed or undelivered messages.
        </span>

        <span>
          You also understand that while Maion takes your privacy and the security of your health
          and other sensitive information very seriously, the transmission of information over
          the internet and mobile networks is not 100% secure. Text messages and emails that you
          send to or receive from Maion are not encrypted, which means that it is possible they
          may be intercepted by third parties. If you choose to send or receive information about
          your health or any other sensitive information by text message or email, you do so at
          your own risk. By initiating an SMS Enrollment, you consent to sending text messages to
          Maion, and receiving text messages from Maion, that are not encrypted.
        </span>

        <span>
          If you are experiencing any issues with Maion's text messaging or email services, or if
          you have any concerns about sending or receiving any sensitive information through text
          or email, please contact us directly at support@maion.ai.
        </span>

        <p>7. Privacy</p>
        <span>
          Maion understands the importance of confidentiality and privacy regarding your health
          information. Please see our Privacy Policy available at maion.ai/privacy for
          information about how we collect and use your information The Privacy Policy is hereby
          incorporated into these Terms of Use by reference and constitute a part of these Terms
          of Use.
        </span>

        <p>8. Ownership and License to Use the Services</p>
        <span>
          Ownership. As between Maion and you, Maion is the sole and exclusive owner of all
          right, title and interest in and to the Services and their content, features and
          functionality (including, without limitation, all information, software, text,
          displays, images, video, audio, design, selection, arrangement and look and feel)
          ("Services Content"). You are not permitted to reproduce, publish, distribute, modify,
          reverse engineer, disassemble, create derivative works of, publicly display, publicly
          perform, republish, download, store, transmit, sell or participate in any sale of, or
          exploit in any way, in whole or in part, any of the Services or Services Content except
          as permitted by these Terms of Use or otherwise by Maion expressly in writing. Any
          copy, modification, revision, enhancement, adaptation, translation, or derivative work
          of the Services or Services Content shall be owned solely and exclusively by Maion or
          its licensors, including all intellectual property rights therein. You may not access
          or use for any commercial purposes any part of the Services or Services Content.
        </span>

        <span>
          Your License. Subject to your compliance with these Terms of Use, we hereby grant to
          you a personal, limited, revocable, non-exclusive, and nontransferable right to view,
          download, access, and use the Services and Services Content solely for your personal
          and non-commercial use and only as permitted under these Terms of Use and the Privacy
          Policy. No other right, title, or interest in or to the Services or Services Content is
          transferred to you, and all rights not expressly granted are reserved by Maion or its
          licensors.
        </span>

        <p>9. Restrictions on Use</p>
        <span>
          You agree that in using or accessing the Services, you will not, and will not attempt
          to:
        </span>
        <span>
          <ul>
            <li>
              impersonate any person or entity or otherwise misrepresent your affiliation with a
              person or entity
            </li>
            <li>
              violate any local, state, national or international law (including export laws)
            </li>
            <li>
              reverse engineer, disassemble, decompile, or translate any software or other
              components of the Services
            </li>
            <li>
              distribute, input, upload, transmit, or otherwise run or propagate any virus,
              application, Trojan horse, or any other harmful computer code that could damage or
              alter a computer, portable device, computer network, communication network, data,
              or the Services, or any other system, device, or property
            </li>
            <li>
              access or use the Services in any manner or for any purpose that infringes,
              misappropriates, or otherwise violates any intellectual property right or other
              right of any third party
            </li>
            <li>
              use any robot, spider, scraper, or other automated means to access the Services for
              any purpose without our express prior written permission or bypass our robot
              exclusion headers or other measures we may use to prevent or restrict access to the
              Services
            </li>
            <li>
              licence, sublicense, sell, resell, transfer, assign, distribute or otherwise
              commercially exploit or make available to any third party the Services, Service
              Content, or User Content other than as expressly permitted herein
            </li>
            <li>
              damage, destroy, disrupt, disable, impair, overburden, interfere with, or otherwise
              impede or harm in any manner the Services, Service Content, or User Content, in
              whole or in part
            </li>
            <li>
              post, transmit or otherwise disseminate any content that, as we determine at our
              sole discretion: (i) is unlawful, harmful, harassing, fraudulent, threatening,
              abusive, libellous, defamatory, vulgar, obscene, hateful, violent, demeaning,
              intimidating, discriminatory, or racially, ethnically or otherwise objectionable,
              or infringes our or any third party's intellectual property or other rights, (ii)
              is derogatory or harmful to our reputation, the reputation of our licensors,
              affiliated healthcare providers, affiliated healthcare practices, or any of our or
              their respective officers, members, employees, representatives, licensors and/or
              suppliers, in any way; (iii) may incite violence or other unlawful activity; or
              (iv) is harmful to children in any manner; or (iv) attempts to obtain the personal
              information of other users
            </li>
            <li>
              harm, harass, threaten, abuse, defame, demean, discriminate against, or intimidate
              any affiliated health care provider who provides health care services related to
              the Services, as we determine in our sole discretion
            </li>
            <li>
              disrupt, interfere with, violate the security of, or attempt to gain unauthorised
              access to the Services or any computer network
            </li>
            <li>
              bypass, breach, avoid, remove, deactivate, impair, descramble, or otherwise
              circumvent any security device, protection, or technological measure implemented by
              Maion or any of our service providers to protect the Services
            </li>
            <li>
              remove, delete, alter, or obscure any trademarks, specifications, warranties, or
              disclaimers, or any copyright, trademark, patent, or other intellectual property or
              proprietary rights notices from the Services or any Service Content or User Content
            </li>
            <li>
              use any manual process or automated device to monitor or copy any content made
              available on or through the Services for any unauthorised purpose except as
              permitted herein
            </li>
            <li>
              to transmit, or procure the sending of, any advertising or promotional material
              without our prior written consent, including any "junk mail", "chain letter" or
              "spam" or any other similar solicitation
            </li>
            <li>
              copy, duplicate, download, store in a retrieval system, publish, transmit or
              otherwise reproduce, transfer, distribute, store, disseminate, aggregate, use as a
              component of or as the basis for a database or otherwise use in any form or by any
              means any data, text, reports, or other materials related to Maion or third-party
              content from the Services
            </li>
            <li>
              otherwise use the Services in any manner that exceeds the scope of use granted
              herein
            </li>
            <li>encourage or enable any other individual to do any of the foregoing</li>
          </ul>
        </span>

        <p>10. User Content and Maion's License to Use Such Content</p>
        <span>
          Except as provided in our Privacy Policy; or information governed by applicable federal
          and state-specific privacy laws and regulations; you understand and agree that any
          information you provide through the Services, whether by direct entry, submission,
          email or otherwise, including, but not limited to, data, questions, comments, forum
          communications, or suggestions/feedback, will be treated as non-confidential and
          non-proprietary and will become the property of Maion and/or (i) the affiliated
          physician practices, or (ii) individual health providers utilising the Services ("User
          Content").
        </span>

        <span>
          Except as provided in our Privacy Policy or subject to any applicable laws, User
          Content may be used by Maion for any purpose, including, without limitation,
          reproduction, solicitation, disclosure, transmission, publication, broadcast, and
          posting, and Maion shall be free to use such User Content for any purpose whatsoever,
          including, without limitation, developing and marketing products using such
          information, without any compensation owed to you. You hereby grant Maion, our service
          providers, our successors and assigns, our affiliated health care providers, and their
          affiliated physician practices , an irrevocable, fully transferable and sublicensable
          right and licence to use, reproduce, modify, analyse, perform, display, distribute, and
          otherwise disclose to third parties any data or information you submit on or through
          the Services for the purposes of providing Services to you; marketing Services to you;
          conducting research or analyses of such data; and designing, developing, implementing,
          modifying and/or improving new, current or future features, products and services of
          Maion using such data. You understand that any User Content you publish in public
          forums will be viewable and copyable by other users of the forum and potentially the
          public at large.
        </span>

        <span>
          WITHOUT LIMITATION OF THE FOREGOING, MAION DOES NOT ASSUME RESPONSIBILITY OR LIABILITY
          FOR THE USER CONTENT FOUND IN ANY PUBLIC FORUM, NOR FOR ANY CLAIMS, DAMAGES OR LOSSES
          RESULTING FROM ANY USE OF ANY PUBLIC FORUM OR THE USER CONTENT CONTAINED THEREIN.
        </span>

        <span>
          You acknowledge, consent, and agree that we may access, monitor, preserve, and disclose
          your information and/or any User Content you submit or make available for inclusion on
          the Services, if required to do so by law or in a good faith belief that such access,
          preservation, or disclosure is permitted by law or reasonably necessary or appropriate
          for any of the following reasons: (1) to comply with legal process; (2) to enforce
          these Terms of Use, our Privacy Policy, or other contracts with you, including
          investigation of potential violations thereof; (3) to respond to claims that any
          content violates the rights of third parties; (4) to respond to your requests for
          customer service; and (5) to protect the rights, property, or personal safety of Maion,
          its agents and affiliates, its users, and the public. This includes exchanging
          information with other companies and organisations for fraud protection, and
          spam/malware prevention, and similar purposes.
        </span>

        <p>11. Copyright Notices</p>
        <span>
          Maion reserves the right to remove any content or any other material or information
          available on or through our Services, at any time, for any reason. Maion otherwise
          complies with the provisions of the Digital Millennium Copyright Act ("DMCA")
          applicable to Internet service providers (17 U.S.C. § 512, as amended), and responds to
          clear notices of alleged copyright infringement. This section describes the procedure
          that should be followed to file a notification of alleged copyright infringement with
          Maion.
        </span>

        <span>
          Notification of Claimed Copyright Infringement. If you have objections to copyrighted
          content or material made available on or through our Services, you may submit a
          notification to legal@maion.ai
        </span>

        <span>
          Any notification to Maion under 17 U.S.C. § 512(c) alleging copyright infringement must
          include all of the following information:
        </span>

        <span>
          <ul>
            <li>
              An electronic or physical signature of the person authorised to act on behalf of
              the owner of the exclusive right being infringed
            </li>
            <li>
              An identification of the copyrighted work or other intellectual property that you
              claim has been infringed or, if multiple copyrighted works are covered by a single
              notification, a representative list of such works
            </li>
            <li>
              An identification of the content or material that you claim is infringing and where
              it is located on our Services
            </li>
            <li>
              Information sufficient for Maion to contact you, such as your address, telephone
              number, and/or email address
            </li>
            <li>
              A statement by you that you have a good-faith belief that the use of the content or
              material of which you are complaining is not authorised by the copyright owner, its
              agent, or the law
            </li>
            <li>
              A signed statement by you that the above information in your notice is accurate and
              that, under penalty of perjury, you are the copyright owner or authorised to act on
              the copyright owner's behalf
            </li>
          </ul>
        </span>

        <p>12. Disclaimer of Warranties</p>
        <span>
          YOU EXPRESSLY AGREE THAT USE OF THE SERVICES IS AT YOUR SOLE RISK. YOU ACKNOWLEDGE AND
          AGREE THAT THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. MAION AND
          ITS AFFILIATES, INCLUDING WITHOUT LIMITATION ALL AFFILIATED PHYSICIAN PRACTICES, AND
          THEIR RESPECTIVE OFFICERS, DIRECTORS, MANAGERS, PARTNERS, MEMBERS, EMPLOYEES, AND
          AGENTS (COLLECTIVELY "RELATED PERSONS") MAKE NO REPRESENTATIONS OR WARRANTIES AND
          EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, WITH
          RESPECT TO THE SERVICES INCLUDING, BUT NOT LIMITED TO, ANY REPRESENTATIONS OR
          WARRANTIES WITH RESPECT TO MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE,
          NONINFRINGEMENT, TITLE, AVAILABILITY, SECURITY, OPERABILITY, CONDITION, QUIET
          ENJOYMENT, VALUE, ACCURACY OF DATA, FREEDOM FROM VIRUSES OR MALWARE, COMPLETENESS,
          TIMELINESS, FUNCTIONALITY, RELIABILITY, SEQUENCING OR SPEED OF DELIVERY OR SYSTEM
          INTEGRATION. WE MAKE NO WARRANTIES OR REPRESENTATIONS THAT YOUR USE OF THE SERVICES
          WILL NOT INFRINGE THE RIGHTS OF THIRD PARTIES.
        </span>

        <span>
          TO THE FULLEST EXTENT OF APPLICABLE LAW, NEITHER MAION NOR ITS RELATED PERSONS WILL BE
          LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON INFORMATION OBTAINED THROUGH
          THE SERVICES. IT IS YOUR RESPONSIBILITY TO EVALUATE THE ACCURACY, COMPLETENESS,
          TIMELINESS, RELIABILITY OR USEFULNESS OF THE SERVICES, SERVICES CONTENT, AND USER
          CONTENT. FURTHERMORE, MAION DOES NOT GUARANTEE THAT THE SERVICES WILL BE UNINTERRUPTED,
          OR FREE FROM ERROR, DEFECT, LOSS, DELAY IN OPERATION, CORRUPTION, CYBER ATTACK,
          VIRUSES, INTERFERENCE, HACKING, MALWARE, OR OTHER SECURITY INTRUSION, AND MAION
          DISCLAIMS ANY LIABILITY RELATING THERETO.
        </span>

        <span>
          YOU UNDERSTAND AND AGREE THAT ANY CONTENT, MATERIAL AND/OR INFORMATION OBTAINED THROUGH
          THE USE OF THE SERVICES ARE USED AT YOUR SOLE RISK AND THAT YOU WILL BE SOLELY
          RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER OR MOBILE PHONE OR LOSS OF DATA THAT
          RESULTS FROM THE DOWNLOAD OF SUCH CONTENT, MATERIAL AND/OR INFORMATION, OR YOUR
          RELIANCE ON ANY SUCH CONTENT, MATERIAL, AND/OR INFORMATION.
        </span>

        <p>13. Limitation of Liability</p>
        <span>
          YOU UNDERSTAND THAT TO THE EXTENT PERMITTED UNDER APPLICABLE LAW AND EXCEPT AS SET
          FORTH IN THIS SECTION, IN NO EVENT WILL MAION, ITS RELATED PERSONS OR LICENSORS BE
          LIABLE TO YOU OR TO ANY PARTY FOR ANY CLAIMS, LIABILITIES, LOSSES, COSTS OR DAMAGES
          UNDER ANY LEGAL OR EQUITABLE THEORY, WHETHER IN TORT (INCLUDING NEGLIGENCE AND STRICT
          LIABILITY), CONTRACT, WARRANTY, STATUTE OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO,
          ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES,
          INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL, USE OF
          DATA, SERVICE INTERRUPTION, COMPUTER OR MOBILE PHONE DAMAGE, OR SYSTEM FAILURE, OR THE
          COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY
          INJURY OR EMOTIONAL DISTRESS, INCLUDING DEATH, ARISING OUT OF OR IN CONNECTION WITH ANY
          ACCESS, USE OF (OR INABILITY TO USE) THE SERVICES OR ANY SERVICES CONTENT, OR OTHER
          INTANGIBLE LOSSES ARISING OUT OF OR RELATED TO YOUR USE OF THE SERVICES. THIS IS TRUE
          EVEN IF MAION OR RELATED PERSONS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES
          OR LOSSES.
        </span>

        <span>
          IN NO EVENT SHALL MAION OR IT'S RELATED PERSONS' TOTAL LIABILITY TO YOU FOR ALL
          DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT
          LIMITED TO, NEGLIGENCE), OR OTHERWISE) EXCEED IN THE AGGREGATE, THE GREATER OF (I) ONE
          HUNDRED DOLLARS ($100), OR (II) THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE
          SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THE CLAIM AT ISSUE.
        </span>

        <span>
          Some jurisdictions do not allow the exclusion of certain warranties or the limitation
          or exclusion of liability for damages such as in this section. Accordingly, some of
          these limitations may not apply to you. If you are a New Jersey resident, or a resident
          of another U.S. state that permits the exclusion of these warranties and liabilities,
          then the limitations in this section specifically do apply to you.
        </span>

        <p>14. Indemnification</p>
        <span>
          You agree to indemnify, defend, and hold harmless Maion, its affiliates, its affiliated
          health care practices, its subsidiaries, and all of their directors, officers,
          employees, contractors, licensors, suppliers, representatives, proprietors, partners,
          shareholders, principals, agents,successors, assigns, accountants, and attorneys
          harmless from and against any and all third-party suits, actions, claims, proceedings,
          damages, settlements, judgments, injuries, liabilities, obligations, losses, risks,
          costs, and expenses (including, without limitation, reasonable attorneys' fees,
          litigation expenses, and accounting fees), relating to or arising from, or alleged to
          arise from, (i) your use of the materials or features available on the Services in an
          unauthorised manner or your use of the Services, (ii) your fraud, violation of law,
          negligence, or willful misconduct, or (iii) any breach by you of these Terms of Use.
        </span>

        <p>15. Changes to the Terms of Use and the Services</p>
        <span>
          The Services are continually under development, and Maion reserves the right to review
          or remove any part of these Terms of Use in its sole discretion at any time and without
          prior notice to you. You should check the Terms of Use from time to time when you use
          the Services to determine if any changes have been made. Any changes to these Terms of
          Use are effective upon posting to the Services, unless applicable law requires us to
          provide additional notice or take other actions before such changes can become
          effective. If you disagree with these Terms of Use, your sole and exclusive remedy is
          to discontinue your use of the Services. Your continued use after a change has been
          posted constitutes your acceptance of the changes.
        </span>

        <p>16. Governing Law; Dispute Resolution; Arbitration; Venue; Severability</p>
        <span>
          IMPORTANT NOTE: PLEASE READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES YOU AND MAION TO
          RESOLVE ALL DISPUTES BETWEEN US THROUGH BINDING INDIVIDUAL ARBITRATION AND LIMITS THE
          MANNER IN WHICH YOU CAN SEEK RELIEF FROM MAION. YOU UNDERSTAND AND AGREE THAT, BY
          ENTERING INTO THESE TERMS OF USE, YOU AND MAION ARE EACH WAIVING THE RIGHT TO A TRIAL
          BY JURY AND THE RIGHT TO PARTICIPATE IN A CLASS ACTION.
        </span>

        <span>
          Governing Law. These Terms of Use and your use of the Services shall be governed by the
          laws of the State of Delaware, without giving effect to the principles of conflict of
          laws. Subject to the requirement to arbitrate set forth in this Section 16, exclusive
          jurisdiction for all disputes that do not require arbitration will be the state and
          federal courts located in New York, New York, and you consent to the jurisdiction of
          those courts.
        </span>

        <span>
          Binding Arbitration, Generally. Except as described in the "Exceptions" section below,
          you and Maion agree that every dispute arising in connection with these Terms of Use,
          the Services, or communications from us will be resolved through binding arbitration.
          Arbitration uses a neutral arbitrator instead of a judge or jury, is less formal than a
          court proceeding, may allow for more limited discovery than in court, and is subject to
          very limited review by courts. This agreement to arbitrate disputes includes all claims
          whether based in contract, tort, statute, regulation, ordinance, fraud,
          misrepresentation, common law, constitutional provision, respondeat superior, agency or
          any other legal or equitable theory, regardless of whether a claim arises after the
          termination of these Terms of Use, and regardless of whether a claim arises before or
          after the effective date of these Terms of Use. Any dispute relating to the
          interpretation, applicability, or enforceability of this binding arbitration agreement
          will be resolved by the arbitrator.
        </span>

        <span>
          Exceptions. Although we are agreeing to arbitrate most disputes between us, nothing in
          these Terms of Use will be deemed to waive, preclude, or otherwise limit the right of
          either party to: (a) bring an individual action in small claims court; (b) file suit in
          a court of law to address an intellectual property infringement claim; or (c) seek
          injunctive relief in a court of law in a state or federal court in New York, New York.
        </span>

        <span>
          Arbitrator. This arbitration agreement, and any arbitration between us, is subject to
          the Federal Arbitration Act and will be administered by the American Arbitration
          Association ("AAA") under its Consumer Arbitration Rules (collectively, "AAA Rules") as
          modified by these Terms of Use. The AAA Rules and filing forms are available online at
          www.adr.org, by calling the AAA at +1-800-778-7879, or by contacting Maion.
        </span>

        <span>
          Commencing Arbitration. Before initiating arbitration, a party must first send a
          written notice of the dispute to the other party by certified U.S. Mail or by Federal
          Express. The Notice of Arbitration must: (a) identify the name or account number of the
          party making the claim; (b) describe the nature and basis of the claim or dispute; and
          (c) set forth the specific relief sought ("Demand"). The parties will make good faith
          efforts to resolve the claim directly, but if the parties do not reach an agreement to
          do so within 30 days after the Notice of Arbitration is received, you or Maion may
          commence an arbitration proceeding. If the arbitrator finds that either the substance
          of the claim or the relief sought in the Demand is frivolous or brought for an improper
          purpose (as measured by the standards set forth in Federal Rule of Civil Procedure
          11(b)), then the payment of all fees will be governed by the AAA Rules and Maion may
          seek reimbursement for any fees paid to AAA.
        </span>

        <span>
          Arbitration Proceedings. Any arbitration hearing will take place in the county and
          state of your billing address unless we agree otherwise or, if the claim is for US
          $10,000 or less (and does not seek injunctive relief), you may choose whether the
          arbitration will be conducted: (a) solely on the basis of documents submitted to the
          arbitrator; (b) through a telephonic or video hearing; or (c) by an in-person hearing
          as established by the AAA Rules in the county (or parish) of your billing address.
          During the arbitration, the amount of any settlement offer made by you or Maion must
          not be disclosed to the arbitrator until after the arbitrator makes a final decision
          and award, if any. Regardless of the manner in which the arbitration is conducted, the
          arbitrator must issue a reasoned written decision sufficient to explain the essential
          findings and conclusions on which the decision and award, if any, are based.
        </span>

        <span>
          Arbitration Relief. Except as provided in the "No Class Actions" section below, the
          arbitrator can award any relief that would be available if the claims had been brought
          in a court of competent jurisdiction. The arbitrator's award shall be final and binding
          on all parties. Judgment on the award may be entered in any court having jurisdiction.
        </span>

        <span>
          No Class Actions. YOU AND MAION AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY
          IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
          PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and Maion agree
          otherwise in writing, the arbitrator may not consolidate more than one person's claims,
          and may not otherwise preside over any form of a representative or class proceeding.
        </span>

        <span>
          Limited Time to File Claims. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IF YOU
          OR RO WANTS TO ASSERT A DISPUTE AGAINST THE OTHER THAT IS SUBJECT TO ARBITRATION
          PURSUANT TO THESE TERMS OF USE, THEN YOU OR RO MUST COMMENCE SUCH DISPUTE BY DELIVERY
          OF A NOTICE OF ARBITRATION WITHIN ONE (1) YEAR AFTER THE DISPUTE ARISES -- OR IT WILL
          BE FOREVER BARRED.
        </span>

        <span>
          Severability. All parts of these Terms of Use apply to the maximum extent permitted by
          law. Maion and you both agree that if we cannot enforce a part of this contract as
          written, then that part will be replaced with terms that most closely match the intent
          of the part we cannot enforce, to the extent permitted by law. The invalidity of part
          of these Terms of Use will not affect the validity and enforceability of the remaining
          provisions. The section headings are for convenience only and do not have any force or
          effect.
        </span>

        <p>17. Miscellaneous Terms</p>

        <span>
          No waiver. No waiver by Maion of any term or condition set forth in these Terms of Use
          shall be deemed a further or continuing waiver of such term or condition or a waiver of
          any other term or condition, and any failure by Maion to assert a right or provision
          under these Terms of Use shall not constitute a waiver of such right or provision.
        </span>

        <span>
          No agency relationship. Neither these Terms of Use, nor any Services Content, materials
          or features of the Services create any partnership, joint venture, employment, or other
          agency relationship between us and you. You may not enter into any contract on our
          behalf or bind us in any way.
        </span>

        <span>
          Remedies. You agree that any violation, or threatened violation, by you of these Terms
          of Use constitutes an unlawful and unfair business practice that may cause us
          irreparable and unquantifiable harm. You also agree that monetary damages would be
          inadequate for such harm and consent to our obtaining any injunctive or equitable
          relief that we deem necessary or appropriate. These remedies are in addition to any
          other remedies we may have at law or in equity.
        </span>

        <span>
          Assignment. You may not assign any of your rights under these Terms of Use, and any
          such attempt will be null and void. MAion and its affiliates may, in their individual
          discretion, transfer, without further consent or notification, all contractual rights
          and obligations pursuant to these Terms of Use if some or all of the business of Maion
          is transferred to another entity by way of merger, sale of its assets, or otherwise.
        </span>

        <span>
          Notice for California Users. If you are a California resident, you hereby waive
          California Civil Code §1542, which says: "A general release does not extend to claims
          which the creditor does not know or suspect to exist in his favour at the time of
          executing the release, which if known by him must have materially affected his
          settlement with the debtor." This release includes the criminal acts of others.
        </span>

        <span>
          Headings. The heading references herein are for convenience purposes only, and shall
          not be deemed to limit or affect any of the provisions hereof.
        </span>

        <span>
          Entire Agreement. This is the entire agreement between you and Maion relating to the
          subject matter herein and supersedes all previous communications, representations,
          understandings and agreements, either oral or written, between the parties with respect
          to said subject matter, excluding any other agreements that you may have entered into
          with Maion.
        </span>

        <p>18. Contact Us</p>

        <span>If you have any questions about these Terms of Use, please contact us at:</span>

        <span>Maion Inc. 701 Tillery Street, Unit 12, 1803 Austin, TX 78702 legal@maion.ai</span>

        <p>19. Notice to Patients - Open Payments Database</p>

        <span>
          For informational purposes only, a link to the federal Centers for Medicare and
          Medicaid Services (CMS) Open Payments web page is provided here. The federal Physician
          Payments Sunshine Act requires that detailed information about payment and other
          payments of value worth over ten dollars ($10) from manufacturers of drugs, medical
          devices, and biologics to physicians and teaching hospital be made available to the
          public. You may search this federal database for payments made to physicians and
          teaching hospitals by visiting this website: https://openpaymentsdata.cms.gov/
        </span>
      </div>
    </div>
  )
}
