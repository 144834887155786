export const QUESTION_TYPES = {
  QUESTIONNAIRE: 'INITIAL',
  PRESCRIPTION: 'FOLLOWUP',
}

export const ORDER_STATUSES = {
  PENDING: 'PENDING',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PENDING_QUESTIONNAIRE_UPDATE: 'PENDING_QUESTIONNAIRE_UPDATE',
  PRESCRIPTION_REQUESTED: 'PRESCRIPTION_REQUESTED',
  PRESCRIPTION_ISSUED: 'PRESCRIPTION_ISSUED',
  PAYMENT_ACTION_REQUIRED: 'PAYMENT_ACTION_REQUIRED',
  IN_DELIVERY: 'IN_DELIVERY',
  PROCESSING: 'PROCESSING',
  PRESCRIPTION_DECLINED: 'PRESCRIPTION_DECLINED',
  ERROR: 'ERROR',
  CANCELLED: 'CANCELLED',
  COMPLETE: 'COMPLETE',
  SHIPPED: 'SHIPPED',
}

export const USER_METRIC_METRIC_TYPES = {
  WEIGHT: 'WEIGHT',
  PHOTO: 'PHOTO',
  FEELING: 'FEELING',
  SIDE: 'SIDE',
  MOOD: 'MOOD',
}

export const USER_METRIC_DIMENSIONS = {
  GRAM: 'GRAM',
  CENTIMETER: 'CENTIMETER',
  PERCENTAGE: 'PERCENTAGE',
  PHOTO: 'PHOTO',
  TEXT: 'TEXT',
  ENUM: 'ENUM',
}

export const USER_METRIC_DIMENSIONS_MAP = {
  WEIGHT: USER_METRIC_DIMENSIONS.GRAM,
  PHOTO: USER_METRIC_DIMENSIONS.PHOTO,
  FEELING: USER_METRIC_DIMENSIONS.ENUM,
  SIDE: USER_METRIC_DIMENSIONS.TEXT,
  MOOD: USER_METRIC_DIMENSIONS.ENUM,
}

export const KG_SIZE = {
  label: 'Kg',
  multiplier: 1,
}
export const LB_SIZE = {
  label: 'Lb',
  multiplier: 2.20462,
}

export const SIZE_UNITS = [KG_SIZE, LB_SIZE]

export const LENGTH_UNITS = [
  {
    label: 'Cm',
    multiplier: 1,
  },
  {
    label: 'In',
    multiplier: 0.3937,
  },
]

export const CALCULATED_BMI = 'Calculated BMI'
