import { environment } from '../../environments'

export const initializeGa = () => {
  if (!environment.GA_ID) return
  const script = document.createElement('script')
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GA_ID}`
  document.body.appendChild(script)

  window.dataLayer = window.dataLayer || []
  window.gtag = function gtag() {
    window.dataLayer.push(arguments)
  }

  window.gtag('js', new Date())

  window.gtag('config', environment.GA_ID)
}

/**
 * @param {string} name
 * @param {Record<string, unknown>} params
 * **/
export const sendEvent = (name, params) => {
  if (!environment.GA_ID) return
  window.gtag('event', name, params)
}

export const initializeGTM = ({ gtmId, dataLayer, dataLayerName = 'dataLayer' }) => {
  if (!gtmId) {
    console.warn('GTM Id is required')
    return
  }

  // Create and insert noscript iframe
  const noscript = document.createElement('noscript')
  noscript.innerHTML = `
    <iframe src="${environment.GOOGLE_TM_URL_PREVIEW}/ns.html?id=GTM-${gtmId}"
    height="0" width="0" style="display: none; visibility: hidden"></iframe>`
  document.body.insertBefore(noscript, document.body.childNodes[0])

  // Create and insert GTM script
  const script = document.createElement('script')
  script.innerHTML = `
    <!-- Google Tag Manager -->
    !function(){"use strict";function l(e){for(var t=e,r=0,n=document.cookie.split(";");r<n.length;r++){var o=n[r].split("=");if(o[0].trim()===t)return o[1]}}function s(e){return localStorage.getItem(e)}function u(e){return window[e]}function d(e,t){e=document.querySelector(e);return t?null==e?void 0:e.getAttribute(t):null==e?void 0:e.textContent}var e=window,t=document,r="script",n="dataLayer",o="${gtmId}",a="${environment.GOOGLE_TM_URL_CONTAINER}",i="${environment.GOOGLE_TM_URL_PREVIEW}",c="rhnjoxuv",E="localStorage",I="USER_ID",v="",g=!1;try{var g=!!E&&(m=navigator.userAgent,!!(m=new RegExp("Version/([0-9._]+)(.*Mobile)?.*Safari.*").exec(m)))&&16.4<=parseFloat(m[1]),A="stapeUserId"===E,f=g&&!A?function(e,t,r){void 0===t&&(t="");var n={cookie:l,localStorage:s,jsVariable:u,cssSelector:d},t=Array.isArray(t)?t:[t];if(e&&n[e])for(var o=n[e],a=0,i=t;a<i.length;a++){var c=i[a],c=r?o(c,r):o(c);if(c)return c}else console.warn("invalid uid source",e)}(E,I,v):void 0;g=g&&(!!f||A)}catch(e){console.error(e)}var m=e,E=(m[n]=m[n]||[],m[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"}),t.getElementsByTagName(r)[0]),I="dataLayer"===n?"":"&l="+n,v=f?"&bi="+encodeURIComponent(f):"",A=t.createElement(r),e=g?"kp"+c:c,n=!g&&i?i:a;A.async=!0,A.src=n+"/"+e+".js?st="+o+I+v+"",null!=(f=E.parentNode)&&f.insertBefore(A,E)}();
    <!-- End Google Tag Manager -->
    `
  document.head.insertBefore(script, document.head.childNodes[0])

  // Handle dataLayer
  if (dataLayer) {
    const dataLayerScript = document.createElement('script')
    dataLayerScript.innerHTML = `
      window.${dataLayerName} = window.${dataLayerName} || [];
      window.${dataLayerName}.push(${JSON.stringify(dataLayer)});`
    document.head.insertBefore(dataLayerScript, document.head.childNodes[0])
  }
}
