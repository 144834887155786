import React from 'react'
import { Label } from 'components/layout/label'

import PaymentLogo from 'shared/icons/misc/creditCardError.svg'
import InfoIcon from 'shared/icons/misc/info.svg'
import styles from './styles.module.css'
import { generatePath, Link } from 'react-router-dom'
import { APP_PATHS } from '../../../../paths'

export const PaymentFailed = ({ message, isPhoneNumberRequired = false }) => {
  const token = localStorage.getItem('REGISTRATION_TOKEN')

  return (
    <div className={styles.wrapper}>
      <Label>Confirmation</Label>
      <div className={styles.wrapper_payment}>
        <div className={styles.wrapper_icon_gray}>
          <img color="white" src={PaymentLogo} alt="Payment error" />
        </div>
        <p className={styles.payment_label}>Order Failed</p>
        <p className={styles.payment_body}>Your payment occurred an error.</p>
        <p className={styles.payment_body}>{message}</p>

        <hr />
        <img color="white" src={InfoIcon} alt="Info" />
        <p className={styles.payment_sublabel}> Do not worry 😉</p>
        {isPhoneNumberRequired ? (
          <p className={styles.payment_body}>
            Sometimes it happens. Please{' '}
            <Link to={generatePath(APP_PATHS.setPhoneNumber, { token })} className={styles.link}>
              finish your registration
            </Link>{' '}
            and check your payment method, <br />
            try to pay the order or{' '}
            <a className={styles.link} href="mailto:help@maion.ai">
              contact us.
            </a>
          </p>
        ) : (
          <p className={styles.payment_body}>
            Sometimes it happens. Please go to{' '}
            <Link to={APP_PATHS.myProfileBillingPage} className={styles.link}>
              Your billing page
            </Link>{' '}
            and check your payment method, <br />
            try to pay the order or{' '}
            <a className={styles.link} href="mailto:help@maion.ai">
              contact us.
            </a>
          </p>
        )}
      </div>
    </div>
  )
}
