import React, { useState } from 'react'
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react'
import { LB_SIZE, USER_METRIC_METRIC_TYPES } from 'shared/constants/common'
import { FileUpload } from 'components/elements/imageUpload'

const MetricModalContent = ({ type, value, handleChange }) => {
  const onChange = (e) => handleChange(e.target.value)
  if (type === USER_METRIC_METRIC_TYPES.SIDE) {
    return <Input placeholder="Any side effect?" value={value} onChange={onChange} />
  }
  if (type === USER_METRIC_METRIC_TYPES.WEIGHT) {
    return (
      <Input placeholder="What's your weight?" type="number" value={value} onChange={onChange} />
    )
  }
  if (type === USER_METRIC_METRIC_TYPES.PHOTO) {
    return <FileUpload image={value} onChange={handleChange} />
  }
  if (type === USER_METRIC_METRIC_TYPES.MOOD) {
    return (
      <Select placeholder="Select your mood" onChange={onChange}>
        <option value="happy">Happy</option>
        <option value="sad">Sad</option>
        <option value="excited">Excited</option>
        <option value="angry">Angry</option>
        <option value="relaxed">Relaxed</option>
      </Select>
    )
  }
  if (type === USER_METRIC_METRIC_TYPES.FEELING) {
    return (
      <Select placeholder="How do you feel?" onChange={onChange}>
        <option value="happy">Happy</option>
        <option value="sad">Sad</option>
        <option value="excited">Excited</option>
        <option value="angry">Angry</option>
        <option value="relaxed">Relaxed</option>
      </Select>
    )
  }
  return <Input placeholder="Enter value" value={value} onChange={onChange} />
}

export const MetricModal = ({ type, isOpen, onClose, handleCommit }) => {
  const [metricValue, setMetricValue] = useState(null)
  const handleClose = () => {
    onClose()
    setMetricValue(null)
  }
  const onCommit = () => {
    if (type === USER_METRIC_METRIC_TYPES.WEIGHT) {
      handleCommit(type, `${+metricValue / LB_SIZE.multiplier}`)
    } else {
      handleCommit(type, metricValue)
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>How do you feel</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <MetricModalContent type={type} value={metricValue} handleChange={setMetricValue} />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              onCommit()
              handleClose()
            }}
          >
            Save
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const InjectionModal = ({ isOpen, onClose, handleCommit }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Take your medicine</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        <Text> </Text>
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme="blue"
          mr={3}
          onClick={() => {
            handleCommit()
            onClose()
          }}
        >
          Take medicine
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
)
