import React, { useCallback, useEffect } from 'react'
import styles from './profile.module.css'
import { Label } from '../../components/layout/label'
import { LeftMenu } from './components/leftMenu'
import { useReadMyUserProfile } from 'shared/queries/userProfile'
import {
  useUserContextState,
  useUserContextStateDispatch,
} from 'shared/contexts/user-context-provider'
import { isEmpty } from 'lodash'
import { APP_PATHS } from '../../paths'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '../../shared/hooks/useMediaQuery'

export const Layout = ({ children }) => {
  const {
    data: profile,
    isLoading: isProfileLoading,
    refetch: refetchProfile,
  } = useReadMyUserProfile()
  const { isUserLoading, user } = useUserContextState()
  const navigate = useNavigate()
  const { onLogout } = useUserContextStateDispatch()
  const handleLogout = useCallback(async () => {
    onLogout()
    navigate(APP_PATHS.home)
  }, [])
  useEffect(() => {
    if ((!isUserLoading && isEmpty(user)) || (!isProfileLoading && isEmpty(profile))) {
      navigate(APP_PATHS.registration)
    }
  }, [isUserLoading, user, profile, isProfileLoading])
  const isMobile = useMediaQuery('(max-width: 800px)')

  if (isUserLoading || isProfileLoading) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      {(!isMobile || window.location.pathname === APP_PATHS.myProfile) && (
        <Label>My Maion</Label>
      )}
      <div
        className={`${styles.layout} ${
          !Array.isArray(children) || children.length === 1 ? styles.layout_two : ''
        }`}
      >
        <LeftMenu profile={profile} onLogout={handleLogout} refetchProfile={refetchProfile} />
        {typeof children === 'function' ? children({ profile, refetchProfile }) : children}
      </div>
    </div>
  )
}
