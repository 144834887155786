import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { isEmpty, trimStart } from 'lodash'
import * as Yup from 'yup'
import 'yup-phone'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  VStack,
  useMediaQuery,
  Flex,
  useToast,
} from '@chakra-ui/react'

import { APP_PATHS } from 'paths'
import { Label } from 'components/layout/label'
import { useSetUserPhoneNumberMutation } from 'shared/mutations/user'
import { FormikInput } from 'components/elements/input'

export const SetPhoneNumberPage = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { token } = useParams()
  const [fieldErrors, setFieldErrors] = useState({})
  const [isMobile] = useMediaQuery('(max-width: 800px)')

  const { mutate: setUserPhoneNumber } = useSetUserPhoneNumberMutation({
    onSuccess: (data) => {
      navigate(
        generatePath(APP_PATHS.verifyPhoneNumber, {
          token: data.token,
          phoneNumber: data.phoneNumber,
        })
      )
    },
    onError: ({ error }) => {
      const { code, fields, context } = error

      if (code === 'COM-9') {
        let validationErrors = {}
        fields.forEach(({ dataPath, message }) => {
          validationErrors[trimStart(dataPath, '.')] = message
        })
        setFieldErrors(validationErrors)
      } else if (code === 'MNC-1-1') {
        let errors = {}
        if (context?.phoneNumber) {
          errors.phoneNumber = 'User with such phone number already exists.'
        }
        setFieldErrors(errors)
      }
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
  })

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .phone('US', true, 'Invalid Phone Number')
        .required('Phone Number is required'),
    }),
    onSubmit: (values) => {
      if (values.phoneNumber) {
        const cleanedNumber = values.phoneNumber.replace(/\s+/g, '')
        values.phoneNumber = cleanedNumber.startsWith('+1')
          ? cleanedNumber
          : `+1${cleanedNumber}`
      }
      setUserPhoneNumber({
        ...values,
        token,
      })
    },
  })

  return (
    <Box
      as="form"
      onSubmit={formik.handleSubmit}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      gap="30px"
      padding="7% 8% 60px 8%"
    >
      <Label>Phone Number Verification</Label>

      <VStack
        width={isMobile ? '100%' : '400px'}
        alignItems="flex-start"
        spacing={8}
        textAlign="start"
        fontSize="14px"
        lineHeight={'normal'}
        fontWeight={'400'}
      >
        <Text>
          Enter your phone number to receive a verification code. This helps us keep your account
          secure and your information safe:
        </Text>
        <FormControl>
          <FormLabel fontWeight="600" fontSize="17px" lineHeight={'22px'} color={'#4F5B76'}>
            Cell Number
          </FormLabel>
          <FormikInput
            name="phoneNumber"
            required
            type="tel"
            formik={formik}
            errors={fieldErrors}
            placeholder="123-456-7890"
            color={'white'}
          />
          <Text fontSize="12px" mt={2}>
            <strong>Example: 123-456-7890</strong> (please don’t include ‘-’ or ‘+1’)
          </Text>
        </FormControl>
        <Flex justifyContent="center" width={isMobile ? '100%' : '400px'} mt={4} w={'100%'}>
          <Button
            type="submit"
            colorScheme="pink"
            bg={'var(--accent)'}
            color="white"
            fontSize={'16px'}
            size="lg"
            width={isMobile ? '100%' : '100px'}
            h={'36px'}
            isDisabled={!isEmpty(formik.errors) || !formik.dirty}
          >
            Send
          </Button>
        </Flex>
      </VStack>
      <Box
        mt={12}
        textAlign="start"
        width={isMobile ? '100%' : '400px'}
        fontSize="12px"
        lineHeight={'normal'}
      >
        <Text fontWeight="bold" fontStyle={'italic'}>
          Why do we need to verify your phone number?
        </Text>
        <Text>
          Our qualified physicians will use your phone number as the primary contact mechanism
          for engaging with you.
        </Text>
      </Box>
    </Box>
  )
}

SetPhoneNumberPage.propTypes = {
  isFromScheduler: PropTypes.bool,
}
