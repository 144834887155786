import React from 'react'

import styles from './styles.module.css'
import { Button } from 'shared/components'

export const NewsLettersModal = ({ onConfirm }) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>Congratulations!</p>
      <p className={styles.disclaimer}>
        You have subscribed to Maion news, offers, and updates.
      </p>
      <Button onClick={onConfirm}>Ok</Button>
    </div>
  )
}
