import React from 'react'
export const CalendarSvg = ({ ...args }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      {...args}
    >
      <path
        d="M21.5558 1.88818H1V21.9414H21.5558V1.88818Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="100"
      />
      <path
        d="M1.18945 7.35205H21.3706"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="100"
      />
      <path
        d="M5.64648 0V4.11934"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="100"
      />
      <path
        d="M16.9131 0V4.11934"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="100"
      />
    </svg>
  )
}
