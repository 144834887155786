import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Label } from 'components/layout/label'
import { APP_PATHS } from 'paths'

import styles from './styles.module.css'

export const UploadIdComplete = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.wrapper}>
      <Label>Confirmation</Label>
      <div className={styles.inner_wrapper}>
        <p className={styles.label}>Upload Id Successful!</p>
        <p className={styles.body}>
          Thank you. Your ID has now been shared with our prescribing partners
        </p>
        <div className={styles.buttons}>
          <button className={styles.continue} onClick={() => navigate(APP_PATHS.home)}>
            Complete
          </button>
        </div>
      </div>
    </div>
  )
}
