import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React from 'react'

export const NotificationPopup = ({ title, description, isOpen, onClose }) => {
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton data-testid="close-modal" />
        <ModalBody>
          <Text color="black" variant="h6">
            {description}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
