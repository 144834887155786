import React from 'react'
import styles from './styles.module.css'
export const Label = ({ children, className = '', subTitle }) => {
  return (
    <div className={styles.wrapper}>
      <h1 className={`${styles.label} ${className}`}>{children}</h1>
      {!!subTitle && <p className={styles.subtitle}>{subTitle}</p>}
    </div>
  )
}
