import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { uniqByKeepFirst } from 'shared/utils/uniq'
import { useCallback, useMemo } from 'react'

export const useProfileTile = () => {
  const { items, addItem, removeItem } = useProfileTileStore()
  // const allItems = ['chart', 'nextMedication', 'mood']
  const allItems = ['chart', 'nextMedication']

  const addNew = useCallback(
    (type) => {
      if (!allItems.includes(type)) return
      addItem(type)
    },
    [addItem]
  )

  const removeTile = useCallback((type) => removeItem(type), [removeItem])

  return useMemo(
    () => ({
      items,
      hasMore: items.length < 4,
      addNew,
      allItems,
      availableItems: allItems.filter((i) => !items.includes(i)),
      removeTile,
    }),
    [items, addNew, allItems, removeTile]
  )
}

const useProfileTileStore = create(
  persist(
    (set, get) => ({
      items: ['chart', 'nextMedication'],
      addItem: (item) =>
        set(() => {
          const items = uniqByKeepFirst([...get().items, item], (it) => it)
          return { items: items }
        }),
      removeItem: (type) => set(() => ({ items: get().items.filter((i) => i !== type) })),
      clear: () => set({ items: [] }),
    }),
    {
      name: 'profile-tile',
      version: 1,
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)
