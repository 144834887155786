import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Questionnaires } from 'features/payment/components/questionnaries'
import { QUESTION_TYPES } from 'shared/constants/common'
import { useReadMyOrder } from 'shared/queries/order'
import { useRenewPrescriptionMutation } from 'shared/mutations/order'

import { isEmpty } from 'lodash'
import { ReNewPrescriptionFailed } from '../../payment/components/complete/renewPrescriptionFailed'
import { ReNewPrescriptionComplete } from '../../payment/components/complete/renewPrescriptionComplete'
import { useUserContextState } from 'shared/contexts/user-context-provider'
import { APP_PATHS } from 'paths'
import { CircularProgress, Flex } from '@chakra-ui/react'

export const RenewPrescriptionPage = () => {
  const { isUserLoading, user } = useUserContextState()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const orderId = params.get('orderId')
  const { data: order, isLoading } = useReadMyOrder({ id: orderId })
  const [orderStatus, setOrderStatus] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!isUserLoading && isEmpty(user)) {
      navigate(APP_PATHS.registration)
    }
  }, [isUserLoading, user])

  const { mutate } = useRenewPrescriptionMutation(orderId, {
    onSuccess: () => {
      setOrderStatus('completed')
    },
    onError: (error) => {
      setOrderStatus('error')
      setError(error)
    },
  })
  const handleComplete = (questionnairesResponses) => {
    setOrderStatus('loading')
    mutate({
      questionnairesResponses,
    })
  }
  if (isUserLoading || !user) {
    return null
  }
  if (orderStatus === 'completed') {
    return <ReNewPrescriptionComplete />
  }
  if ((orderStatus === 'error' && !!error) || (!isLoading && !order)) {
    return <ReNewPrescriptionFailed message={error?.message || 'Could not load data'} />
  }
  if (isLoading || !order || orderStatus === 'loading') {
    return (
      <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} py="9%" px="8%">
        <CircularProgress isIndeterminate color="var(--accent)" />
      </Flex>
    )
  }
  const productId = order.items[0].productId
  const packOfferId = order.packOfferIds[0]

  return (
    <Questionnaires
      productId={packOfferId ?? productId}
      orderId={orderId}
      type={QUESTION_TYPES.PRESCRIPTION}
      handleComplete={handleComplete}
    />
  )
}
