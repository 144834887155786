import { Box, Text } from '@chakra-ui/react'
import React from 'react'

export const StockBadge = ({ status }) => {
  if (status === 'NOT_AVAILABLE')
    return (
      <Box
        bgColor="grey"
        alignSelf="start"
        p="4px 16px"
        rounded={8}
        width={'fit-content!important'}
      >
        <Text color="white" fontSize="18px" fontWeight="500">
          {' '}
          Not available{' '}
        </Text>
      </Box>
    )
  if (status === 'PENDING_DELIVERY')
    return (
      <Box
        bgColor="#89216C"
        alignSelf="start"
        p="4px 16px"
        rounded={8}
        width={'fit-content!important'}
      >
        <Text color="white" fontSize="18px" fontWeight="500">
          {' '}
          Pending delivery{' '}
        </Text>
      </Box>
    )
  if (status === 'IN_STOCK')
    return (
      <Box
        bgColor="white"
        alignSelf="start"
        p="4px 16px"
        rounded={8}
        width={'fit-content!important'}
      >
        <Text fontSize="18px" fontWeight="500">
          {' '}
          In stock{' '}
        </Text>
      </Box>
    )
  return <></>
}
