import JourneySvg from 'shared/icons/misc/journey.svg'
import HandsSvg from 'shared/icons/misc/hands.svg'
import MoneySvg from 'shared/icons/misc/money.svg'
import BlockSvg from 'shared/icons/misc/block.svg'
import UserSvg from 'shared/icons/misc/user.svg'

import JourneyActiveSvg from 'shared/icons/misc/journeyActive.svg'
import HandsActiveSvg from 'shared/icons/misc/handsActive.svg'
import MoneyActiveSvg from 'shared/icons/misc/moneyActive.svg'
import BlockActiveSvg from 'shared/icons/misc/blockActive.svg'
import UserActiveSvg from 'shared/icons/misc/userActive.svg'

import HomeSvg from 'shared/icons/misc/homeOutline.svg'
import SyringeSvg from 'shared/icons/misc/syringe.svg'
import SearchSvg from 'shared/icons/misc/search.svg'
import BookSvg from 'shared/icons/misc/bookOutline.svg'
import HeartSvg from 'shared/icons/misc/heart.svg'
import SendSvg from 'shared/icons/misc/send.svg'

import HomeActiveSvg from 'shared/icons/misc/homeBig.svg'
import SyringeActiveSvg from 'shared/icons/misc/syringeActive.svg'
import SearchActiveSvg from 'shared/icons/misc/searchActive.svg'
import BookActiveSvg from 'shared/icons/misc/book.svg'
import HeartActiveSvg from 'shared/icons/misc/heartActive.svg'
import SendActiveSvg from 'shared/icons/misc/sendActive.svg'

import { APP_PATHS } from '../../../paths'

export const MenuLinksLoggedIn = [
  {
    icon: HomeSvg,
    iconActive: HomeActiveSvg,
    text: 'Home',
    link: APP_PATHS.home,
  },
  {
    icon: SyringeSvg,
    iconActive: SyringeActiveSvg,
    text: 'Treatments',
    link: APP_PATHS.treatmentPlans,
  },
  {
    icon: JourneySvg,
    iconActive: JourneyActiveSvg,
    text: 'My Journey',
    link: APP_PATHS.myProfile,
  },
  {
    icon: HandsSvg,
    iconActive: HandsActiveSvg,
    text: 'Invite a Friend',
    link: APP_PATHS.myProfileReferralPage,
  },
  {
    icon: MoneySvg,
    iconActive: MoneyActiveSvg,
    text: 'Billing & Payment Details',
    link: APP_PATHS.myProfileBillingPage,
  },
  {
    icon: BlockSvg,
    iconActive: BlockActiveSvg,
    text: 'Returns & Cancellations',
    link: APP_PATHS.myProfileReturnsPage,
  },
  {
    icon: UserSvg,
    iconActive: UserActiveSvg,
    text: 'User Profile',
    link: APP_PATHS.myProfileUserPage,
  },
  {
    icon: SendSvg,
    iconActive: SendActiveSvg,
    text: 'Contact',
    link: APP_PATHS.contact,
  },
]
export const MenuLinksLoggedOut = [
  {
    icon: HomeSvg,
    iconActive: HomeActiveSvg,
    text: 'Home',
    link: APP_PATHS.home,
  },
  {
    icon: SyringeSvg,
    iconActive: SyringeActiveSvg,
    text: 'Treatments',
    link: APP_PATHS.treatmentPlans,
  },
  {
    icon: BookSvg,
    iconActive: BookActiveSvg,
    text: 'How it All Works',
    link: APP_PATHS.howitWorks,
  },
  {
    icon: SearchSvg,
    iconActive: SearchActiveSvg,
    text: 'FAQ',
    link: APP_PATHS.faq,
  },
  {
    icon: HeartSvg,
    iconActive: HeartActiveSvg,
    text: 'Why Maion',
    link: APP_PATHS.whyMaion,
  },
  {
    icon: SendSvg,
    iconActive: SendActiveSvg,
    text: 'Contact',
    link: APP_PATHS.contact,
  },
]
