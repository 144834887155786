// import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
// import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'
import { QueriesKeysEnum } from './queries-keys-enum'

export const useIndexServiceConfig = (params = {}, enabled = true) => {
  //   const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.serviceConfig, params],
    () => axios.get('/indexServiceConfigs', { params }),
    {
      retry: false,
      enabled,
      onError: () => {
        // toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
      },
    }
  )
}
