import React, { useCallback, useState } from 'react'
import { Slide } from 'react-slideshow-image'
import { Text, Image } from '@chakra-ui/react'

import styles from './styles.module.css'
import './styles.css'
import 'react-slideshow-image/dist/styles.css'

import Laptop from 'shared/images/laptop.png'
import Phone from 'shared/images/phone.png'
import Pencils from 'shared/images/penciles.png'
import CardShadow from './shadow.svg'
import { useMediaQuery } from '../../../shared/hooks/useMediaQuery'

const indicators = () => <div className="indicator" />

const defaultCards = [
  {
    label: 'STEP 1',
    labelSecond: 'MEDICAL QUESTIONAIRE',
    description:
      'Complete a simple medical history form so your provider can develop the best treatment plan for you. It’s fast, easy, and free.',
    image: Laptop,
  },
  {
    label: 'STEP 2',
    labelSecond: 'SPEAK TO A PHYSICIAN ONLINE',
    description:
      'Complete a simple medical history form so your provider can develop the best treatment plan for you. It’s fast, easy, and free.',
    image: Phone,
  },
  {
    label: 'STEP 3',
    labelSecond: 'GET YOUR MEDICATION',
    description:
      'Complete a simple medical history form so your provider can develop the best treatment plan for you. It’s fast, easy, and free.',
    image: Pencils,
  },
  {},
]

export const TreatmentsPlans = ({ stepCards = defaultCards }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const isMobile = useMediaQuery('(max-width: 800px)')

  const onStartChange = useCallback((from, to) => {
    setActiveIndex(to)
  }, [])
  return (
    <div className={styles.wrapper}>
      <Text fontSize={{ base: '38px', md: '62px' }} fontWeight="600">
        Weight Loss Made Easy
      </Text>
      <Text fontSize="23" fontWeight="400" color="#646363">
        Three Easy Steps
      </Text>
      {isMobile ? (
        <div className="slide-container" style={{ width: '100%' }}>
          <Slide
            prevArrow={<></>}
            nextArrow={<></>}
            indicators={indicators}
            pauseOnHover
            slidesToScroll={1}
            slidesToShow={1}
            onStartChange={onStartChange}
          >
            {stepCards.map((card, index) =>
              index === 3 ? (
                <div key={index} style={{ marginInline: '20px' }}>
                  <div className={styles.start_card}>
                    <label className={styles.label}>
                      $210 <span className={styles.small_label}>first month</span>
                    </label>
                    <div className={styles.divider} />
                    <div className={styles.description}>
                      The cost of your medication is included in your $210 per month membership
                      when you choose to receive a shipment every 60-days.
                      <br />
                      There are no hidden-fees and insurance is not required.
                    </div>
                    <div className={styles.button}>Start Today</div>
                  </div>
                  <img src={CardShadow} alt={'shadow'} className={styles.shadow} />
                </div>
              ) : (
                <div key={index} style={{ marginInline: '20px' }}>
                  <div className={styles.card}>
                    <label className={styles.label}>{card.label}</label>
                    <div className={styles.divider} />
                    <p className={styles.labelSecond}>{card.labelSecond}</p>
                    <p className={styles.description}>{card.description}</p>
                    <Image alt={'Image'} src={card.image} />
                  </div>
                  <img src={CardShadow} alt={'shadow'} className={styles.shadow} />
                </div>
              )
            )}
          </Slide>
          <div className="bottom_wrapper_doctors">
            <div className="bottom_indicators">
              <div
                className="bottom_indicator_item"
                style={{
                  width: `${(isMobile ? 200 : 400) / (stepCards.length + 1)}%`,
                  left: `${activeIndex * (100 / (stepCards.length + 1))}%`,
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.cards_wrapper}>
          {stepCards.map((card, index) =>
            index === 3 ? (
              <div key={index}>
                <div className={styles.start_card}>
                  <label className={styles.label}>
                    $210 <span className={styles.small_label}>first month</span>
                  </label>
                  <div className={styles.divider} />
                  <div className={styles.description}>
                    The cost of your medication is included in your $210 per month membership
                    when you choose to receive a shipment every 60-days.
                    <br />
                    There are no hidden-fees and insurance is not required.
                  </div>
                  <div className={styles.button}>Start Today</div>
                </div>
                <img src={CardShadow} alt={'shadow'} className={styles.shadow} />
              </div>
            ) : (
              <div key={index}>
                <div className={styles.card}>
                  <label className={styles.label}>{card.label}</label>
                  <div className={styles.divider} />
                  <p className={styles.labelSecond}>{card.labelSecond}</p>
                  <p className={styles.description}>{card.description}</p>
                  <Image
                    alt={'Image'}
                    src={card.image}
                    position="absolute"
                    bottom="-12px"
                    left="-12px"
                  />
                </div>
                <img src={CardShadow} alt={'shadow'} className={styles.shadow} />
              </div>
            )
          )}
        </div>
      )}
    </div>
  )
}
