import React, { useCallback, useEffect, useReducer } from 'react'
import { useQueryClient } from 'react-query'
import { jwtDecode } from 'jwt-decode'
import ReactGA from 'react-ga4'
import { useReadMyUser } from '../../queries/user'
import {
  useCreateSessionByAttemptTokenMutation,
  useCreateSessionByEmailMutation,
  useDeleteSessionMutation,
} from '../../mutations/user'
import { generatePath, useNavigate } from 'react-router-dom'
import { APP_PATHS } from '../../../paths'
import { QueriesKeysEnum } from '../../queries/queries-keys-enum'
import { useBasketStore } from 'shared/stores/basketStore'

const UserStateContext = React.createContext({
  user: {},
  isUserLoading: true,
})

const UserDispatchContext = React.createContext({
  updateUserContext: () => void 0,
  onLogout: () => new Promise(() => void 0),
  onLogin: () => new Promise(() => void 0),
  onLogin2FA: () => new Promise(() => void 0),
})

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload }
    case 'logout':
      return action.payload
    default: {
      return state
    }
  }
}

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { user: {}, isUserLoading: true })
  const { mutate: onLogin } = useCreateSessionByEmailMutation()
  const { mutate: onLogin2FA } = useCreateSessionByAttemptTokenMutation()
  const { mutate: onLogout } = useDeleteSessionMutation()
  const queryCache = useQueryClient()
  const { items } = useBasketStore()
  const navigate = useNavigate()
  const { data: user, isLoading: isUserLoading, refetch: refetchUser } = useReadMyUser()

  const parseLogIn = async (data, callbackOk, callback2FA) => {
    const {
      token,
      phoneNumber,
      isMFARequired,
      isProfileCompleteRequired,
      isPhoneNumberRequired,
      isPhoneNumberVerificationRequired,
    } = data
    const decodedToken = jwtDecode(token)

    if (isMFARequired && callback2FA) {
      callback2FA(token)
    } else if (items.length > 0) {
      if (
        isPhoneNumberRequired ||
        isPhoneNumberVerificationRequired ||
        isProfileCompleteRequired
      ) {
        localStorage.setItem('REGISTRATION_TOKEN', token)
      } else {
        localStorage.setItem('AUTH_TOKEN', token)
        await refetchUser()
        await queryCache.refetchQueries({
          queryKey: [QueriesKeysEnum.user, QueriesKeysEnum.userProfile],
          exact: true,
          type: 'active',
        })
      }
      localStorage.setItem('USER_ID', decodedToken.userId)
      localStorage.setItem('IS_USER_LOGIN', true)
      ReactGA.set({ user_id: decodedToken.userId, is_user_login: true })

      const productId = items?.[0]?.id
      const payOrder = generatePath(APP_PATHS.orderSubscription, {
        id: productId,
        isPhoneNumberRequired: !!isPhoneNumberRequired,
      })
      await navigate(payOrder)
    } else if (isPhoneNumberRequired) {
      localStorage.setItem('REGISTRATION_TOKEN', token)
      localStorage.setItem('USER_ID', decodedToken.userId)
      localStorage.setItem('IS_USER_LOGIN', true)

      const setPhoneNumberPath = generatePath(APP_PATHS.setPhoneNumber, {
        token,
      })
      await navigate(setPhoneNumberPath)
    } else if (isPhoneNumberVerificationRequired && phoneNumber) {
      localStorage.setItem('REGISTRATION_TOKEN', token)
      localStorage.setItem('USER_ID', decodedToken.userId)
      localStorage.setItem('IS_USER_LOGIN', true)
      const finishRegistrationPath = generatePath(APP_PATHS.verifyPhoneNumber, {
        token,
        phoneNumber,
      })
      await navigate(finishRegistrationPath)
    } else if (isProfileCompleteRequired) {
      localStorage.setItem('REGISTRATION_TOKEN', token)
      localStorage.setItem('USER_ID', decodedToken.userId)
      localStorage.setItem('IS_USER_LOGIN', true)
      const finishRegistrationPath = generatePath(APP_PATHS.completeRegistration, {
        token,
      })
      await navigate(finishRegistrationPath)
    } else {
      localStorage.removeItem('REGISTRATION_TOKEN')
      localStorage.setItem('AUTH_TOKEN', token)
      localStorage.setItem('USER_ID', decodedToken.userId)
      localStorage.setItem('IS_USER_LOGIN', true)
      ReactGA.set({ user_id: decodedToken.userId, is_user_login: true })
      await refetchUser()
      await queryCache.refetchQueries({
        queryKey: [QueriesKeysEnum.user, QueriesKeysEnum.userProfile],
        exact: true,
        type: 'active',
      })
      callbackOk()
    }
  }
  const handleLogIn = async (values, callbackOk, callback2FA, callbackError) => {
    return onLogin(values, {
      onSuccess: async (data) => {
        await parseLogIn(data, callbackOk, callback2FA)
      },
      onError: (response) => {
        callbackError(response)
      },
    })
  }

  const handleLogIn2FA = async (values, callbackOk, callbackError) => {
    return onLogin2FA(values, {
      onSuccess: async (data) => {
        const { token } = data
        const decodedToken = jwtDecode(token)
        localStorage.setItem('AUTH_TOKEN', token)
        localStorage.setItem('USER_ID', decodedToken.userId)
        localStorage.setItem('IS_USER_LOGIN', true)
        ReactGA.set({ user_id: decodedToken.userId, is_user_login: true })
        await refetchUser()
        await queryCache.refetchQueries()
        callbackOk()
      },
      onError: (response) => {
        callbackError(response)
      },
    })
  }

  const handleDispatch = useCallback((action) => dispatch(action), [])

  const handleLogout = useCallback(async () => {
    onLogout()
    localStorage.removeItem('AUTH_TOKEN')
    localStorage.setItem('IS_USER_LOGIN', false)
    ReactGA.set({ is_user_login: false })
    await queryCache.invalidateQueries()

    handleDispatch({ type: 'logout', payload: { user: {}, isUserLoading: false } })
  }, [handleDispatch])

  useEffect(() => {
    handleDispatch({
      type: 'update',
      payload: {
        user,
        isUserLoading,
      },
    })
  }, [user, isUserLoading])
  useEffect(() => {
    if (!localStorage.getItem('AUTH_TOKEN') || !localStorage.getItem('USER_ID')) {
      handleLogout()
    }
  }, [])
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider
        value={{
          updateUserContext: handleDispatch,
          onLogout: handleLogout,
          parseLogIn,
          onLogin: handleLogIn,
          onLogin2FA: handleLogIn2FA,
        }}
      >
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}
export const useUserContextState = () => {
  const context = React.useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserContextProvider')
  }
  return context
}
export const useUserContextStateDispatch = () => {
  const context = React.useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserContextProvider')
  }
  return context
}
