import React from 'react'
import { Layout } from '../layout'
import styles from '../profile.module.css'
import { Label } from '../../../components/layout/label'
import { CancellationTable } from '../components/cancellationTable/cancellationTable'
import { useIndexMySubscriptions } from '../../../shared/queries/subscription'
import { isEmpty } from 'lodash'
import { APP_PATHS } from '../../../paths'

export const ReturnsPage = () => {
  const {
    data: subscriptions = [],
    isLoading: isSubscriptionsLoading,
    refetch,
  } = useIndexMySubscriptions()
  if (isSubscriptionsLoading) {
    return null
  }
  return (
    <Layout>
      <section className={`${styles.page_wrapper} ${styles.returnsPage}`}>
        <Label className={styles.mb20}>Cancellations</Label>
        {isEmpty(subscriptions) ? (
          <div className={styles.text}>
            Don`t have any subscriptions yet? <a href={APP_PATHS.treatmentPlans}>Choose plan</a>
          </div>
        ) : (
          <CancellationTable subscriptions={subscriptions} refetchSubscriptions={refetch} />
        )}

        <Label className={styles.mt20}>Refunds policy</Label>
        <div className={styles.text}>
          Due to the nature of prescription products, we cannot issue refunds or accept returns
          for reuse or resale. Therefore, all sales are final.
          <br />
          However, if you believe there has been an error in the filling of your prescription,
          please <a href={'mailto:support@maion.ai'}>contact us</a> immediately.
        </div>
      </section>
    </Layout>
  )
}
