import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty, size } from 'lodash'
import { Form, Formik } from 'formik'
import { Button, useToast, VStack } from '@chakra-ui/react'
import * as Yup from 'yup'

import { Label } from 'components/layout/label'
import { PasswordInput } from 'components/elements/input'
import { useResetPasswordMutation } from 'shared/mutations/user'
import { APP_PATHS } from 'paths'
import styles from './pageStyles.module.css'

export const ResetPassword = () => {
  const { token } = useParams()
  const toast = useToast()
  const navigate = useNavigate()
  const { mutate: onResetPassword } = useResetPasswordMutation()
  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  }
  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .required('Please Enter your new password')
      .test('test-regex-groups', 'Bad password', (value) => {
        if (!value) return false

        const regexs = [
          { regex: /(?=.*\d)/, message: 'One Number' },
          { regex: /(?=.*[a-z])/, message: 'One Lowercase' },
          { regex: /(?=.*[A-Z])/, message: 'One Uppercase' },
          {
            regex: /(?=.*[`~!@#$%^&*()\-_=+[{}\]|;:'",<.>/?€£¥₹])/,
            message: 'One Special Case Character',
          },
        ]
        let messages = []
        if (size(value) < 9) {
          messages.push('9 Characters')
        }
        regexs.map(({ regex, message }) => {
          const matches = regex.exec(value)
          if (!matches) messages.push(message)
        })
        if (!isEmpty(messages)) {
          return new Yup.ValidationError(
            'Must Contain ' + messages.join(', '),
            value,
            'newPassword'
          )
        }
        return true
      }),
    confirmPassword: Yup.string()
      .required('Please re-type your new password')
      .oneOf([Yup.ref('newPassword')], 'Passwords does not match'),
  })

  const handleSubmit = async (values) => {
    const { newPassword } = values
    if (token && newPassword) {
      onResetPassword(
        { newPassword, token },
        {
          onError: (error) => {
            let errorMessage = error.message
            if (error.message === 'Invalid token.') {
              errorMessage =
                'Your password recovery token is expired. Please send a new password reset request.'
            }
            toast({
              position: 'top-right',
              status: 'error',
              title: errorMessage,
              isClosable: true,
            })
          },
          onSuccess: () => {
            navigate(APP_PATHS.registration)
          },
        }
      )
    }
  }
  return (
    <div className={styles.wrapper}>
      <Label>Please select your new password</Label>
      <Formik
        enableReinitialize
        noValidate
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {(formik) => (
          <Form noValidate>
            <VStack rowGap={4}>
              <PasswordInput
                label="New Password"
                name="newPassword"
                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                formik={formik}
                color="white"
              />
              <PasswordInput
                label="Repeat Password"
                name="confirmPassword"
                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                formik={formik}
                color="white"
              />
              <Button
                disabled={!formik.isValid || !formik.values.newPassword}
                colorScheme="pink"
                type="submit"
              >
                Change password
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </div>
  )
}
