import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import { useOutsideClick } from 'shared/hooks/useOutsideClick'
import Logo from 'shared/icons/logo-white.png'
import AvatarPlaceholder from 'shared/images/profile/avatarColor.svg'
import { MenuLinksLoggedIn, MenuLinksLoggedOut } from './links'
import { Link } from 'react-router-dom'
import {
  useUserContextState,
  useUserContextStateDispatch,
} from 'shared/contexts/user-context-provider'
import { isEmpty } from 'lodash'
import { useReadMyUserProfile } from 'shared/queries/userProfile'
import { Auth } from './auth'
import { APP_PATHS } from '../../../paths'
import { Box, Image, Text } from '@chakra-ui/react'

const getLinks = (loggedIn) => (loggedIn ? MenuLinksLoggedIn : MenuLinksLoggedOut)

const bottomLinks = [
  // {
  //   text: 'Safety Information',
  //   link: '',
  // },
  {
    text: 'Privacy Policy',
    link: APP_PATHS.privacyPolicy,
  },
  {
    text: 'Terms of Use',
    link: APP_PATHS.termsAndConditions,
  },
  // {
  //   text: 'Sales and Refunds',
  //   link: '',
  // },
  // {
  //   text: 'Legal',
  //   link: '',
  // },
]

export const MobileMenu = ({ onClose, isOpen }) => {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(isOpen || false)
  const mobileMenuRef = useRef(null)
  const topMenuRef = useRef(null)
  useEffect(() => setIsMobileMenuOpened(isOpen), [isOpen])
  const { user } = useUserContextState()
  const imageRef = useRef(null)

  const { data: profile } = useReadMyUserProfile(undefined, !isEmpty(user))

  useOutsideClick(mobileMenuRef, () => onMenuStateChange(false))
  const onMenuStateChange = (isOpen) => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      onClose?.()
      document.body.style.overflow = undefined
    }
    setIsMobileMenuOpened(isOpen)
  }
  const { onLogout } = useUserContextStateDispatch()

  return (
    <>
      {isMobileMenuOpened && <div className={styles.background} />}
      <div
        className={`${styles.mobile_menu} ${
          isMobileMenuOpened ? styles.mobile_menu_opened : ''
        }`}
        ref={mobileMenuRef}
      >
        <div className={styles.top} ref={topMenuRef}>
          <img src={Logo} alt={'Logo'} className={styles.logo} />
          {isEmpty(user) ? (
            <Auth onClose={onClose} />
          ) : (
            <div className={styles.profile_data}>
              <section className={styles.avatar_container}>
                <img
                  alt={'Avatar'}
                  className={styles.avatar}
                  ref={imageRef}
                  src={profile?.avatarUrl ?? AvatarPlaceholder}
                  placeholder={AvatarPlaceholder}
                />
              </section>
              <Box textAlign={'start'}>
                <Text fontSize={'18px'} fontWeight={'500'} lineHeight={'22px'}>
                  {profile?.firstName ?? ''} {profile?.lastName ?? ''}
                </Text>
                <Text as="span" fontSize={'12px'} fontWeight={'500'}>
                  {profile?.email || ''}
                </Text>
                <Text as="span" fontSize={'12px'} fontWeight={'600'} onClick={onLogout}>
                  Log Out
                </Text>
              </Box>
            </div>
          )}
        </div>
        <div
          className={styles.bottom}
          style={{ height: `calc(100% - ${topMenuRef.current?.clientHeight}px)` }}
        >
          <div className={styles.list}>
            {getLinks(!isEmpty(user)).map((link, index) => {
              return (
                <Link
                  to={link.link}
                  key={`${link.link} ${index}`}
                  className={styles.menu_link}
                  active={window.location.pathname === link.link ? 'active' : undefined}
                  onClick={() => onClose()}
                >
                  {/* <link.image /> */}
                  <Image
                    boxSize={'25px'}
                    src={window.location.pathname === link.link ? link.iconActive : link.icon}
                    alt={''}
                  />
                  <span>{link.text}</span>
                </Link>
              )
            })}
          </div>
          <div className={styles.list}>
            <div className={styles.divider} />
            {bottomLinks.map((link) =>
              link.link ? (
                <Link
                  to={link.link}
                  key={link.link + link.text}
                  className={styles.menu_link}
                  onClick={() => onClose()}
                >
                  {link.text}
                </Link>
              ) : (
                <span
                  key={link.text}
                  className={`${styles.menu_link} ${styles.menu_link_disabled}`}
                >
                  {link.text}
                </span>
              )
            )}
          </div>
        </div>
      </div>
    </>
  )
}
