import React from 'react'
import { TreatmentsProducts } from 'features/treatments/treatmentsProducts'
import { TreatmentsPlans } from 'components/widgets/TreatmentsPlans/TreatmentsPlans'
import { DoctorsList } from 'features/treatments/doctorsList'
import styles from '../home/styles.module.css'

export const TreatmentIndex = () => {
  return (
    <div>
      <TreatmentsProducts />
      <TreatmentsPlans />
      <DoctorsList clear={false} className={styles.doctors} />
    </div>
  )
}
