import { Stack, VStack, Text } from '@chakra-ui/react'
import React from 'react'

const DeskIcon = () => (
  <svg
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1961_2994)">
      <path
        d="M50.4004 100C78.0146 100 100.4 77.6142 100.4 50C100.4 22.3858 78.0146 0 50.4004 0C22.7862 0 0.400391 22.3858 0.400391 50C0.400391 77.6142 22.7862 100 50.4004 100Z"
        fill="#E3B8D7"
      />
      <path
        d="M41.4993 30.167H35.8327C34.3298 30.167 32.8884 30.764 31.8257 31.8267C30.763 32.8894 30.166 34.3308 30.166 35.8337V69.8337C30.166 71.3366 30.763 72.7779 31.8257 73.8406C32.8884 74.9033 34.3298 75.5003 35.8327 75.5003H64.166C65.6689 75.5003 67.1102 74.9033 68.173 73.8406C69.2357 72.7779 69.8327 71.3366 69.8327 69.8337V35.8337C69.8327 34.3308 69.2357 32.8894 68.173 31.8267C67.1102 30.764 65.6689 30.167 64.166 30.167H58.4994"
        stroke="#D537A8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.332 50.6667H51.6654M45.9987 45V56.3333M41.4987 30.1667C41.4987 28.6638 42.0957 27.2224 43.1584 26.1597C44.2211 25.097 45.6625 24.5 47.1654 24.5H52.832C54.3349 24.5 55.7763 25.097 56.839 26.1597C57.9017 27.2224 58.4987 28.6638 58.4987 30.1667C58.4987 31.6696 57.9017 33.1109 56.839 34.1736C55.7763 35.2363 54.3349 35.8333 52.832 35.8333H47.1654C45.6625 35.8333 44.2211 35.2363 43.1584 34.1736C42.0957 33.1109 41.4987 31.6696 41.4987 30.1667Z"
        stroke="#D537A8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="58.0703"
        y="58.2139"
        width="20"
        height="13.4744"
        transform="rotate(-45 58.0703 58.2139)"
        fill="#E3B8D7"
      />
      <path
        d="M56.1562 57.4062L62.7188 63.9688L75.8438 49.9062"
        stroke="#D537A8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1961_2994">
        <rect width="101" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const RecurrentIcon = () => (
  <svg
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1961_3015)">
      <path
        d="M50.4004 100C78.0146 100 100.4 77.6142 100.4 50C100.4 22.3858 78.0146 0 50.4004 0C22.7862 0 0.400391 22.3858 0.400391 50C0.400391 77.6142 22.7862 100 50.4004 100Z"
        fill="#E3B8D7"
      />
      <path
        d="M53.7603 79L63 71.5481L53.7603 64.0962V68.0321C52.6584 68.2332 51.5306 68.3382 50.368 68.3382C40.0178 68.3382 31.637 59.8805 31.637 49.4548C31.637 43.586 34.2918 38.3469 38.4562 34.8834L32.8169 31C27.9932 35.6443 25 42.1953 25 49.4461C25 63.5714 36.3566 75.0204 50.368 75.0204C51.5219 75.0204 52.6498 74.9417 53.7603 74.793V78.9913V79Z"
        stroke="#D537A8"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M47.2397 20L38 27.4519L47.2397 34.9038L47.2397 30.9679C48.3416 30.7668 49.4694 30.6618 50.632 30.6618C60.9822 30.6618 69.363 39.1195 69.363 49.5452C69.363 55.414 66.7082 60.6531 62.5438 64.1166L68.1831 68C73.0068 63.3557 76 56.8047 76 49.5539C76 35.4286 64.6434 23.9796 50.632 23.9796C49.4781 23.9796 48.3502 24.0583 47.2397 24.207L47.2397 20.0087L47.2397 20Z"
        stroke="#D537A8"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M40.9346 56V53.796L45.3996 49.16C46.2989 48.2353 46.7486 47.45 46.7486 46.804C46.7486 46.386 46.6219 46.0503 46.3686 45.797C46.1153 45.531 45.7669 45.398 45.3236 45.398C44.4369 45.398 43.7846 45.8793 43.3666 46.842L40.8016 45.341C41.2069 44.391 41.8149 43.669 42.6256 43.175C43.4363 42.681 44.3229 42.434 45.2856 42.434C46.5269 42.434 47.5846 42.8203 48.4586 43.593C49.3453 44.353 49.7886 45.379 49.7886 46.671C49.7886 48.077 49.0983 49.4703 47.7176 50.851L45.5136 53.074H49.9786V56H40.9346ZM61.2919 51.06V53.872H59.9619V56H56.8839V53.872H50.8419V51.06L55.0789 42.7H58.3469L54.1099 51.06H56.8839V48.21H59.9619V51.06H61.2919Z"
        fill="#D537A8"
      />
    </g>
    <defs>
      <clipPath id="clip0_1961_3015">
        <rect width="101" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const SupportIcon = () => (
  <svg
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1961_3032)">
      <path
        d="M50.4004 100C78.0146 100 100.4 77.6142 100.4 50C100.4 22.3858 78.0146 0 50.4004 0C22.7862 0 0.400391 22.3858 0.400391 50C0.400391 77.6142 22.7862 100 50.4004 100Z"
        fill="#E3B8D7"
      />
      <path
        d="M71.5189 39.5V57.5L61.5189 67.5V75.5H55.0189V67.5C54.8522 65.6667 55.7189 61.5 60.5189 59.5C65.3189 57.5 66.5193 54.3333 66.5195 53"
        stroke="#D537A8"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M31.0007 39.5V57.5L41.0007 67.5V75.5H47.5007V67.5C47.6673 65.6667 46.8007 61.5 42.0007 59.5C37.2007 57.5 36.0002 54.3333 36 53"
        stroke="#D537A8"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M56.1022 45.9298V44.022C56.1022 41.4864 55.1 39.0593 53.3008 37.2723C51.5138 35.4852 49.0868 34.471 46.5511 34.471C44.0154 34.471 41.5884 35.4732 39.8013 37.2723C38.0143 39.0593 37 41.4864 37 44.022V45.9298H65.4721V44.022C65.4721 42.5006 64.8684 41.0396 63.7938 39.9649C62.7191 38.8903 61.2581 38.2866 59.7367 38.2866M59.7367 38.2866C58.7224 38.2866 57.7564 37.8881 57.0319 37.1636C56.3195 36.4512 55.909 35.4732 55.909 34.4589C55.909 33.4446 56.3074 32.4786 57.0319 31.7542C57.7443 31.0417 58.7224 30.6312 59.7367 30.6312C60.7509 30.6312 61.7169 31.0297 62.4414 31.7542C63.1538 32.4666 63.5643 33.4446 63.5643 34.4589C63.5643 35.4732 63.1659 36.4391 62.4414 37.1636C61.729 37.876 60.7509 38.2866 59.7367 38.2866ZM46.539 34.471C45.0176 34.471 43.5566 33.8672 42.4819 32.7926C41.4073 31.7179 40.8035 30.2569 40.8035 28.7355C40.8035 27.2141 41.4073 25.753 42.4819 24.6784C43.5566 23.6037 45.0176 23 46.539 23C48.0604 23 49.5215 23.6037 50.5961 24.6784C51.6708 25.753 52.2745 27.2141 52.2745 28.7355C52.2745 30.2569 51.6708 31.7179 50.5961 32.7926C49.5215 33.8672 48.0604 34.471 46.539 34.471Z"
        stroke="#D537A8"
        strokeWidth="3"
        strokeMiterlimit="16"
        strokeLinecap="round"
      />
      <path d="M41.8652 46.2796V41.7637" stroke="#D537A8" strokeWidth="3" />
      <path d="M51.5879 46.2796V41.7637" stroke="#D537A8" strokeWidth="3" />
    </g>
    <defs>
      <clipPath id="clip0_1961_3032">
        <rect width="101" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const Services = () => {
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      w="100%"
      justifyContent="center"
      p="10px 100px 20px 100px"
      gap="60px"
      alignItems="flex-start"
    >
      <VStack w={{ base: '100%', md: '30%' }}>
        <DeskIcon />
        <Text fontWeight="600" fontSize="20px">
          The Highest Medical Assurance
        </Text>
        <Text fontSize={'12px'} lineHeight={'18px'}>
          Our pharmacy partners comply with FDA Aseptic Processing Guidance, Controlled
          Environment Testing Association (CETA), American Society of Health System Pharmacists
          (ASHP) and are members of the Professional Compounding Centers of America (PCCA).
        </Text>
      </VStack>
      <VStack w={{ base: '100%', md: '30%' }}>
        <RecurrentIcon />
        <Text fontWeight="600" fontSize="20px">
          Customer Policy
        </Text>
        <Text fontSize={'12px'} lineHeight={'18px'}>
          We allow you to cancel at any time. <br />
          We have a fair and transparent refund policy.
        </Text>
      </VStack>
      <VStack w={{ base: '100%', md: '30%' }}>
        <SupportIcon />
        <Text fontWeight="600" fontSize="20px">
          Care and Support
        </Text>
        <Text fontSize={'12px'} lineHeight={'18px'}>
          We make sure there is a health care professional on hand for your entire journey and
          will be there to support you at each dosage increase at no additional cost. Our AI
          powered adherence platform is designed to help you at every step of your journey and is
          free at the point of use.
        </Text>
      </VStack>
    </Stack>
  )
}
