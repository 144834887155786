import React, { useRef } from 'react'
import styles from './styles.module.css'
import { Label } from 'components/layout/label'
import { useAskBotStore } from '../../shared/stores/askBotStore'
import { Link } from 'react-router-dom'
import { TreatmentProductItemsItems } from '../treatments/TreatmentPlans'
import { AskBot } from 'components/elements/askBot'
import { Box, Text } from '@chakra-ui/react'
import { useIndexServiceConfig } from 'shared/queries/serviceConfig'

export const Page = () => {
  const { data: serviceConfig = [{ faqQuestions: [] }] } = useIndexServiceConfig()

  const { setCurrentQuestion } = useAskBotStore()
  // const onQuestionClick = (text) => {
  //   return () => setCurrentQuestion(text)
  // }

  const askBotRef = useRef(null)

  const onQuestionClick = (text) => {
    return () => {
      setCurrentQuestion(text)

      // Scroll to AskBot when a question is clicked
      if (askBotRef.current) {
        askBotRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  const questions = serviceConfig[0]?.faqQuestions

  return (
    <div className={styles.wrapper}>
      <div className={styles.paddings} style={{ paddingBottom: '5px' }}>
        <Label
          subTitle={
            <div className={styles.additional_lable}>
              <span>
                For any other questions, please try our Maion AI or email us at{' '}
                <Link to={'mailto:chat@maion.ai'}>chat@maion.ai</Link>
              </span>
            </div>
          }
        >
          FAQ
        </Label>
        <ul className={styles.text}>
          {questions?.map((question) => (
            <li onClick={onQuestionClick(question.text)} key={question.text}>
              <LiMarker />
              {question.text}
            </li>
          ))}
        </ul>
        <Text className={styles.bottom_text}>
          Ask Maion if you want to know anything else...
        </Text>
      </div>
      <div ref={askBotRef}>
        <AskBot />
      </div>
      <TreatmentProductItemsItems style={{ paddingTop: '45px' }} />
    </div>
  )
}

const LiMarker = () => (
  <Box boxSize={'12px'}>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="5" stroke="currentColor" strokeWidth="2" />
    </svg>
  </Box>
)
