import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import './styles.css'
import ArrowLeft from 'shared/icons/arrows/arrowLeft.svg'
import ArrowRight from 'shared/icons/arrows/arrowRight.svg'

import { Label } from 'components/layout/label'
import { useBasketStore } from 'shared/stores/basketStore'
import { useToast } from '@chakra-ui/react'
import { useMediaQuery } from '../../../shared/hooks/useMediaQuery'
import { APP_PATHS } from 'paths'

const indicators = () => <div className="indicator" />

export const Slideshow = ({ products, clear = false, onChange }) => {
  const isMobile = useMediaQuery('(max-width: 800px)')
  const { addItem, reduceItemAmount } = useBasketStore()
  const navigate = useNavigate()
  const toast = useToast()
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    onChange?.({ item: products[0] })
  }, [])

  const addOneItemToBasket = useCallback(
    (item) => {
      addItem(item)
      reduceItemAmount(item.id, 1)
      toast({
        position: 'top-right',
        status: 'success',
        title: `${item.title} added to your basket`,
        duration: 2000,
        isClosable: true,
      })
    },
    [addItem, reduceItemAmount]
  )

  const onStartChange = useCallback(
    (from, to) => {
      setActiveIndex(to)
      onChange?.({ item: products[to] })
    },
    [products]
  )

  const topData = useMemo(() => {
    return products[activeIndex]
  }, [products, activeIndex])

  const onProductClick = (product) => () => {
    addOneItemToBasket(product)
  }

  return (
    <div className="slide-container" style={{ width: '100%' }}>
      {!clear && (
        <div className="header_info">
          <Label>{topData.title}</Label>
          <span className="description">{topData.description}</span>
          <div className="links">
            <Link to={`#treatment-${topData.id}`}>Treatment Plans</Link>
            <Link to={`#info-${topData.id}`}>Find Out More</Link>
          </div>
        </div>
      )}
      <Slide
        prevArrow={
          isMobile ? <></> : <img src={ArrowLeft} alt={'Next'} className="arrow_left" />
        }
        nextArrow={
          isMobile ? <></> : <img src={ArrowRight} alt={'Previous'} className="arrow_right" />
        }
        indicators={indicators}
        pauseOnHover
        onStartChange={onStartChange}
        easing={'ease'}
      >
        {products.map((product, index) => (
          <div key={index} style={{ display: 'grid', placeContent: 'center', height: '100%' }}>
            <div className="slide_item" style={clear ? { padding: 0 } : undefined}>
              <img
                src={product.imageUrl}
                alt={`Slider ${index} image`}
                onClick={() =>
                  navigate(
                    generatePath(APP_PATHS.product, {
                      id: product?.id,
                    })
                  )
                }
              />
              {!clear && (
                <span>
                  {index === 0 ? 'No insurance needed, no hidden fees, just a better life.' : ''}
                </span>
              )}
            </div>
          </div>
        ))}
      </Slide>

      <div className="bottom_wrapper">
        {!clear && (
          <div className="price_chip" onClick={onProductClick(products[activeIndex])}>
            <label>${products[activeIndex].priceInCents / 100}</label>
            <span>Intro offer</span>
          </div>
        )}
        {products?.[activeIndex]?.title === 'Semaglutide' ? (
          <span>
            Semaglutide is the active ingredient in <b>Wegovy®</b>
          </span>
        ) : (
          <span> </span>
        )}
        {!clear && (
          <div className="bottom_indicators">
            <div
              className="bottom_indicator_item"
              style={{
                width: `${100 / products.length}%`,
                left: `${activeIndex * (100 / products.length)}%`,
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
