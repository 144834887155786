import React from 'react'

import styles from './styles.module.css'

export const ImageCard = ({ image, text }) => {
  return (
    <div className={styles.wrapper}>
      <img src={image} />
      <div className={styles.text}>{text}</div>
    </div>
  )
}
