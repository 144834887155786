// import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'
import { QueriesKeysEnum } from './queries-keys-enum'

export const useValidateCouponCode = (params = {}, enabled = true) => {
  const toast = useToast()
  const url = `/validateCouponCode?code=${params.code}&productIds=${params.productIds.join(',')}`

  return useQuery([QueriesKeysEnum.coupons, params], () => axios.get(url), {
    retry: false,
    enabled,
    onError: (error) => {
      console.log(error)
      if (error.error.code !== 'MNC-33')
        toast({
          position: 'top-right',
          status: 'error',
          title: error.message,
          isClosable: true,
        })
    },
  })
}
