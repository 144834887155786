import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, InputGroup, InputRightElement, Textarea } from '@chakra-ui/react'
import { useField } from 'formik'

import { get } from 'lodash'

import styles from './styles.module.css'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

export const Input = ({
  name,
  placeholder,
  label,
  type,
  value,
  onChange,
  required,
  errorMessage,
  color,
  className,
  inputProps,
  note,
}) => {
  return (
    <div className={`${styles.wrapper} ${className ?? ''}`}>
      {label && (
        <label htmlFor={name}>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <input
        id={name}
        className={color === 'white' ? styles.input_white : styles.input}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={onChange}
        required={required}
        {...inputProps}
        autoComplete={inputProps?.autoComplete ?? type === 'tel' ? 'off' : undefined}
      />
      {!!note && <span className={styles.note}>{note}</span>}
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email', 'date', 'tel', 'password']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  color: PropTypes.oneOf(['gray', 'white']),
  className: PropTypes.string,
  note: PropTypes.string,
}

Input.defaultProps = {
  type: 'text',
  required: false,
  color: 'gray',
}

export const FormikInput = ({
  formik,
  name,
  placeholder,
  label,
  type,
  required,
  color,
  className,
  inputProps,
  onChange,
  errors,
  note,
}) => {
  const onChangeHandler = (e) => {
    if (onChange) onChange(e)
    if (type === 'tel') {
      let value = e.target?.value
      if (Number.isNaN(Number(value.replace(/\s+/g, '')))) return
      if (value.length > 12) return
      if (e.nativeEvent?.inputType === 'insertText') {
        if ([3, 7].includes(value.length)) {
          value = value + ' '
        }
        if ([4, 8].includes(value.length) && !value.endsWith(' ')) {
          const lastNumber = value.at(-1)
          value = `${value.slice(0, value.length - 1)} ${lastNumber}`
        }
      }

      if (
        e.nativeEvent?.inputType?.toLowerCase().includes('delete') &&
        [4, 8].includes(value.length)
      ) {
        value = value.trim()
      }
      formik.setFieldValue(name, value)
      return
    }
    formik.handleChange(e)
  }
  return (
    <Input
      name={name}
      placeholder={placeholder}
      label={label}
      type={type}
      required={required}
      color={color}
      className={className}
      value={get(formik.values, name)}
      onChange={onChangeHandler}
      errorMessage={get(errors, name) || get(formik.errors, name)}
      inputProps={inputProps}
      note={note}
    />
  )
}

export const PasswordInput = (inputProps) => {
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup size="md">
      <FormikInput pr="4.5rem" type={show ? 'text' : 'password'} {...inputProps} />
      <InputRightElement
        className={styles.inputButton}
        top={inputProps.label ? '29px' : '0'}
        w="3rem"
      >
        <IconButton
          size="sm"
          aria-label="View password"
          onClick={handleClick}
          icon={show ? <ViewOffIcon /> : <ViewIcon />}
        />
      </InputRightElement>
    </InputGroup>
  )
}

export const FormikTextarea = ({ ...props }) => {
  const [field, meta] = useField(props)

  return (
    <>
      <Textarea {...field} {...props} />
      {meta.touched && meta.error ? <div style={{ color: 'red' }}>{meta.error}</div> : null}
    </>
  )
}
