import React from 'react'

import AvatarPlaceHolder from 'shared/images/profile/avatar.svg'
import styles from './components.module.css'
import profileStyles from '../profile.module.css'
import { LeftMenuLinks } from '../data'
import { Link } from 'react-router-dom'
import { APP_PATHS } from '../../../paths'
import { useMediaQuery } from '../../../shared/hooks/useMediaQuery'
import { UploadAvatar } from './uploadAvatar/UploadAvatar'

export const LeftMenu = ({ profile, onLogout, refetchProfile }) => {
  const { firstName = '', lastName = '', avatarUrl = AvatarPlaceHolder } = profile || {}
  const isHasNewMessage = true

  const isMobile = useMediaQuery('(max-width: 800px)')

  return (
    <div className={styles.left_menu_wrapper}>
      {(!isMobile || window.location.pathname === APP_PATHS.myProfile) && (
        <div className={`${styles.user_info} ${isMobile ? profileStyles.item_bg : ''}`}>
          {isMobile ? (
            <UploadAvatar profile={profile} refetchProfile={refetchProfile} />
          ) : (
            <img src={avatarUrl} alt={'avatar'} />
          )}
          <span>
            {firstName} {lastName}
          </span>
        </div>
      )}
      <div className={styles.links}>
        {LeftMenuLinks.map((link) => (
          <Link
            key={link.text}
            to={link.link}
            active={window.location.pathname === link.link && 'active'}
            hasNew={link.link === APP_PATHS.myProfileMessagesPage && isHasNewMessage && 'hasNew'}
          >
            <link.image />
            {link.text}
          </Link>
        ))}
      </div>
      <button className={styles.sign_out_button} onClick={onLogout}>
        Sign Out
      </button>
    </div>
  )
}
