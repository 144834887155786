import React, { useRef } from 'react'
import styles from '../../profile.module.css'
import AddIcon from 'shared/icons/misc/addGreen.svg'
import axios from 'axios'
import { useToast } from '@chakra-ui/react'
import { useUpdateMyUserProfileMutation } from 'shared/mutations/userProfile'
import AvatarPlaceHolder from 'shared/images/profile/avatar.svg'

export const UploadAvatar = ({ profile, refetchProfile }) => {
  const { avatarUrl = AvatarPlaceHolder } = profile || {}

  const toast = useToast()
  const uploadRef = useRef()
  const { mutate: updateProfile } = useUpdateMyUserProfileMutation()

  const onAvatarChangeClick = () => {
    uploadRef.current.click()
  }
  return (
    <div className={styles.userPage_avatar}>
      <div className={styles.upload}>
        <img src={AddIcon} alt={'add'} onClick={onAvatarChangeClick} />
        <input
          type={'file'}
          onChange={async (e) => {
            const file = e.target.files[0]
            if (file) {
              const formData = new FormData()
              formData.append('file', file)
              const { url } = await axios.post('/media/avatars', formData)
              if (url) {
                updateProfile(
                  {
                    ...profile,
                    avatarUrl: url,
                  },
                  {
                    onSuccess: () => {
                      toast({
                        position: 'top-right',
                        status: 'success',
                        title: 'Profile avatar successfully updated',
                        isClosable: true,
                      })
                      refetchProfile()
                    },
                  }
                )
              } else {
                toast({
                  position: 'top-right',
                  status: 'error',
                  title: 'Could not updated avatar',
                  isClosable: true,
                })
              }
            }
          }}
          ref={uploadRef}
        />
      </div>
      <img src={avatarUrl} alt={'Avatar'} className={styles.avatar} />
    </div>
  )
}
