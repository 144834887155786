import React from 'react'
import { Label } from 'components/layout/label'

import BookLogo from 'shared/icons/misc/bookWhite.svg'
import InfoIcon from 'shared/icons/misc/info.svg'
import styles from './styles.module.css'

export const ReNewPrescriptionFailed = ({ message }) => {
  return (
    <div className={styles.wrapper}>
      <Label>Confirmation</Label>
      <div className={styles.wrapper_payment}>
        <div className={styles.wrapper_icon_gray}>
          <img color="white" src={BookLogo} alt="Prescription error" />
        </div>
        <p className={styles.payment_label}>Subscription Renewal Failed</p>
        <p className={styles.payment_body}>We could not send your data for renewal.</p>
        <p className={styles.payment_body}>{message}</p>

        <hr />
        <img color="white" src={InfoIcon} alt="Info" />
        <p className={styles.payment_sublabel}> Do not worry 😉</p>

        <p className={`${styles.payment_body} ${styles.nowrap}`}>
          Sometimes it happens. Please go back and check your payment method or{' '}
          <a className={styles.link} href="mailto:help@maion.ai">
            contact us
          </a>
          .
        </p>
      </div>
    </div>
  )
}
