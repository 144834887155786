import React from 'react'
import styles from './styles.module.css'
import { useIndexServiceConfig } from 'shared/queries/serviceConfig'
import { STATE_TO_NAME } from 'shared/constants/states'

export const StatesModal = () => {
  const { data: serviceConfig = [{ availabelStates: [] }] } = useIndexServiceConfig()
  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>Here’s a list of the states we serve</p>
      <p className={styles.disclaimer}>
        {serviceConfig[0].availabelStates.map((state) => STATE_TO_NAME[state]).join(', ')}
      </p>
    </div>
  )
}
