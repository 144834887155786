import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

export const Checkbox = ({ name, label, checked, onChange, className = '' }) => {
  return (
    <label className={`${styles.container} ${className}`}>
      {label}
      <input
        id={name}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={(e) => onChange?.(e.target.checked)}
      />
      <span className={styles.checkmark} />
    </label>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.element.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}
