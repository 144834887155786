import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isEmpty, size } from 'lodash'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
} from '@chakra-ui/react'

import RoundArrow from 'shared/icons/misc/roundArrow.svg'
import CreditCardIcon from 'shared/icons/misc/creditCard.svg'

import { CardSkeleton, Button } from 'shared/components'
import { useChangePaymentMethodMutation } from 'shared/mutations/subscription'
import {
  useCreatePaymentMethodMutation,
  useDeleteMyPaymentMethodMutation,
} from 'shared/mutations/paymentMethod'

import { GooglePayPayment, StripePayment } from 'features/payment/components/stripe/stripe'

import { environment } from 'environments'
import styles from './styles.module.css'

const stripePromise = loadStripe(environment.STRIPE_PUBLIC_KEY)

const PaymentMethod = ({
  onCloseChangeCardModal,
  totalPrice,
  subscriptionId,
  paymentMethod,
  paymentMethods,
  refetchPaymentMethods,
  refetchSubscriptions,
  profile,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)

  const [isAddCard, setIsAddCard] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [selectedCard, setSelectedCard] = useState(0)

  const { mutateAsync: createPaymentMethod } = useCreatePaymentMethodMutation()
  const { mutateAsync: deletePaymentMethod } = useDeleteMyPaymentMethodMutation()
  const { mutateAsync: changePaymentMethod } = useChangePaymentMethodMutation()

  let savedPaymentMethod = ''
  if (selectedCard > 0) {
    savedPaymentMethod = paymentMethods[selectedCard - 1]
  }
  const isCurrentMethodUsed = paymentMethod.id === savedPaymentMethod?.id

  const handleExpress = async (event) => {
    if (!stripe) {
      return
    }

    const { error: submitError } = await elements.submit()
    if (submitError) {
      setError(submitError.message)
      return
    }
    const { billingDetails } = event

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: billingDetails,
      },
    })

    if (error) {
      setError(error.message)
      setProcessing(false)
      return
    }

    const savedPaymentMethod = await createPaymentMethod(paymentMethod.id, {
      onSuccess: () => {
        refetchPaymentMethods()
      },
    })

    const params = {
      subscriptionId: subscriptionId,
      paymentMethodId: savedPaymentMethod.id,
    }

    await changePaymentMethod(params, {
      onSuccess: () => {
        refetchSubscriptions()
        onCloseChangeCardModal()
      },
    })
    setProcessing(false)
  }

  const handleCreateAndSetPaymentMethod = async (values) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }
    setProcessing(true)

    if (error) {
      setProcessing(false)
      return
    }

    elements.submit()
    const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          name: values.name,
          email: values.email,
          address: {
            country: 'US',
          },
        },
      },
    })

    if (paymentError) {
      setProcessing(false)
      setError(paymentError)
      return
    }

    const savedPaymentMethod = await createPaymentMethod(paymentMethod.id, {
      onSuccess: () => {
        refetchPaymentMethods()
      },
    })

    const params = {
      subscriptionId: subscriptionId,
      paymentMethodId: savedPaymentMethod.id,
    }

    await changePaymentMethod(params, {
      onSuccess: () => {
        refetchSubscriptions()
        onCloseChangeCardModal()
      },
    })
    setProcessing(false)
  }

  const handleDeletePaymentMethod = async () => {
    await deletePaymentMethod(savedPaymentMethod.id, {
      onSuccess: () => {
        refetchPaymentMethods()
        setSelectedCard(selectedCard - 1)
      },
    })
  }

  const handleUsePaymentMethodInSubscription = async () => {
    const params = {
      subscriptionId: subscriptionId,
      paymentMethodId: savedPaymentMethod.id,
    }

    await changePaymentMethod(params, {
      onSuccess: () => {
        refetchSubscriptions()
        onCloseChangeCardModal()
      },
    })
  }

  const cardSchema = Yup.object({
    name: Yup.string()
      .required('Card Holder name is required')
      .max(20, 'Name cannot be longer than 20 characters'),
    email: Yup.string().email('Invalid email').required('Email Address is required'),
    phone: Yup.string(),
  })
  const formik = useFormik({
    initialValues: {
      name: profile ? `${profile.firstName} ${profile.lastName}` : '',
      email: profile?.email || '',
      saveData: false,
    },
    validationSchema: cardSchema,
    onSubmit: handleCreateAndSetPaymentMethod,
  })
  useEffect(() => {
    if (!isEmpty(profile)) {
      formik.setFieldValue('name', `${profile.firstName} ${profile.lastName}`)
      formik.setFieldValue('email', profile?.email)
    }
  }, [profile])

  return (
    <div className={`${styles.payment_part_column} ${styles.payment_left_part}`}>
      {isAddCard ? (
        <>
          <StripePayment
            formik={formik}
            error={error}
            setError={setError}
            saveMyDataCheckBox={false}
          />
          <HStack>
            <Button onClick={() => setIsAddCard(false)}>Return</Button>
            <Button
              disabled={!!error || processing}
              onClick={() => handleCreateAndSetPaymentMethod(formik.values)}
            >
              Add PM
            </Button>
          </HStack>
        </>
      ) : (
        <Accordion defaultIndex={[0]} allowMultiple w="100%">
          <AccordionItem>
            <h2>
              <AccordionButton>
                <img src={CreditCardIcon} alt="Credit card icon" />
                <Box as="span" flex="1" textAlign="left" ml={2}>
                  Credit card
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <div className={styles.credit_card_block_card_wrapper}>
                <div className={styles.card_wrapper}>
                  <div className={styles.card}>
                    {selectedCard === 0 ? (
                      <CardSkeleton totalPriceInCents={totalPrice} />
                    ) : (
                      <CardSkeleton
                        totalPriceInCents={totalPrice}
                        mask={paymentMethods[selectedCard - 1]?.cardNumberMask}
                        saved={true}
                        brand={paymentMethods[selectedCard - 1]?.brand}
                        expMonth={paymentMethods[selectedCard - 1]?.expMonth}
                        expYear={paymentMethods[selectedCard - 1]?.expYear}
                      />
                    )}
                  </div>
                  <div
                    className={styles.card_back}
                    style={{
                      background: 'var(--gray-text-secondary)',
                      right: '-10px',
                      height: '86%',
                      zIndex: 3,
                    }}
                  />
                  <div
                    className={styles.card_back}
                    style={{
                      background: 'var(--secondary-bg)',
                      right: '-20px',
                      height: '70%',
                      zIndex: 2,
                    }}
                  />
                </div>

                <div className={styles.switch_method}>
                  {selectedCard > 0 && (
                    <img
                      className={styles.prev_button}
                      src={RoundArrow}
                      alt={'Prev'}
                      onClick={() => setSelectedCard((prevState) => prevState - 1)}
                    />
                  )}
                  Switch Payment Method
                  {size(paymentMethods) > selectedCard && (
                    <img
                      src={RoundArrow}
                      alt={'Next'}
                      onClick={() => setSelectedCard((prevState) => prevState + 1)}
                    />
                  )}
                </div>
                <HStack>
                  {selectedCard === 0 && (
                    <Button onClick={() => setIsAddCard(true)}>Add Card</Button>
                  )}
                  {selectedCard !== 0 && (
                    <>
                      <Button disabled={isCurrentMethodUsed} onClick={handleDeletePaymentMethod}>
                        Delete PM
                      </Button>
                      <Button
                        disabled={isCurrentMethodUsed}
                        onClick={handleUsePaymentMethodInSubscription}
                      >
                        Set PM
                      </Button>
                    </>
                  )}
                </HStack>
              </div>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <Box m={4}>
              <GooglePayPayment stripe={stripe} amount={totalPrice} onConfirm={handleExpress} />
            </Box>
          </AccordionItem>
        </Accordion>
      )}
    </div>
  )
}

export const PaymentMethodModal = ({
  isOpenChangeCardModal,
  onCloseChangeCardModal,
  onOpenChangeCardModal,
  subscriptionId,
  paymentMethod,
  paymentMethods,
  refetchPaymentMethods,
  refetchSubscriptions,
  profile,
}) => {
  const options = {
    mode: 'setup',
    amount: 0,
    currency: 'usd',
    paymentMethodCreation: 'manual',
    appearance: {
      variables: {
        borderRadius: '10px',
        fontFamily: 'futura-pt, sans-serif',
        spacingUnit: '4px',
      },
      rules: {
        '.Label': {
          color: '#4f5b76',
          fontSize: '17px',
          fontWeight: '450',
          lineHeight: 'normal',
          fontFamily: 'futura-pt, sans-serif',
        },
        '.Input': {
          border: '2.5px solid #e0e0e0',
          boxShadow: '0px 2.51px 5.01px 0px rgba(0, 0, 0, 0.12)',
          borderRadius: '10px',
          fontSize: '16px',
          marginBottom: '4px',
        },

        '.Input:focus': {
          borderColor: '2.5px solid #e0e0e0',
          boxShadow: '0px 2.51px 5.01px 0px rgba(0, 0, 0, 0.12)',
        },
      },
    },
  }

  return (
    <Modal
      isOpen={isOpenChangeCardModal}
      onClose={onCloseChangeCardModal}
      size="lg"
      isCentered
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Payment Method</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Elements stripe={stripePromise} options={options}>
            <PaymentMethod
              onCloseChangeCardModal={onCloseChangeCardModal}
              onOpenChangeCardModal={onOpenChangeCardModal}
              subscriptionId={subscriptionId}
              paymentMethod={paymentMethod}
              paymentMethods={paymentMethods}
              refetchPaymentMethods={refetchPaymentMethods}
              refetchSubscriptions={refetchSubscriptions}
              profile={profile}
            />
          </Elements>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onCloseChangeCardModal}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
