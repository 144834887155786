import React, { useCallback, useRef, useState } from 'react'

import styles from './styles.module.css'
import { Label } from 'components/layout/label'
import { Link } from 'react-router-dom'
import { APP_PATHS } from '../../paths'

const mockData = Array.from(new Array(30)).map(() => ({
  isActive: false,
}))

export const SchedulePage = () => {
  const ref = useRef()
  const [, setForseRerender] = useState({})
  ref.current = mockData

  const toggle = useCallback(
    (index) => {
      return () => {
        ref.current[index].isActive = !ref.current[index].isActive
        setForseRerender({})
      }
    },
    [ref.current]
  )

  return (
    <div className={styles.wrapper}>
      <Label>Schedule your Maion Appointment</Label>
      <span>What state do you live in?</span>
      <div className={styles.items_wrapper}>
        {ref.current?.map((item, index) => (
          <div
            key={index}
            className={`${item.isActive ? styles.item_active : {}} ${styles.item}`}
            onClick={toggle(index)}
          >
            Arizona
          </div>
        ))}
      </div>
      <Link to={APP_PATHS.schedulePickDate}>Next</Link>
    </div>
  )
}
