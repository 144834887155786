import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Label } from 'components/layout/label'
import { APP_PATHS } from 'paths'

import BookLogo from 'shared/icons/misc/bookWhite.svg'
import styles from './styles.module.css'

export const ReNewPrescriptionComplete = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.wrapper}>
      <Label>Confirmation</Label>
      <div className={styles.wrapper_payment}>
        <div className={styles.wrapper_icon}>
          <img color="white" src={BookLogo} alt="Renew complete" />
        </div>
        <p className={styles.payment_label}>Prescription renewal request sent!</p>
        <div className={styles.buttons}>
          <button
            className={styles.continue}
            onClick={() => navigate(APP_PATHS.myProfileBillingPage)}
          >
            Review subscription
          </button>
        </div>
        <p className={styles.payment_body}>
          If for any reason you wish to cancel your appointment please don’t hesitate to contact
          us at:{' '}
          <a className={styles.link} href="mailto:help@maion.ai">
            help@maion.ai
          </a>
        </p>
      </div>
    </div>
  )
}
