import React from 'react'
import styles from 'styles/home.module.css'

import { Header } from '../components/layout/header'

export const PhysicianPage = () => {
  return (
    <>
      <div className={styles.physician}>
        <Header />
      </div>
    </>
  )
}
