import React from 'react'
import { get } from 'lodash'

import styles from './styles.module.css'
import { Dropdown } from 'shared/components'

export const FormikSelectDateOfBirthday = ({
  formik,
  name,
  label,
  required,
  className,
  fieldErrors,
}) => {
  const errorMessage = get(fieldErrors, name) || get(formik.errors, name)

  const months = [
    { key: 1, text: 'January' },
    { key: 2, text: 'February' },
    { key: 3, text: 'March' },
    { key: 4, text: 'April' },
    { key: 5, text: 'May' },
    { key: 6, text: 'June' },
    { key: 7, text: 'July' },
    { key: 8, text: 'August' },
    { key: 9, text: 'September' },
    { key: 10, text: 'October' },
    { key: 11, text: 'November' },
    { key: 12, text: 'December' },
  ]
  const currentYear = new Date().getFullYear()
  const years = Array.from({ length: 100 }, (_, i) => ({
    key: currentYear - i - 18,
    text: (currentYear - i - 18).toString(),
  }))

  const daysCount = new Date(formik.values.year, formik.values.month, 0).getDate()
  const days = Array.from({ length: daysCount || 31 }, (_, i) => ({
    key: i + 1,
    text: (i + 1).toString(),
  }))

  return (
    <div className={`${styles.wrapper} ${className ?? ''}`}>
      {label && (
        <label htmlFor={name}>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <div className={styles.date}>
        <Dropdown
          name="month"
          label="Month"
          required
          options={months}
          value={formik.values.month}
          errorMessage={fieldErrors?.month || formik.errors?.month}
          onChange={(value) => formik.setFieldValue('month', value)}
        />
        <Dropdown
          name="day"
          label="Day"
          required
          options={days}
          value={formik.values.day}
          errorMessage={fieldErrors?.day || formik.errors?.day}
          onChange={(value) => formik.setFieldValue('day', value)}
        />
        <Dropdown
          name="year"
          label="Year"
          required
          options={years}
          value={formik.values.year}
          errorMessage={fieldErrors?.year || formik.errors?.year}
          onChange={(value) => formik.setFieldValue('year', value)}
        />
      </div>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </div>
  )
}
