import React from 'react'

import styles from './badge.module.css'

export const Badge = ({ children, amount }) => {
  return (
    <div className={styles.wrapper}>
      {amount ? <div className={styles.badge}>{amount}</div> : null}
      {children}
    </div>
  )
}
