import React from 'react'
export const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)
  React.useEffect(() => {
    const script = document.createElement('script')

    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    script.async = true

    script.onload = () => {
      if (window.Trustpilot) {
        const el = document.getElementById('trustbox')
        window.Trustpilot.loadFromElement(el, true)
      }
    }

    document.body.appendChild(script)
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
  }, [])
  return (
    <div
      id="trustbox"
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget" // Renamed this to className.
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id="59c009160000ff0005ab75ea"
      data-locale="en-GB"
      // [ long list of data attributes...]
    >
      <a
        href="https://www.trustpilot.com/review/gogomeds.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        {' '}
        Trustpilot
      </a>
    </div>
  )
}
