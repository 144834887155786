import React, { useMemo } from 'react'
import { isEmpty, size } from 'lodash'

import { useIndexMyPaymentMethods } from 'shared/queries/paymentMethod'
import { useReadProduct } from 'shared/queries/product'
import { useIndexActivePackOffers } from 'shared/queries/packOffer'
import { USDollar } from 'shared/utils/currenciesFormatters'
import { getCardBrand } from 'shared/utils/paymentBrands'

import styles from './billingCards.module.css'
import { PaymentMethodModal } from './changePaymentMethodModal'
import { Button, Tooltip, useDisclosure } from '@chakra-ui/react'

export const BillingCards = ({
  subscription,
  orders,
  profile,
  refetchSubscriptions,
  setActivePaymentMethod,
}) => {
  const {
    isOpen: isOpenChangeCardModal,
    onOpen: onOpenChangeCardModal,
    onClose: onCloseChangeCardModal,
  } = useDisclosure()
  const { id, totalPriceInCents, paymentMethodId } = subscription
  const {
    data: paymentMethods,
    isLoading: isPaymentMethodLoading,
    refetch: refetchPaymentMethods,
  } = useIndexMyPaymentMethods({
    status: 'ACTIVE',
  })

  const productId = subscription.items?.[0]?.productId
  const packOfferId = subscription.packOfferIds?.[0]
  const { data: product, isLoading: isProductLoading } = useReadProduct(
    {
      id: productId,
    },
    !packOfferId && !!productId
  )
  const { data: packOffers = [], isLoading: isPackOffersLoading } = useIndexActivePackOffers()
  const packOffer = useMemo(() => {
    if (isEmpty(packOffers) || !packOfferId) {
      return
    }
    return packOffers.find(({ id }) => id === packOfferId)
  }, [packOffers, packOfferId])

  const paymentMethod = useMemo(() => {
    if (!paymentMethodId || isPaymentMethodLoading || !paymentMethods) {
      return {}
    }
    const paymentMethod = paymentMethods.find((item) => item.id === paymentMethodId)
    setActivePaymentMethod(paymentMethod?.referenceId)
    return paymentMethod
  }, [paymentMethods, paymentMethodId, isPaymentMethodLoading])
  const currentPlan = useMemo(() => {
    if (isProductLoading || isPackOffersLoading || (!product && !packOffer)) {
      return
    }
    const ordersCount = size(orders) - 1

    return {
      planName: packOffer ? packOffer.title : product.title,
      billingCycle: `${
        packOffer ? packOffer.subscriptionRecurringInDays : product.subscriptionRecurringInDays
      } days`,
      cost: totalPriceInCents,
      progress: {
        now: (ordersCount % 12) + 1,
        max: 12,
      },
    }
  }, [product, isProductLoading, packOffer, isPackOffersLoading])

  const isLastOrderPadding = orders[size(orders) - 1].status === 'PENDING'

  if (isEmpty(currentPlan)) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.card_wrapper}>
        <div className={styles.head}>
          <p className={styles.label}>Current Plan Summary</p>
        </div>
        <div className={styles.body}>
          <div className={styles.summary_table}>
            <div>
              <p className={styles.text_p}>PLAN NAME</p>
              <span className={styles.text_span}>{currentPlan.planName}</span>
            </div>
            <div>
              <p className={styles.text_p}>BILLING CYCLE</p>
              <span className={styles.text_span}>{currentPlan.billingCycle}</span>
            </div>
            <div>
              <p className={styles.text_p}>PLAN COST</p>
              <span className={styles.text_span}>{USDollar.format(currentPlan.cost / 100)}</span>
            </div>
          </div>
          <div style={{ marginTop: '20px' }}>
            <p className={styles.text_p}>STAGE</p>
            <span className={styles.text_span}>
              Cycle {currentPlan.progress.now} of {currentPlan.progress.max}
            </span>
          </div>
          <div style={{ width: '100%', paddingRight: '17px', marginTop: '10px' }}>
            <div className={styles.progress_bar_wrapper}>
              <div
                className={styles.progress}
                style={{
                  width: `${100 * (1 - currentPlan.progress.now / currentPlan.progress.max)}%`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.card_wrapper}>
        <div className={styles.head}>
          <p className={styles.label}>Payment Method</p>
        </div>
        <div className={styles.body}>
          <Tooltip
            label={`You can't change Payment Method until invoice status is Pending`}
            placement="bottom-end"
            hasArrow
            px={2}
            py={1}
            bg={'var(--secondary-gray)'}
            rounded={8}
            fontSize={'lg'}
            fontWeight="medium"
            isDisabled={!isLastOrderPadding}
          >
            <div className={styles.payment_method_wrapper}>
              {!isEmpty(paymentMethod) && (
                <>
                  <img
                    src={getCardBrand(paymentMethod.brand).icon}
                    alt={'Card Logo'}
                    className={styles.card_logo}
                  />
                  <div className={styles.col}>
                    <p>{getCardBrand(paymentMethod.brand).name}</p>
                    <p>**** **** **** {paymentMethod.cardNumberMask}</p>
                    <span>
                      Expiry on {paymentMethod.expMonth}/{paymentMethod.expYear}
                    </span>
                  </div>
                  <Button
                    className={styles.change_button}
                    onClick={onOpenChangeCardModal}
                    isDisabled={isLastOrderPadding}
                  >
                    Change
                  </Button>
                </>
              )}
            </div>
          </Tooltip>
        </div>
        <PaymentMethodModal
          isOpenChangeCardModal={isOpenChangeCardModal}
          onCloseChangeCardModal={onCloseChangeCardModal}
          onOpenChangeCardModal={onOpenChangeCardModal}
          subscriptionId={id}
          paymentMethod={paymentMethod}
          paymentMethods={paymentMethods}
          refetchPaymentMethods={refetchPaymentMethods}
          refetchSubscriptions={refetchSubscriptions}
          profile={profile}
        />
      </div>
    </div>
  )
}
