import React from 'react'
import styles from './styles.module.css'
import { Label } from '../../components/layout/label'

import Girl from 'shared/images/girl.png'
import { useNavigate } from 'react-router-dom'
import { APP_PATHS } from '../../paths'

export const ScheduleResult = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.wrapper}>
      <Label>Your Appointment</Label>
      <div className={styles.wrapper_data}>
        <img src={Girl} alt={'Girl'} />
        <p>
          You have a pending appointment at 14:30 PM MST on Saturday, May 20th with{' '}
          <span>Dr Lorem Ipsum</span>, FNP
        </p>
      </div>
      <div className={styles.buttons}>
        <button className={styles.back} onClick={() => navigate(-1)}>
          Back
        </button>
        <button className={styles.continue} onClick={() => navigate(APP_PATHS.scheduleCalendar)}>
          Confirm
        </button>
      </div>
    </div>
  )
}
