import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'

export const useSetUserProfileMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/setUserProfile', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useCompleteUserProfileMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/completeUserProfile', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}
