import React from 'react'
export const MoneySvg = ({ ...args }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      {...args}
    >
      <path
        d="M11.8432 23.0975C17.8318 23.0975 22.6864 18.3616 22.6864 12.5195C22.6864 6.67736 17.8318 1.94141 11.8432 1.94141C5.85467 1.94141 1 6.67736 1 12.5195C1 18.3616 5.85467 23.0975 11.8432 23.0975Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M11.8438 4.9292V20.1097"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="100"
      />
      <path
        d="M15.5938 9.86556C15.5938 8.36848 13.9125 7.1543 11.8403 7.1543C9.76813 7.1543 8.08691 8.36848 8.08691 9.86556C8.08691 11.3626 9.70297 12.0937 11.8403 12.5768C13.8995 13.0218 15.5938 13.791 15.5938 15.2881C15.5938 16.7852 13.9125 17.9993 11.8403 17.9993C9.76813 17.9993 8.08691 16.7852 8.08691 15.2881"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="100"
      />
    </svg>
  )
}
