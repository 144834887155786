import React from 'react'
import PropTypes from 'prop-types'

import { FormikInput } from '../../../components/elements/input'
import { Dropdown } from '../../../shared/components'
import { States } from '../../../shared/constants/states'
import { FormikSelectDateOfBirthday } from 'components/elements/select/date'
import { Box, Grid, GridItem, Text } from '@chakra-ui/react'

export const AddressStep = ({ formik, fieldErrors, myState }) => (
  <>
    <FormikInput
      name="address.streetAddress"
      label="Street Address"
      placeholder="Street"
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
      color="white"
    />
    <FormikInput
      name="address.houseNo"
      label="Apt/House No"
      placeholder="No."
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
      color="white"
    />
    <FormikInput
      name="address.addressLocality"
      label="City"
      placeholder="City"
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
      color="white"
    />
    <Grid templateColumns="repeat(2, 1fr)" gap={6} w="100%">
      <GridItem w="100%">
        <Dropdown
          name="address.addressRegion"
          label="State"
          showLabel
          required
          disabled={myState?.addressRegion}
          options={States}
          value={formik.values.address.addressRegion}
          errorMessage={
            fieldErrors?.address?.addressRegion || formik.errors?.address?.addressRegion
          }
          onChange={(value) => formik.setFieldValue('address.addressRegion', value)}
        />
      </GridItem>
      <GridItem w="100%">
        <FormikInput
          name="address.postalCode"
          label="Zip"
          placeholder="Zip Code"
          required
          formik={formik}
          errors={fieldErrors}
          color="white"
        />
      </GridItem>
    </Grid>
    <Box w="100%">
      <FormikSelectDateOfBirthday
        name="birthDate"
        label="Date of Birth"
        required
        formik={formik}
        fieldErrors={fieldErrors}
      />
    </Box>
    <Box mt={12} textAlign="start" fontSize="12px" lineHeight={'normal'}>
      <Text fontWeight="bold" fontStyle={'italic'}>
        Why do we need to your date of birth?
      </Text>
      <Text>We are not able to sell prescription medication to people under the age of 18.</Text>
    </Box>
  </>
)

AddressStep.propTypes = {
  formik: PropTypes.object.isRequired,
}
