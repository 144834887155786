import React, { useCallback, useState } from 'react'
import { useProfileTile } from '../../hooks/useProfileTile/useProfileTile'
import { Button } from 'shared/components'
import styles from './styles.module.css'
import { TilesMap } from './tilesMap'
import CloseIcon from 'shared/icons/misc/closeIcon.svg'
import { last, size } from 'lodash'

export const ProfileTiles = ({
  itemWrapperClassname = '',
  days,
  mood,
  profile,
  weights,
  available,
}) => {
  const { items, addNew, hasMore, availableItems, removeTile } = useProfileTile()
  const [isAddOpen, setIsAddOpen] = useState(false)
  const { initialWeight, targetWeight, initialHeight } = profile
  const initialHeightSquare = (initialHeight / 100) * (initialHeight / 100) ?? 1
  const bmiList = weights.map(({ value }) => +value / initialHeightSquare)
  const initialBMI = initialWeight / initialHeightSquare
  const targetBMI = targetWeight / initialHeightSquare

  let previousBMI = 0
  const latestBMI = size(bmiList) > 0 ? last(bmiList) : 0
  if (size(bmiList) > 1) {
    previousBMI = bmiList[bmiList.length - 2]
  }
  const latestPercentage = latestBMI ? (initialBMI - latestBMI) / (initialBMI - targetBMI) : 0
  const previousPercentage = previousBMI
    ? (initialBMI - previousBMI) / (initialBMI - targetBMI)
    : 0
  const addTile = useCallback((type) => addNew(type), [addNew])
  return (
    <>
      {items.map((item) => (
        <div
          className={`${itemWrapperClassname} ${styles.wrapper} ${
            item === 'mood' ? styles.mood_wrapper : ''
          }`}
          key={item}
        >
          <>
            {TilesMap[item]({
              isAble: true,
              days,
              mood,
              available,
              chart: {
                name: 'BMI',
                amount: Math.floor(latestPercentage * 100),
                percent: Math.floor((latestPercentage - previousPercentage) * 100),
                direction: initialBMI - latestBMI > 0 ? 'down' : 'up',
              },
            })}
          </>
          <img
            src={CloseIcon}
            onClick={() => removeTile(item)}
            alt={'Remove icon'}
            className={styles.close_icon}
          />
        </div>
      ))}
      {hasMore && (
        <>
          {isAddOpen && (
            <>
              {availableItems.map((item) => (
                <Button variant={'grey'} key={item} onClick={() => addTile(item)}>
                  {item}
                </Button>
              ))}
            </>
          )}
          <Button onClick={() => setIsAddOpen((prevState) => !prevState)}>
            {isAddOpen ? 'Close' : 'Add Tile'}
          </Button>
        </>
      )}
    </>
  )
}
