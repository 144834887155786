import React from 'react'
import { Box, Flex, HStack, Image, Spacer, Text, VStack } from '@chakra-ui/react'

import { USDollar } from '../../utils/currenciesFormatters'
import { getCardBrand } from '../../utils/paymentBrands'

import CardChip from 'shared/icons/misc/cardChipGold.png'
import CardArrow from 'shared/icons/misc/CreditCardArrow.svg'

export const CardSkeleton = ({
  totalPriceInCents,
  mask,
  saved = false,
  brand = 'mastercard',
  expMonth,
  expYear,
}) => {
  const cardIcon = getCardBrand(brand)
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
      background="linear-gradient(180deg, rgba(0, 0, 0, 0) 45.66%, rgba(23, 23, 23, 0.65) 100%), 
    radial-gradient(57.36% 125.58% at 95.53% 6.23%, #4b4b4b 0%, #171717 100%)"
      borderRadius="17px"
      maxWidth="420px"
      padding={{ base: '15px 20px', md: '30px 40px' }}
      aspectRatio="85.6 / 53.98"
      color="white"
      fontWeight="400"
      position="relative"
    >
      <Image
        src={cardIcon.iconLight}
        alt="Card icon"
        maxW={'60px'}
        maxH={'30px'}
        top="30px"
        right="20px"
        position="absolute"
      />
      <Flex justify="space-between" align="flex-end" mb={4}>
        <VStack align="flex-start" spacing={4}>
          <Text
            fontSize={{ base: '16px', md: '20px' }}
            fontWeight="400"
            fontFamily={'Barlow Condensed, sans-serif'}
          >
            Credit Card
          </Text>
          <Image src={CardChip} alt="Card Chip" w={'57px'} h={'42px'} />
        </VStack>
        <Spacer />
        <VStack align="flex-end" justifyContent="space-between">
          <Spacer />
          <Text
            fontSize={{ base: '16px', md: '24px' }}
            fontWeight="400"
            fontFamily={'Barlow Condensed, sans-serif'}
          >
            {saved ? 'Saved Card' : 'Please add payment card'}
          </Text>
        </VStack>
      </Flex>

      <HStack
        fontSize={{ base: '28px', md: '32px' }}
        fontWeight="600"
        mb={2}
        pr={4}
        spacing={1.5}
        fontFamily={'Kredit Front, sans-serif'}
      >
        {mask ? (
          <>
            <Text as="span">****</Text>
            <Spacer />
            <Text as="span">****</Text>
            <Spacer />
            <Text as="span">****</Text>
            <Spacer />
            <Text as="span">{mask}</Text>
          </>
        ) : (
          <>
            <Text as="span">1234</Text>
            <Spacer />
            <Text as="span">5678</Text>
            <Spacer />
            <Text as="span">9012</Text>
            <Spacer />
            <Text as="span">3456</Text>
          </>
        )}
      </HStack>

      <Flex justify="space-between" alignItems={'flex-end'} pr={4}>
        <Box>
          {totalPriceInCents && (
            <Text
              fontSize={{ base: '28px', md: '32px' }}
              fontWeight="400"
              fontFamily={'Barlow Condensed, sans-serif'}
            >
              {USDollar.format(totalPriceInCents / 100)}
            </Text>
          )}
        </Box>
        <HStack textAlign="right" mb="6">
          <VStack
            fontSize={{ base: '7px', md: '9px' }}
            lineHeight="0.4"
            fontFamily={'Barlow Condensed, sans-serif'}
          >
            <Text>VALID</Text>
            <Text>THRU</Text>
          </VStack>
          <Image src={CardArrow} alt="Card Icon" mr={2} />

          <Text
            fontSize={{ base: '18px', md: '24px' }}
            fontFamily={'Kredit Front, sans-serif'}
            fontWeight="600"
          >
            {!saved ? (
              <span>00/00</span>
            ) : (
              <span>
                {expMonth}/{expYear % 100}
              </span>
            )}
          </Text>
        </HStack>
      </Flex>
    </Flex>
  )
}
