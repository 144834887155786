import { useCallback, useMemo, useState } from 'react'

export const useFormSave = ({ initialData } = { updateOrSave: 'save' }) => {
  const [formCache, setFormCache] = useState(initialData)

  const onSubmit = useCallback((data) => {
    setFormCache((prevState) => ({ ...prevState, ...data }))
  }, [])

  const finish = useCallback(
    (overrideData) => {
      //const operation = updateOrSave === 'update' ? 'update' : 'create'
      return {
        ...formCache,
        ...(overrideData ? overrideData : {}),
      }
    },
    [formCache]
  )

  return useMemo(() => ({ onSubmit, finish, formCache }), [onSubmit, finish, formCache])
}
