import React, { useState } from 'react'

import styles from './styles.module.css'

import ArrowDown from 'shared/icons/misc/dropdownArrow.svg'

export const Dropdown = ({
  label,
  options,
  value,
  onChange,
  required,
  showLabel,
  errorMessage,
  disabled = false,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false)

  const appear = () => {
    if (!disabled) {
      setIsActive((_isActive) => !_isActive)
    }
  }

  const disAppear = () => {
    if (isActive) setIsActive(false)
  }

  const handleChange = (newValue) => {
    onChange(newValue)
    disAppear()
  }
  const selectedItem = options.find((el) => el.key === value)
  return (
    <div className={styles.wrapper} onMouseLeave={disAppear} {...rest}>
      {showLabel && (
        <label htmlFor={name}>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <div
        className={`${styles.label} ${disabled ? styles.disabled_list_item : ''}`}
        onClick={appear}
      >
        <p style={{ whiteSpace: 'pre' }}>{selectedItem ? selectedItem.text : label}</p>
        <img
          src={ArrowDown}
          alt={'ArrowDown'}
          className={`${styles.arrow} ${isActive ? styles.arrow_rotate : {}}`}
          width={41}
          height={41}
        />
      </div>
      {isActive && (
        <div className={styles.list}>
          {options.map((el) => (
            <div
              className={`${styles.list_item} ${el.disabled ? styles.disabled_list_item : ''}`}
              key={el.key}
              onClick={() => handleChange(el.key)}
            >
              {el.logo ? <img src={el.logo} alt={`${el.text} logo`} /> : null}
              <span className={`${el.key === value ? styles.active : ''}`}>{el.text}</span>
            </div>
          ))}
        </div>
      )}
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </div>
  )
}
