import React from 'react'
import { Link as ChakraLink, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { APP_PATHS } from 'paths'

import CreditCardError from 'shared/icons/misc/creditCardError.svg'
import { Label } from 'components/layout/label'
import styles from './styles.module.css'

export const SubscriptionDisabled = () => {
  return (
    <div className={styles.wrapper}>
      <Label>Confirmation</Label>
      <div className={styles.wrapper_payment}>
        <div className={styles.wrapper_icon}>
          <img color="white" src={CreditCardError} alt="Subscription" />
        </div>
        <p className={styles.payment_label}>Subscription Info!</p>
        <p className={styles.payment_body}>
          <Text>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            You can't start new subscription because you already have active subscription. Please
            review active subscription{' '}
            <ChakraLink
              color={'var(--help-color)'}
              className={styles.href}
              href={APP_PATHS.myProfileBillingPage}
            >
              here
            </ChakraLink>{' '}
            or you can cancel it{' '}
            <Link className={styles.href} to={APP_PATHS.myProfileReturnsPage}>
              here
            </Link>
            .
          </Text>
        </p>
      </div>
    </div>
  )
}
