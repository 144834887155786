import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Text,
  Button,
  Link as ChakraLink,
  VStack,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react'
import { Label } from 'components/layout/label'
import { APP_PATHS } from 'paths'
import { RiMoneyDollarCircleLine } from 'react-icons/ri'

export const RefundMyOrderAndCancelSubscriptionComplete = ({ orderId }) => {
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery('(max-width: 800px)')

  return (
    <Box
      p={8}
      maxW="800px"
      mx="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="30px"
      padding="7% 8% 60px 8%"
    >
      <Label>Confirmation</Label>
      <VStack
        spacing={6}
        textAlign="center"
        width={isMobile ? 'auto' : '400px'}
        fontSize={'16px'}
        fontWeight={'400'}
      >
        <Box bg="var(--accent)" p={6} borderRadius="20px">
          <Icon as={RiMoneyDollarCircleLine} boxSize={10} color={'white'} p={0} m={0} />
        </Box>
        <Text fontSize="20px" fontWeight="bold">
          Your order and subscription was canceled.
        </Text>
        <Text fontSize="20px" fontWeight="bold">
          Order ID:{' '}
          <Text as="span" color={'var(--accent)'}>
            {orderId}
          </Text>
        </Text>
        <Text fontSize="20px" fontWeight="bold">
          Money will be returned to the card used for payment.
        </Text>
        <Button
          colorScheme="pink"
          bg="var(--accent)"
          width="160px"
          borderRadius="10px"
          fontWeight="400"
          onClick={() => navigate(APP_PATHS.myProfile)}
        >
          Go to profile
        </Button>

        <Text>
          If you have any questions or feedback, please contact us via email{' '}
          <ChakraLink
            color="var(--help-color)"
            fontWeight="600"
            href="mailto:help@maion.ai"
            textDecor={'underline'}
          >
            help@maion.ai
          </ChakraLink>
        </Text>
      </VStack>
    </Box>
  )
}
