import React from 'react'
import { Box, Text, Image, VStack, Link as ChakraLink, Flex } from '@chakra-ui/react'
import { Label } from 'components/layout/label'

import CreditCardChecked from 'shared/icons/misc/creditCardChecked.svg'
import InfoIcon from 'shared/icons/misc/info.svg'
import { getLastSymbols } from 'shared/constants/helpers'
import { Link } from 'react-router-dom'
import { APP_PATHS } from 'paths'

export const InitialPrescriptionFailed = ({ message, orderId }) => {
  return (
    <Box
      p={8}
      mx="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="30px"
      padding="7% 8% 60px 8%"
    >
      <Label>Confirmation</Label>
      <VStack spacing={6} textAlign="center" maxW="800px">
        <Flex
          bg="var(--gray-text)"
          borderRadius="20px"
          boxSize={'85px'}
          justifyContent={'center'}
        >
          <Image src={CreditCardChecked} alt="Prescription error" />
        </Flex>
        <Text fontSize="32px" fontWeight="bold">
          Medical intake questionary failed.
        </Text>
        <Text fontSize="24px" color="var(--gray-text)" fontWeight="bold">
          We could not send your data for initial subscription.
        </Text>
        <Text fontSize="24px" color="var(--gray-text)" fontWeight="bold">
          {message}
        </Text>
        <Text fontWeight="600">
          Your order ID is:{' '}
          <Text as="span" color={'var(--accent)'}>
            {getLastSymbols(orderId, 8)}
          </Text>
        </Text>

        <Image src={InfoIcon} alt="Info" />
        <Text fontSize="24px" fontWeight="bold">
          Do not worry 😉
        </Text>

        <Text fontSize="14px" color="var(--gray-text)" fontWeight="bold">
          Sometimes it happens. Please go to{' '}
          <ChakraLink as={Link} to={APP_PATHS.myProfileBillingPage} color="var(--help-color)">
            Billing & Payment Details
          </ChakraLink>{' '}
          page and check your Invoice or{' '}
          <ChakraLink href="mailto:help@maion.ai" color="var(--help-color)">
            contact us
          </ChakraLink>
          .
        </Text>
      </VStack>
    </Box>
  )
}
