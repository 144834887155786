import React from 'react'

const colorMap = {
  top: {
    arrow: '#007400',
    bg: '#C3FCBB',
  },
  bottom: {
    arrow: '#740000',
    bg: '#fcbbbb',
  },
}

/**
 * @param {'top' | 'bottom'} direction
 * **/
export const DirectionArrow = ({ direction }) => {
  const { arrow, bg } = colorMap[direction]
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      style={direction === 'bottom' ? { transform: 'rotate(180deg)', zIndex: 2 } : { zIndex: 2 }}
    >
      <rect
        x="25.0312"
        y="24.9331"
        width="24.8029"
        height="24.8029"
        rx="12.4014"
        transform="rotate(-180 25.0312 24.9331)"
        fill={bg}
      />
      <path
        d="M12.6315 7.21737L12.6315 17.8472M12.6315 17.8472L17.0605 13.0638M12.6315 17.8472L8.20239 13.0638"
        stroke={arrow}
        strokeWidth="1.77163"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
