import React, { useState } from 'react'

import styles from './styles.module.css'
import { Label } from 'components/layout/label'
import { Modal } from '../../features/modal'
import { TreatmentProductItemsItems } from '../treatments/TreatmentPlans'

import BusinessImage from 'shared/images/whyMaion/business.png'
import CustomersImage from 'shared/images/whyMaion/customers.png'
import ProductsImage from 'shared/images/whyMaion/products.png'
import { ImageCard } from 'shared/components/ImageCard/ImageCard'
import { useIndexServiceConfig } from 'shared/queries/serviceConfig'

const items = (open, statesAmount) => [
  {
    image: CustomersImage,
    text: 'Our Customers',
    subItems: [
      'We make sure there is a healthcare professional on hand for your entire journey.',
      'We believe the customer is always right which is why we adopt a ‘cancel any time’ policy.',
      'We provide unlimited weight related physician interactions.',
      'We provide a health care profession for support  at each dosage increase at no additional cost.',
      'We have an unrivalled frictionless consultation process.',
    ],
  },
  {
    image: ProductsImage,
    text: 'Our Products',
    subItems: [
      'We offer an accelerated physician review, prescribing and dispatch service, typically delivering to your door within 72hrs of your initial intake questionnaire.',
      'Our compound products are prepared in a sterile facility aligning to USP797 standards.',
      'Our compound products are sent for testing to an independent lab before being delivered to you.',
      <span key={'openStatesModal'}>
        Our products are available in{' '}
        <span className={styles.click} onClick={open}>
          {statesAmount} states
        </span>
        .
      </span>,
      'Our AI powered adherence platform is designed to help you at every step of your journey and is free at the point of use.',
    ],
  },
  {
    image: BusinessImage,
    text: 'Our Business',
    subItems: [
      'Our pharmacy partner has 37 years of sterile pharmacy expertise and experience.',
      'Our pharmacy partners are members of the Professional Compounding Centers of America (PCCA).',
      'Our pharmacy partners are NABP® affiliated sterile pharmacy facility and provider which adheres to strict US Pharmacopeia standards and product testing (USP).',
      'Our Founders and consultants have worked directly with government bodies and the FDA.',
      'Our team has health care experience in 3 continents across the globe.',
      'Our Founders have first hand experience of the obesity to weight loss journey. We are like you!',
    ],
  },
]

export const Page = () => {
  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
  const openStatesShareModal = () => setStatesModalIsOpen(true)
  const closeStatesShareModal = () => setStatesModalIsOpen(false)
  const { data: serviceConfig = [{ availabelStates: [] }] } = useIndexServiceConfig()

  const statesAmount = serviceConfig[0].availabelStates?.length ?? 0

  return (
    <div>
      <div className={styles.paddings} style={{ paddingBottom: '50px' }}>
        <Label subTitle={'Care and quality are central to everything we do'}>Why Maion</Label>
        <div className={styles.wrapper}>
          {items(openStatesShareModal, statesAmount).map((item, index) => (
            <div key={index}>
              <ImageCard {...item} />
              <ul className={styles.subItems}>
                {item.subItems.map((subItem) => (
                  <li key={subItem}>
                    <LiMarker />
                    <span>{subItem}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <TreatmentProductItemsItems style={{ paddingTop: '50px', paddingBottom: '50px' }} />
      <div
        className={`${styles.bottom_items} ${styles.paddings}`}
        style={{ paddingTop: '75px' }}
      >
        <div className={styles.item}>
          <p className={styles.lable}>Why We Care</p>
          <div className={styles.cols}>
            <span className={styles.texts}>
              We set up Maion because we saw the need for a weight loss product that was both
              safe and affordable. Collectively we have all suffered from obesity or have seen
              love ones around us struggling to deal with their weight problem. Our aim is not to
              just work within FDA guidelines but to redefine and demonstrate what is considered
              good practice. We welcome any conversation with customers who are looking for
              something better or qualified pharmacists who are striving to do things better.
            </span>
            <div className={styles.texts}>
              We know that medication alone is not the solution, that is why we are investing
              over 50% of our revenue in a smart, intelligent, individualized adherence app that
              will be there for you 24/7. <span>Watch this space!</span>
            </div>
          </div>
        </div>
        <div className={styles.item}>
          <p className={styles.lable}>For Compounds</p>
          <div className={styles.cols}>
            <span className={styles.texts}>
              Simply put, at Maion we aim to reset the consumers view of compound pharmacies and
              the value, both in cost and expertise, that they can bring to the everyday US
              customers.
            </span>
            <div />
          </div>
        </div>
      </div>
      <Modal modalIsOpen={statesModalIsOpen} close={closeStatesShareModal} type={'states'} />
    </div>
  )
}

const LiMarker = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6" r="5" stroke="currentColor" strokeWidth="2" />
  </svg>
)
