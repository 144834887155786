import React from 'react'

import Medicine from 'shared/images/mainPageBanners/medicine.png'
import Medicine2 from 'shared/images/mainPageBanners/medicine2.png'
import Women1 from 'shared/images/mainPageBanners/women1.png'

import styles from './styles.module.css'
import { linkify } from 'shared/utils/linkify'

const items = [
  {
    textColor: 'white',
    image: Medicine,
    topText: undefined,
    aplyTextStyles: {
      fontSize: '23px !important',
    },
    bottomText:
      'Our Semaglutide medication has the same active ingredient as <b>Wegovy®</b> and <b>Ozempic®</b>',
  },
  {
    textColor: 'var(--accent)',
    image: Women1,
    topText: '20%',
    bottomText: 'Up to 20% weight loss in a 68 week period.¹',
  },
  {
    textColor: 'white',
    image: Medicine2,
    aplyTextStyles: {
      width: '50%',
    },
    bottomText:
      'Patients using prefilled syringes reported higher satisfaction and preference compared to those using vials and syringes.²',
  },
]

const noticeItems = [
  '1. www.uclahealth.org/news/semaglutide-weight-loss-what-you-need-know',
  '2. Contact Maion for study details.',
]

export const Banners = () => {
  return (
    <>
      <div className={styles.wrapper}>
        {items.map((item, index) => (
          <Item {...item} key={index} />
        ))}
      </div>
      <div className={styles.notice_wrapper}>
        {noticeItems.map((i, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: linkify(i, i.slice(3)) }} />
        ))}
      </div>
    </>
  )
}

const Item = ({ image, textColor, topText, bottomText, aplyTextStyles }) => {
  return (
    <div
      className={styles.item}
      style={{
        background: `url(${image}) center/cover no-repeat`,
        color: textColor,
      }}
    >
      {!!topText && <p className={styles.top_text}>{topText}</p>}
      {!!bottomText && (
        <p
          className={`${styles.bottom_text} ${
            topText ? styles.bottom_with_top : styles.bottom_without_top
          }`}
          style={aplyTextStyles}
          dangerouslySetInnerHTML={{ __html: bottomText }}
        />
      )}
    </div>
  )
}
