import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  VStack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react'
import ReactGA from 'react-ga4'
import styles from './styles.module.css'
import { Label } from 'components/layout/label'
import { SubscriptionProductSlider } from 'features/treatments'
import { useIndexProducts } from '../../shared/queries/product'
import { useIndexActivePackOffers } from '../../shared/queries/packOffer'
import { DoctorsList } from '../../features/treatments/doctorsList'
import { Services } from '../../features/treatments/Services'

const ModalSelectProduct = ({ isSelectProductOpen, onSelectProductClose }) => {
  const modalSize = useBreakpointValue({ base: 'sm', sm: 'md', md: 'xl', lg: '4xl' })

  return (
    <Modal
      isOpen={isSelectProductOpen}
      onClose={onSelectProductClose}
      size={modalSize}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent
        alignItems={'center'}
        style={{
          background: 'linear-gradient(98deg, #1D96DE 2%, #080267 85%, #9600DA 106%)',
        }}
        borderRadius={'3xl'}
        // h={'475px'}
        pt={{ base: '80px', sm: '90px', md: '100px', lg: '130px' }}
        pb={{ base: '40px', sm: '50px', md: '60px', lg: '80px' }}
      >
        <ModalBody>
          <VStack
            maxW={'600px'}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
            fontWeight={'600'}
            fontSize={{ base: '32px', sm: '36px', md: '40px', lg: '50px' }}
            lineHeight={{ base: '40px', sm: '46px', md: '57px', lg: '62px' }}
            color={'white'}
            spacing={{ base: '8', sm: '10', md: '12', lg: '16' }}
          >
            <Text>
              Please select a product
              <br /> to Sign Up
            </Text>
            <Button
              onClick={() => onSelectProductClose()}
              colorScheme="pink"
              bg={'var(--accent)'}
              borderRadius="12px"
              fontSize={{ base: '20px', md: '24px', lg: '26px' }}
              h={{ base: '46px', md: '56px', lg: '66px' }}
              px={8}
              py={4}
            >
              Close
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const TreatmentProductItemsItems = ({ className = '', style = undefined }) => {
  const { data: products = [], isLoading: isProductsLoading } = useIndexProducts({
    isForIndividualSale: true,
    isDefaultCouponToReturn: true,
  })

  const { data: packOffers = [], isLoading: isPackOffersLoading } = useIndexActivePackOffers({})

  const isLoading = isProductsLoading || isPackOffersLoading
  const productsToSale = [...products]
  for (const packOffer of packOffers) {
    productsToSale.push({
      ...packOffer,
      priceInCents: packOffer.totalPriceInCents,
      isPack: true,
    })
  }
  if (isLoading) {
    return null
  }
  const footnotes =
    productsToSale
      ?.filter(({ footnote }) => !!footnote)
      .map(({ id, footnote }, index) => ({
        id,
        footnote,
        index,
      })) || []
  return (
    <div className={`${styles.paddings} ${className}`} style={style}>
      <Label subTitle={'from a USP797 compliant US, sterile pharmacy'}>Weight loss</Label>
      <SubscriptionProductSlider items={productsToSale} footnotes={footnotes} />
    </div>
  )
}

export const TreatmentPlansPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectPopUp = searchParams.get('selectPopUp')
  const {
    isOpen: isSelectProductOpen,
    onOpen: onSelectProductOpen,
    onClose: onSelectProductClose,
  } = useDisclosure()

  const { data: products = [], isLoading: isProductsLoading } = useIndexProducts({
    isForIndividualSale: true,
  })

  const { data: packOffers = [], isLoading: isPackOffersLoading } = useIndexActivePackOffers({})

  const isLoading = isProductsLoading || isPackOffersLoading
  const productsToSale = [...products]
  for (const packOffer of packOffers) {
    productsToSale.push({
      ...packOffer,
      priceInCents: packOffer.totalPriceInCents,
      isPack: true,
    })
  }

  useEffect(() => {
    if (selectPopUp === 'show') {
      onSelectProductOpen()
      setSearchParams(undefined)
      setTimeout(() => {
        onSelectProductClose()
      }, 2000)
    }
  }, [selectPopUp])

  useEffect(() => {
    if (productsToSale && !isLoading) {
      const productItems = productsToSale.map((item, index) => ({
        item_id: item.id,
        item_name: item.title,
        affiliation: item.medName,
        index: index,
        price: item.priceInCents / 100 || item.totalPriceInCents / 100,
        ndc: item.ndc,
        sku: item.sku,
      }))

      const itemList = {
        items: productItems,
      }
      //https://developers.google.com/tag-platform/gtagjs/reference/events#view_item_list
      ReactGA.event('view_item_list', itemList)
    }
  }, [])

  if (isLoading) {
    return null
  }
  const footnotes =
    productsToSale
      ?.filter(({ footnote }) => !!footnote)
      .map(({ id, footnote }, index) => ({
        id,
        footnote,
        index,
      })) || []
  return (
    <section className={styles.wrapper}>
      <TreatmentProductItemsItems />

      <Services />
      <VStack w="100%" justifyContent="center" p="10px 7% 30px 7%" gap="20px">
        {footnotes.map(({ footnote, index }) => (
          <Text key={index} color="grey" fontSize="14" textAlign="start">
            {'*'.repeat(index + 1)} {footnote}
          </Text>
        ))}
      </VStack>
      <DoctorsList clear={false} className={styles.doctors} />
      <ModalSelectProduct
        isSelectProductOpen={isSelectProductOpen}
        onSelectProductClose={onSelectProductClose}
      />
    </section>
  )
}
