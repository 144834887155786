import React from 'react'
import QRCode from 'react-qr-code'

import styles from './styles.module.css'

export const QrCode = ({ link, loggedIn = false }) => {
  return (
    <div className={loggedIn ? styles.wrapper_logged_in : styles.wrapper}>
      {/*<span className={styles.text}>maion</span>*/}

      <QRCode value={link} title={'maion'} bgColor={'transparent'} fgColor={'white'} />
    </div>
  )
}
