import React, { useEffect } from 'react'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

import styles from './styles.module.css'

import { helix } from 'ldrs'
import { useCreateSessionByOauthCodeMutation } from 'shared/mutations/user'
import { APP_PATHS } from '../../paths'
import { useQueryClient } from 'react-query'
import { sleep } from 'shared/utils/sleep'

helix.register()

export const Page = () => {
  const [params] = useSearchParams()
  const { mutate: login } = useCreateSessionByOauthCodeMutation()
  const navigate = useNavigate()
  const queryCache = useQueryClient()

  const handleLogin = async () => {
    const code = params.get('code')
    const type = params.get('type')

    if (!code || !type) {
      navigate(APP_PATHS.registration)
      return
    }

    await login(
      { code, type: type.toUpperCase() },
      {
        onSuccess: async ({
          token,
          isProfileSetupRequired,
          isPhoneNumberVerificationRequired,
          phoneNumber,
        }) => {
          if (isProfileSetupRequired) {
            const finishRegistrationPath = generatePath(APP_PATHS.finishRegistration, {
              token,
            })
            await navigate(finishRegistrationPath)
          } else if (phoneNumber && isPhoneNumberVerificationRequired) {
            await navigate(generatePath(APP_PATHS.verifyPhoneNumber, { token, phoneNumber }))
          } else {
            localStorage.setItem('AUTH_TOKEN', token)
            await queryCache.refetchQueries()
            await sleep(1000)
            await navigate(APP_PATHS.myProfile)
          }
        },
        onError: async (response) => {
          const { error } = response
          console.log(error)
          await sleep()
          navigate(APP_PATHS.registration)
        },
      }
    )
  }

  useEffect(() => {
    void handleLogin()
  }, [])

  return (
    <div className={styles.col}>
      <l-helix size="150" speed="5" color="var(--accent)"></l-helix>
      <div className={styles.typewriter}>
        <h1>Authenticating you...</h1>
      </div>
    </div>
  )
}
