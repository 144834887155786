import React from 'react'

import { VStack, Text } from '@chakra-ui/react'
import { useMediaQuery } from 'shared/hooks/useMediaQuery'

import styles from './styles.module.css'
import { Label } from 'components/layout/label'

import QuestionariesImage from 'shared/images/howItWorks/questionaries.png'
import GetApprovedImage from 'shared/images/howItWorks/getApproved.png'
import MedicationImage from 'shared/images/howItWorks/med.png'
import KeepOnTrackImage from 'shared/images/howItWorks/keepOnTrack.jpg'
import { ImageCard } from 'shared/components/ImageCard/ImageCard'

import Brain from 'shared/images/howItWorks/semaglutide/brain.png'
import Liver from 'shared/images/howItWorks/semaglutide/liver.png'
import Pancreas from 'shared/images/howItWorks/semaglutide/pancreas.png'
import Stomach from 'shared/images/howItWorks/semaglutide/stomach.png'

import FirstEfBar from 'shared/images/howItWorks/effectivness/first.svg'
import SecondEfBar from 'shared/images/howItWorks/effectivness/second.svg'
import ThirdEfBar from 'shared/images/howItWorks/effectivness/third.svg'

import { TreatmentProductItemsItems } from '../treatments/TreatmentPlans'
import { useIndexProducts } from 'shared/queries/product'
import { useIndexActivePackOffers } from 'shared/queries/packOffer'
import { WistiaVideoSlider } from './components/WistiaVideoSlider/WistiaVideoSlider'

const effectivness = [
  {
    icon: FirstEfBar,
    text: '-10% body weight',
  },
  {
    icon: SecondEfBar,
    text: '-15% body weight',
  },
  {
    icon: ThirdEfBar,
    text: '-20% body weight',
  },
]

const items = [
  {
    image: QuestionariesImage,
    text: 'Complete the medical questionnaire',
    subItems: [
      'Complete our online questionnaire.',
      'I know what you’re thinking, but we’ve tried to make it as short and fun as possible.',
    ],
  },
  {
    image: GetApprovedImage,
    text: 'Get approved',
    subItems: [
      'Get your answers assessed by one of our fully qualified doctors.',
      'Our MDs are very nice and have years of experience helping people with their weight loss journeys.',
    ],
  },
  {
    image: MedicationImage,
    text: 'Receive your medication within 72hr',
    subItems: [
      'Get your medication delivered direct to your door within 72hrs.',
      'As well as your medication, in it’s beautifully packaged and convenient box, we’ll also give you all the instructions for use.',
    ],
  },
  {
    image: KeepOnTrackImage,
    text: 'Use our Maion tools to keep on track',
    subItems: [
      'Keep on track everyday with our help.',
      'Your medication is only part of it. With our online tools we’ll help you keep track of your dosages, progress, and encourage you to keep on track. GO You!',
    ],
  },
]

const humanParts = [
  {
    icon: Brain,
    label: 'Brain',
    text: 'Reduces craving, hunger and the urge to eat.',
  },
  {
    icon: Liver,
    label: 'Liver',
    text: 'Reduces glucose production.',
  },
  {
    icon: Stomach,
    label: 'Stomach',
    text: 'Slows emptying and promotes feeling full.',
  },
  {
    icon: Pancreas,
    label: 'Pancreas',
    text: 'Increasing insulin production.',
  },
]

export const HowItWorksPage = () => {
  const isMobile = useMediaQuery('(max-width: 800px)')

  const { data: products = [], isLoading: isProductsLoading } = useIndexProducts({
    isForIndividualSale: true,
  })

  const { data: packOffers = [], isLoading: isPackOffersLoading } = useIndexActivePackOffers({})

  const isLoading = isProductsLoading || isPackOffersLoading
  const productsToSale = [...products]
  for (const packOffer of packOffers) {
    productsToSale.push({
      ...packOffer,
      priceInCents: packOffer.totalPriceInCents,
      isPack: true,
    })
  }

  const footnotes =
    productsToSale
      ?.filter(({ footnote }) => !!footnote)
      .map(({ id, footnote }, index) => ({
        id,
        footnote,
        index,
      })) || []

  return (
    <div>
      <VStack
        alignItems="flex-start"
        justifyContent="flex-start"
        gap="10px"
        mb={'40px'}
        p={isMobile ? '7% 8% 20px 8%' : '7% 8% 0 8%'}
      >
        <Label subTitle={'Weight loss in 4 easy steps'}>How it all works</Label>
      </VStack>
      <div className={`${styles.wrapper} ${styles.paddings}`}>
        {items.map((item, index) => (
          <div key={index} className={styles.text_wrapper}>
            <ImageCard {...item} />
            <p className={styles.step}>Step {index + 1}</p>
            <span className={styles.label_first}>{item.subItems[0]}</span>
            <span className={styles.label_second}>{item.subItems[1]}</span>
          </div>
        ))}
      </div>
      <WistiaVideoSlider />
      <VStack alignItems="flex-start" justifyContent="flex-start" gap="30px" p="7% 8% 0 8%">
        <p className={styles.label}>How Semaglutide Works</p>
        <div className={styles.bottom_texts}>
          <div>
            <p>GLP-1, GLP-1 Agonists & Semaglutide</p>
            <p>
              <span>What is GLP-1:</span> GLP-1 is a naturally occurring hormone, realised by the
              intestine in response to food intake, that helps to regulate blood sugar levels and
              appetite.
            </p>
            <p>
              <span>What is a GLP-1 receptor agonist:</span> GLP-1 agonists are synthetic
              compounds that mimic the effect of the GLP-1 hormone
            </p>
            <p>
              <span>What is Semaglutide:</span> Semaglutide is a prescription synthetic compound
              GLP-1 agonist
            </p>
          </div>
          <div>
            {humanParts.map((item) => (
              <div key={item.label} className={styles.part}>
                <img src={item.icon} />
                <div className={styles.texts}>
                  <p>{item.label}</p>
                  <span>{item.text}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </VStack>
      <VStack alignItems="flex-start" justifyContent="flex-start" gap="13px" p="7% 8% 0 8%">
        <p className={styles.effectivness_label}>Effectiveness*</p>
        {effectivness.map((ef, index) => (
          <div key={index} className={styles.effectivness}>
            <img src={ef.icon} />
            <span>{ef.text}</span>
          </div>
        ))}
        <span className={styles.effectivness_note}>*The National Institutes of Health</span>
      </VStack>
      {!isLoading && (
        <VStack
          w="100%"
          justifyContent="center"
          p="10px 7% 35px 7%"
          gap="20px"
          marginTop={'75px'}
        >
          {footnotes.map(({ footnote, index }) => (
            <Text key={index} color="grey" fontSize="14" textAlign="start">
              {'*'.repeat(index + 1)} {footnote}
            </Text>
          ))}
        </VStack>
      )}
      <TreatmentProductItemsItems style={{ paddingTop: '25px' }} />
    </div>
  )
}
