// import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'
import { QueriesKeysEnum } from './queries-keys-enum'

export const useIndexProducts = (params = {}, enabled = true) => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.product, params],
    () => axios.get('/indexProducts', { params }),
    {
      retry: false,
      enabled,
      onError: (error) => {
        toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
      },
    }
  )
}

export const useReadProduct = (params = {}, enabled = true) => {
  const toast = useToast()
  return useQuery(
    [QueriesKeysEnum.product, params],
    () => axios.get('/readProduct', { params }),
    {
      retry: false,
      enabled,
      onError: (error) => {
        toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
      },
    }
  )
}
