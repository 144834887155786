import React from 'react'
export const HandsSvg = ({ ...args }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...args}
    >
      <path
        d="M14.1853 0.428711L13.7048 0.954761C12.6287 2.13783 11.7416 2.6668 11.0032 3.01287C10.2649 3.3582 9.64442 3.46137 9.03497 3.92742C8.38523 4.42494 7.44101 5.379 7.04326 7.08226C6.19061 7.2681 5.5665 7.59441 5.07425 8.01949C4.58786 8.43945 4.22161 8.91722 3.77038 9.43741C3.76671 9.44327 3.77257 9.45424 3.77038 9.46009C3.12283 10.2034 2.5566 11.0039 1.45782 11.4267L1 11.6097V19.4287H14.964C15.7646 19.3855 16.3198 18.8317 16.6575 18.2852C16.9945 17.7394 17.1813 17.1482 17.2758 16.6624C17.519 15.4244 17.9621 11.8606 17.9621 11.8606L17.9856 11.7926V11.7238C17.9621 11.2292 17.7585 10.8092 17.5043 10.42L18.3284 7.97413L19.6337 6.00748L20 5.48143L19.5187 5.04756L14.712 0.885987L14.1853 0.428711ZM14.2314 2.44073L18.0537 5.75652L17.0465 7.24176L16.9784 7.31126L16.9549 7.4254L16.2913 9.39132C15.9448 9.24865 15.5881 9.14842 15.1925 9.16305H15.1463L10.5227 9.18573H9.79019V12.0208C9.68764 12.8183 9.24373 13.1271 8.69142 13.3473C8.50829 13.4212 8.48558 13.381 8.32516 13.4153V9.16378C8.20503 6.45158 9.17195 5.67092 9.9279 5.09366C10.1367 4.93343 10.7483 4.74832 11.6215 4.33934C12.3511 3.9962 13.2579 3.38088 14.2314 2.44073ZM6.90628 8.72845C6.90335 8.89454 6.85134 9.0116 6.86013 9.18573V15.0389H7.59264C7.59264 15.0389 8.37644 15.0301 9.2181 14.6957C10.059 14.3614 11.075 13.5785 11.2552 12.2038V10.649L15.1925 10.6263H15.2152C15.5368 10.6087 15.8522 10.7191 16.0925 10.9333C16.3327 11.1475 16.4782 11.4481 16.4971 11.7692C16.4942 11.7809 16.4605 12.0523 16.4517 12.1123H13.4528V13.5756H16.2686C16.1909 14.1411 16.1199 14.496 16.0393 15.0389H13.4528V16.5021H15.8107C15.7242 16.8561 15.5852 17.1952 15.3983 17.5082C15.1984 17.8337 15.0262 17.9566 14.8724 17.9654H2.46503V12.5008C3.65244 11.8716 4.39082 10.9665 4.86842 10.4207C5.3636 9.85445 5.70349 9.42571 6.03605 9.14037C6.2536 8.95453 6.59422 8.84844 6.90628 8.72845Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </svg>
  )
}
