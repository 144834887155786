import React from 'react'
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { FormikInput } from 'components/elements/input'
import { CircleArrowRight } from 'shared/icons/circle-arrow-right'

export const ForgotPasswordModal = ({ email, isOpen, onSubmitEmail, onClose }) => {
  const handleSubmit = (values) => {
    onSubmitEmail(values.email)
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
      autoFocus={false}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box display="flex" alignItems="center">
            <CircleArrowRight
              onClick={onClose}
              transform={'rotate(180deg)'}
              cursor={'pointer'}
              mr="24px"
            />
            <Text variant="h2">Forgot your password?</Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="32px">
          <Formik
            enableReinitialize
            noValidate
            initialValues={{ email }}
            onSubmit={handleSubmit}
            validationSchema={Yup.object({
              email: Yup.string().email().required('Email is required'),
            })}
          >
            {(formik) => (
              <Form noValidate>
                <FormikInput
                  type="email"
                  label="Email"
                  placeholder="john@example.com"
                  name="email"
                  formik={formik}
                  color="white"
                />
                <Button type="submit" colorScheme="pink" mt={3}>
                  Send reset email
                </Button>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
