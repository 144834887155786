import React, { useState } from 'react'
import { Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import { Stack, useToast } from '@chakra-ui/react'

import { Label } from 'components/layout/label'
import { FormikInput, PasswordInput } from 'components/elements/input'
import { Button, Dropdown } from 'shared/components'
import { States } from 'shared/constants/states'
import { CALENDAR_MONTHS_BY_ID } from 'shared/constants/calendarHelpers'
import { useUpdateMyUserProfileMutation } from 'shared/mutations/userProfile'
import { useChangePasswordMutation } from 'shared/mutations/user'

import { Layout } from '../layout'
import styles from '../profile.module.css'
import { UploadAvatar } from '../components/uploadAvatar/UploadAvatar'
import { isEmpty, size } from 'lodash'
import { useMediaQuery } from 'shared/hooks/useMediaQuery'

export const UserPage = () => {
  const [editAddress, setEditAddress] = useState(false)
  const { mutate: changePassword } = useChangePasswordMutation()
  const { mutate: updateProfile } = useUpdateMyUserProfileMutation()
  const toast = useToast()
  const isMobile = useMediaQuery('(max-width: 800px)')

  const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Please Enter your current password'),
    password: Yup.string()
      .required('Please Enter your new password')
      .test('test-regex-groups', 'Bad password', (value) => {
        if (!value) return false

        const regexs = [
          { regex: /(?=.*\d)/, message: 'One Number' },
          { regex: /(?=.*[a-z])/, message: 'One Lowercase' },
          { regex: /(?=.*[A-Z])/, message: 'One Uppercase' },
          {
            regex: /(?=.*[`~!@#$%^&*()\-_=+[{}\]|;:'",<.>/?€£¥₹])/,
            message: 'One Special Case Character',
          },
        ]
        let messages = []
        if (size(value) < 9) {
          messages.push('9 Characters')
        }
        regexs.map(({ regex, message }) => {
          const matches = regex.exec(value)
          if (!matches) messages.push(message)
        })
        if (!isEmpty(messages)) {
          return new Yup.ValidationError(
            'Must Contain ' + messages.join(', '),
            value,
            'password'
          )
        }
        return true
      }),
    confirmPassword: Yup.string()
      .required('Please re-type your new password')
      .oneOf([Yup.ref('password')], 'Passwords does not match'),
  })

  const changePasswordFormik = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values, formikHelpers) => {
      changePassword(
        {
          password: values.oldPassword,
          newPassword: values.password,
        },
        {
          onSuccess: () => {
            formikHelpers.resetForm()
          },
        }
      )
    },
  })

  return (
    <Layout>
      {({ profile, refetchProfile }) => {
        const {
          address = {
            streetAddress: '',
            addressRegion: '',
            addressLocality: '',
            postalCode: '',
          },
          phoneNumber = '',
          firstName = '',
          lastName = '',
          createdAt = Date.now(),
          email = '',
        } = profile || {}
        const joined = new Date(createdAt)
        const fullAddress = `${address?.streetAddress}, ${address?.addressRegion}, ${address?.addressLocality}. ${address?.postalCode}. ${address?.addressCountry}`
        return (
          <section className={`${styles.page_wrapper} ${styles.userPage}`}>
            <Label>User Profile</Label>
            <div className={styles.row}>
              <UploadAvatar profile={profile} refetchProfile={refetchProfile} />
              <div
                className={styles.col}
                style={
                  isMobile
                    ? { width: 'calc(100% - 100px)', alignItems: 'center' }
                    : { width: 'calc(100% - 175px)', alignSelf: 'center' }
                }
              >
                <p className={styles.userPage_username}>
                  {firstName} {lastName}
                </p>
                <p className={styles.userPage_text}>
                  Joined Maion: {CALENDAR_MONTHS_BY_ID[joined.getMonth() + 1]}{' '}
                  {joined.getFullYear()}
                </p>
              </div>
            </div>
            <div className={styles.col}>
              <div className={styles.row}>
                <div
                  className={`${styles.col} ${styles.userPage_text}`}
                  style={{ width: 'auto' }}
                >
                  <h1 className={styles.userPage_label}>Contact:</h1>

                  <span>
                    <label className={styles.userPage_text_accent}>Email:</label> {email}
                  </span>
                  <span>
                    <label className={styles.userPage_text_accent}>Cell:</label> {phoneNumber}
                  </span>
                </div>
                <Button disabled>Update</Button>
              </div>
            </div>
            <div className={styles.col}>
              {editAddress ? (
                <div className={styles.row}>
                  <Formik
                    enableReinitialize
                    initialValues={address}
                    validateOnChange={false}
                    validateOnMount={false}
                    validationSchema={Yup.object().shape({
                      addressLocality: Yup.string().required('City is required'),
                      addressRegion: Yup.string().required('State is required'),
                      postalCode: Yup.string()
                        .matches(/^[0-9]+$/, 'Must be only digits')
                        .min(5, 'Must be exactly 5 digits')
                        .max(5, 'Must be exactly 5 digits')
                        .required('Zip is required'),
                      streetAddress: Yup.string().required('Street Address is required'),
                    })}
                    onSubmit={(values) => {
                      const addressData = {
                        ...values,
                        postalCode: String(values.postalCode),
                      }
                      updateProfile(
                        {
                          ...profile,
                          address: addressData,
                        },
                        {
                          onSuccess: () => {
                            toast({
                              position: 'top-right',
                              status: 'success',
                              title: 'Profile successfully updated',
                              isClosable: true,
                            })
                            setEditAddress(false)
                            refetchProfile()
                          },
                        }
                      )
                    }}
                  >
                    {(formik) => {
                      return (
                        <form
                          onSubmit={formik.handleSubmit}
                          className={styles.wrapper}
                          style={{ padding: 0 }}
                        >
                          <FormikInput
                            name="streetAddress"
                            label="Street Address"
                            required
                            type="text"
                            formik={formik}
                            color="white"
                          />
                          <FormikInput
                            name="addressLocality"
                            label="City"
                            required
                            type="text"
                            formik={formik}
                            color="white"
                          />
                          <Dropdown
                            name="addressRegion"
                            label="State"
                            showLabel
                            required
                            options={States}
                            value={formik.values.addressRegion}
                            errorMessage={formik.errors?.addressRegion}
                            onChange={(value) => formik.setFieldValue('addressRegion', value)}
                          />
                          <FormikInput
                            name="postalCode"
                            label="Zip"
                            required
                            formik={formik}
                            color="white"
                          />
                          <Stack
                            direction="row"
                            sx={{
                              justifyContent: 'flex-end',
                              width: '100%',
                            }}
                          >
                            <Button type="submit" disabled={!formik.isValid}>
                              Update
                            </Button>
                            <Button variant="grey" onClick={() => setEditAddress(false)}>
                              Cancel
                            </Button>
                          </Stack>
                        </form>
                      )
                    }}
                  </Formik>
                </div>
              ) : (
                <div className={styles.row}>
                  <div className={styles.col} style={{ width: 'auto' }}>
                    <h1 className={styles.userPage_label}>Address:</h1>
                    <span className={styles.userPage_text}>{fullAddress}</span>
                  </div>
                  <Button onClick={() => setEditAddress(true)}>Update</Button>
                </div>
              )}
            </div>
            <div className={styles.col}>
              <form
                onSubmit={changePasswordFormik.handleSubmit}
                className={styles.wrapper}
                style={{ padding: 0 }}
              >
                <h1 className={styles.userPage_label}>Change Password:</h1>
                <PasswordInput
                  name="oldPassword"
                  label="Old Password"
                  required
                  formik={changePasswordFormik}
                  color="white"
                />
                <PasswordInput
                  name="password"
                  label="New Password"
                  required
                  formik={changePasswordFormik}
                  color="white"
                />
                <PasswordInput
                  name="confirmPassword"
                  label="Confirm Password"
                  required
                  formik={changePasswordFormik}
                  color="white"
                />
                <Button
                  style={{ alignSelf: 'flex-end' }}
                  disabled={!changePasswordFormik.isValid}
                >
                  Submit
                </Button>
              </form>
            </div>
          </section>
        )
      }}
    </Layout>
  )
}
