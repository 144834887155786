import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { ReactQueryProvider } from 'shared/contexts/react-query-provider'
import { UserContextProvider } from 'shared/contexts/user-context-provider'
import { initializeGa, initializeGTM } from './shared/utils/ga'
import ReactGA from 'react-ga4'
import { environment } from 'environments'
import { HelmetProvider } from 'react-helmet-async'
import { isDevEnv } from 'LoginDev'
// import { theme } from 'libs/ui-components/src'
// <ChakraProvider theme={theme}>

initializeGa()
initializeGTM({ gtmId: environment.GOOGLE_TM_GLOBAL })

ReactGA.initialize(environment.GOOGLE_TM_SUCCESS, {
  gtagUrl: environment.GOOGLE_TM_URL_PREVIEW.includes('maion')
    ? `${environment.GOOGLE_TM_URL_PREVIEW}/gtag/destination`
    : 'https://www.googletagmanager.com/gtag/js',
})
ReactGA.set({
  user_id: localStorage.getItem('USER_ID') || null,
  is_user_login: localStorage.getItem('IS_USER_LOGIN') || false,
})

if (!isDevEnv()) console.log = () => {}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ReactQueryProvider>
          <ChakraProvider>
            <UserContextProvider>
              <App />
            </UserContextProvider>
          </ChakraProvider>
        </ReactQueryProvider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
