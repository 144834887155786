export const environment = {
  production: process.env.REACT_APP_PRODUCTION ?? false,
  BACKEND_URL: process.env.REACT_APP_BACKEND,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  BACKEND_URL_SUFFIX: '/api',
  LOGIN_PAYLOAD: 'Log in to Maion AI',
  SELF_URL: process.env.REACT_APP_SELF_URL ?? 'http://localhost:3000',
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '',
  GA_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  FEATHERY_SDK_KEY: process.env.REACT_APP_FEATHERY_SDK_KEY,
  // GOOGLE_TM_LEAD: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE_LEAD,
  // GOOGLE_TM_PURCHASE: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE_PURCHASE,
  GOOGLE_TM_GLOBAL: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE_GLOBAL,
  GOOGLE_TM_SUCCESS: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE_SUCCESS,
  GOOGLE_TM_SUCCESS_PAYMENT: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE_SUCCESS_PAYMENT,
  GOOGLE_TM_SUCCESS_PAYMENT_CONVERSION:
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE_SUCCESS_PAYMENT_CONVERSION,
  GOOGLE_TM_URL_PREVIEW: process.env.REACT_APP_GOOGLE_TAG_URL_PREVIEW,
  GOOGLE_TM_URL_CONTAINER: process.env.REACT_APP_GOOGLE_TAG_URL_CONTAINER,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  DEV_USERNAME: process.env.REACT_APP_DEV_USERNAME,
  DEV_PASSWORD: process.env.REACT_APP_DEV_PASSWORD,
}
