import React from 'react'

import { useIndexProducts } from 'shared/queries/product'
import { useIndexActivePackOffers } from 'shared/queries/packOffer'

import styles from './styles.module.css'
import { Slideshow } from 'components/widgets'

export const TreatmentsProducts = ({ clear = false, onChange, className = '' }) => {
  const { data: products = [], isLoading: isProductsLoading } = useIndexProducts({
    isForIndividualSale: true,
    isDefaultCouponToReturn: true,
  })

  const { data: packOffers = [], isLoading: isPackOffersLoading } = useIndexActivePackOffers({})

  const isLoading = isProductsLoading || isPackOffersLoading

  const productsToSale = [...products]
  for (const packOffer of packOffers) {
    productsToSale.push({
      ...packOffer,
      priceInCents: packOffer.totalPriceInCents,
      isPack: true,
    })
  }
  if (isLoading) {
    return null
  }
  return (
    <div className={`${styles.wrapper} ${className}`} style={clear ? { padding: 0 } : undefined}>
      <Slideshow products={productsToSale} clear={clear} onChange={onChange} />
    </div>
  )
}
