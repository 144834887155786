import React, { useCallback, useEffect, useRef, useState } from 'react'
import { LogoGradient } from '../logoGradient'
import { PaperFlightSvg } from 'shared/icons/misc/paperFlight'

// import SoundIcon from '../../../shared/icons/misc/sound.svg'
import CloseIcon from 'shared/icons/misc/close.svg'
import OpenIcon from 'shared/icons/misc/arrowChatOpen.svg'

import styles from './styles.module.css'
import { useAskBotMutation } from 'shared/mutations/info'

import { cardio } from 'ldrs'
import { linkify } from 'shared/utils/linkify'
import { useAskBotStore } from 'shared/stores/askBotStore'
import { useLocation } from 'react-router-dom'

cardio.register()

const results = []

export const AskBot = ({ initialClosed = false, closeOnRouteChange = false }) => {
  const location = useLocation()
  const prevPathName = useRef(location.pathname)

  const { currentQuestion: initialQuestion, setCurrentQuestion } = useAskBotStore()
  const ref = useRef()
  const [showResults, setShowResults] = useState(results)
  const [closed, setClosed] = useState(initialClosed)

  const toggle = () => setClosed((prev) => !prev)

  const { mutate: onAskBot } = useAskBotMutation({
    onSuccess: (data) => {
      results.splice(-1, 1, { role: 'assistant', content: data.response })
      const form = document.getElementById('form')
      setShowResults(results)
      form.elements['input'].value = ''
    },
  })

  const submit = useCallback(async (dataSubmit) => {
    dataSubmit.preventDefault()
    const form = document.getElementById('form')
    const value = form.elements['input'].value
    if (value) {
      results.push({ role: 'user', content: value })
      results.push({ role: 'assistant', content: '' })
      setDefaultValue('')
      onAskBot({ messages: results })
    }
  }, [])

  useEffect(() => {
    if (initialQuestion) {
      results.push({ role: 'user', content: initialQuestion })
      results.push({ role: 'assistant', content: '' })
      setDefaultValue('')
      onAskBot({ messages: results })
      setCurrentQuestion(undefined)
    }
  }, [initialQuestion])

  const [defaultValue, setDefaultValue] = useState(initialQuestion)
  const onChange = (e) => {
    setDefaultValue(e.target.value)
  }

  useEffect(() => {
    if (closeOnRouteChange && prevPathName !== location.pathname) {
      setClosed(true)
    }
  }, [closeOnRouteChange, location])

  return (
    <div className={styles.paddings}>
      <div
        className={styles.mark_wrapper}
        style={closed && showResults.length ? { maxHeight: 0 } : undefined}
      >
        {showResults.map((result, index) => (
          <div
            key={index}
            className={`${styles.mark} ${result.role === 'user' ? styles.mark_user : ''}`}
            style={{
              alignSelf: result.role === 'user' ? 'flex-end' : 'flex-start',
              minWidth: result.content ? undefined : '300px',
              minHeight: result.content ? undefined : '120px',
              alignItems: result.content ? undefined : 'center',
              justifyContent: result.content ? undefined : 'center',
            }}
            dangerouslySetInnerHTML={{
              __html: result.content
                ? linkify(result.content)
                : '<l-cardio size="50" stroke="4" speed="2" color="white" />',
            }}
          />
        ))}
      </div>
      <div
        className={styles.wrapper}
        style={
          closed
            ? !showResults.length
              ? {
                  transitionDelay: '0s',
                  // minWidth: '20%',
                  width: 'fit-content',
                  paddingLeft: '37px',
                  justifyContent: 'space-between',
                  gap: '6px',
                }
              : {
                  // minWidth: '20%',
                  width: 'fit-content',
                  paddingLeft: '37px',
                  justifyContent: 'space-between',
                  gap: '6px',
                }
            : !showResults.length
              ? { transitionDelay: '0s' }
              : undefined
        }
      >
        {closed ? (
          <p
            style={{
              fontSize: '16px',
              fontWeight: '600',
            }}
          >
            Ask Maion
          </p>
        ) : (
          <>
            <form
              className={styles.input_wrapper}
              onSubmit={submit}
              ref={ref}
              id={'form'}
              style={
                closed
                  ? !showResults.length
                    ? { transitionDelay: '0s', opacity: 0 }
                    : { opacity: 0 }
                  : !showResults.length
                    ? { transitionDelay: '0s' }
                    : undefined
              }
            >
              <LogoGradient style={{ cursor: 'default' }} size="small" />
              <input
                className={styles.input}
                name={'input'}
                value={defaultValue}
                onChange={onChange}
                placeholder={'Talk with Maion'}
                aria-invalid={'false'}
                spellCheck={'false'}
              />
              <button onClick={submit} type={'submit'}>
                <PaperFlightSvg />
              </button>
            </form>
          </>
        )}
        {/*<img src={SoundIcon} alt={'sound'} />*/}
        <img
          src={!closed ? CloseIcon : OpenIcon}
          alt={!closed ? 'close' : 'open'}
          className={!closed ? styles.close : styles.open}
          onClick={toggle}
        />
      </div>
      {!closed && (
        <div className={styles.note}>
          The Maion AI healthcare feature is the future of our business. Its aim is to help you
          on your healthcare journey and encourage a healthier, better society. It is an
          experimental <span>(Beta)</span> feature designed to provide information and guidance
          on the subject of weight loss. Please be aware that the information provided by this AI
          is not a substitute for professional medical advice, diagnosis, or treatment. Always
          seek the advice of a qualified healthcare provider with any questions you may have
          regarding a medical condition or treatment. Use this tool at your own risk.
        </div>
      )}
    </div>
  )
}
