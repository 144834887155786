import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useVerifyEmailMutation } from 'shared/mutations/user'
import styles from 'components/widgets/Order/styles.module.css'
import { Label } from 'components/layout/label'
import { APP_PATHS } from 'paths'
import { Box, Text, VStack } from '@chakra-ui/react'
import { useBasketStore } from 'shared/stores/basketStore'
import { jwtDecode } from 'jwt-decode'

export const ConfirmEmail = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const { items } = useBasketStore()
  const [errorMessage, setErrorMessage] = useState()
  const [isError, setIsError] = useState(false)

  const { mutate: verifyEmail, isLoading } = useVerifyEmailMutation(token, {
    onSuccess: async (data) => {
      const {
        token,
        isPhoneNumberRequired,
        isPhoneNumberVerificationRequired,
        phoneNumber,
        isProfileCompleteRequired,
      } = data

      const decodedToken = jwtDecode(token)
      localStorage.setItem('USER_ID', decodedToken.userId)
      localStorage.setItem('REGISTRATION_TOKEN', token)

      if (items.length > 0) {
        const productId = items?.[0]?.id
        navigate(
          generatePath(APP_PATHS.orderSubscription, { id: productId, isPhoneNumberRequired })
        )
      }

      if (items.length === 0 && isPhoneNumberRequired) {
        navigate(generatePath(APP_PATHS.setPhoneNumber, { token }))
      }

      if (items.length === 0 && isPhoneNumberVerificationRequired && phoneNumber) {
        navigate(
          generatePath(APP_PATHS.verifyPhoneNumber, {
            token,
            phoneNumber,
          })
        )
      }

      if (items.length === 0 && isProfileCompleteRequired) {
        navigate(generatePath(APP_PATHS.completeRegistration, { token }))
      }
    },
    onError: (error) => {
      setIsError(true)
      setErrorMessage(error.message)
    },
  })

  useEffect(() => {
    if ((token, !isError, !errorMessage)) {
      verifyEmail()
    }
  }, [token])

  if (isLoading) {
    return <div>Loading</div>
  }
  if (isError) {
    return (
      <Box className={styles.wrapper}>
        <VStack className={styles.wrapper_order}>
          <Label>Email verification failed</Label>
          <Text className={styles.order_body}>{errorMessage}</Text>
          <Box className={styles.buttons}>
            <button
              className={styles.continue}
              onClick={() => {
                navigate(APP_PATHS.home)
              }}
            >
              Go to home
            </button>
          </Box>
        </VStack>
      </Box>
    )
  }
  return (
    <Box className={styles.wrapper}>
      <VStack maxW={'400px'} className={styles.wrapper_order}>
        <Label>Email verified</Label>
        <Text fontSize={'14px'} lineHeight={'18px'} textAlign={'left'}>
          Your email address has been verified. We’ll now ask you to sign in and verify your
          phone number.
        </Text>
        <Box className={styles.buttons}>
          <button
            className={styles.continue}
            onClick={() => {
              navigate(APP_PATHS.registration)
            }}
          >
            Proceed to Sign In
          </button>
        </Box>
        <Box fontSize={'12px'} lineHeight={'16px'} textAlign={'left'}>
          <Text as="i" fontWeight={'bold'}>
            Why do we need to verify your phone number?
          </Text>
          <Text>
            Our qualified physicians will use your phone number as the primary contact mechanism
            for engaging with you.
          </Text>
        </Box>
      </VStack>
    </Box>
  )
}
