import React from 'react'
export const UserSvg = ({ ...args }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...args}
    >
      <path
        d="M17.23 18.188C15.7465 16.2301 13.3959 14.9653 10.75 14.9653C8.10407 14.9653 5.75355 16.2301 4.27 18.188M17.23 18.188C19.2363 16.4022 20.5 13.8001 20.5 10.9028C20.5 5.51806 16.1348 1.15283 10.75 1.15283C5.36522 1.15283 1 5.51806 1 10.9028C1 13.8001 2.26371 16.4022 4.27 18.188M17.23 18.188C15.5075 19.7213 13.2375 20.6528 10.75 20.6528C8.26249 20.6528 5.99255 19.7213 4.27 18.188M14 8.46533C14 10.2603 12.5449 11.7153 10.75 11.7153C8.95507 11.7153 7.5 10.2603 7.5 8.46533C7.5 6.67041 8.95507 5.21533 10.75 5.21533C12.5449 5.21533 14 6.67041 14 8.46533Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
