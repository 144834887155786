import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'

export const useCreateReferralMutation = (options) => {
  const toast = useToast()
  return useMutation(async () => axios.post('/createUserReferralCode'), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useSendReferralCodeMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/sendUserReferralCode', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    onSuccess: () => {
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Email(s) successfully sent',
        isClosable: true,
      })
    },
    ...options,
  })
}
