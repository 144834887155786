import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { QrCode } from './components/qrCode'
import styles from './styles.module.css'
import { Label } from 'components/layout/label'
import { FormikInput, FormikTextarea } from 'components/elements/input'
import { Modal } from '../modal'
import {
  useCreateReferralMutation,
  useSendReferralCodeMutation,
} from 'shared/mutations/referrals'
import { useUserContextState } from '../../shared/contexts/user-context-provider'
import { useMediaQuery } from '../../shared/hooks/useMediaQuery'
import { TreatmentProductItemsItems } from '../../pages/treatments/TreatmentPlans'
import { APP_PATHS } from 'paths'

export const Page = ({
  withQrCode = true,
  style: propsStyle = undefined,
  rowStyle = undefined,
  withTreatments = true,
}) => {
  const currentHost = window.location.host
  const currentProtocol = window.location.protocol

  const isMobile = useMediaQuery('(max-width: 800px)')

  const [isInput, setIsInput] = useState(false)
  const [inviteData, setInviteData] = useState()
  const [referralCode, setReferralCode] = useState()
  const { mutate: createCode, isLoading: isCodeCreating } = useCreateReferralMutation()
  const { mutate: sendEmails } = useSendReferralCodeMutation()
  const [referralModalIsOpen, setReferralModalIsOpen] = useState(false)
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false)
  const { isUserLoading, user } = useUserContextState()
  useEffect(() => {
    if (isUserLoading || isEmpty(user)) {
      return
    }
    if (!referralCode && !isCodeCreating) {
      createCode(null, {
        onSuccess: ({ token }) => {
          setReferralCode(token)
        },
      })
    }
  }, [isCodeCreating, referralCode, isUserLoading, user])

  const openReferralModal = () => setReferralModalIsOpen(true)
  const closeReferralModal = () => setReferralModalIsOpen(false)
  const openReferralShareModal = () => setShareModalIsOpen(true)
  const closeReferralShareModal = () => setShareModalIsOpen(false)

  const openInput = () => setIsInput(true)

  const style = !withQrCode
    ? isMobile
      ? { padding: 0, ...(propsStyle ? propsStyle : {}) }
      : { padding: '0 8% 60px 8%', ...(propsStyle ? propsStyle : {}) }
    : propsStyle

  if (isUserLoading) {
    return (
      <div className={styles.wrapper} style={style}>
        <Label>Invite a friend</Label>
        <div
          className={`${styles.items_wrapper} ${withQrCode ? '' : styles.items_wrapper_alone}`}
        >
          Generating code
        </div>
      </div>
    )
  }
  if (isCodeCreating) {
    return (
      <div className={styles.wrapper} style={style}>
        <Label>Invite a friend</Label>
        <div
          className={`${styles.items_wrapper} ${withQrCode ? '' : styles.items_wrapper_alone}`}
        >
          Generating code
        </div>
      </div>
    )
  }
  return (
    <div style={style}>
      <div className={styles.wrapper} style={style}>
        <Label>Invite a friend</Label>
        <div
          className={`${styles.items_wrapper} ${withQrCode ? '' : styles.items_wrapper_alone}`}
          style={rowStyle}
        >
          <div className={styles.left}>
            <span style={{ color: 'var(--accent)' }}>
              Losing weight can be one of the most positive things you can do. It is a gift to
              your body and to the people you love and who love you. We want you to share that
              gift with your friends and family.
            </span>
            <br />
            <br />
            <span>
              Give and get given. Invite a friend to join Maion and start their weight loss
              journey and we’ll give them <a href={'#'}>10% off</a> their first order and you a{' '}
              <a href={'#'}>choice of rewards</a>, as well as that enormous sense of well-being.
              <br />
              <br />
            </span>
            {isInput ? (
              <Formik
                enableReinitialize
                initialValues={{
                  invitedUserName: '',
                  email: '',
                  message: '',
                }}
                validateOnChange={false}
                validateOnMount={false}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email('Not a valid email').required('Cannot be empty'),
                  invitedUserName: Yup.string().required('Cannot be empty'),
                  message: Yup.string(),
                })}
                onSubmit={(values) => {
                  setInviteData(values)
                  openReferralModal()
                }}
              >
                {(formik) => {
                  return (
                    <form
                      onSubmit={formik.handleSubmit}
                      style={{ padding: 0 }}
                      className={styles.referral}
                    >
                      <span>
                        Add their email, name and personal message below and we&apos;ll do the
                        rest. Let&apos;s all look after each other.
                      </span>
                      <FormikInput
                        name="invitedUserName"
                        placeholder="Name"
                        required
                        type="text"
                        formik={formik}
                        color={'white'}
                      />
                      <FormikInput
                        name="email"
                        placeholder="Email address"
                        required
                        type="email"
                        formik={formik}
                        color={'white'}
                      />
                      <FormikTextarea
                        name="message"
                        placeholder="Message"
                        className={styles.textarea}
                      />
                      <button
                        type="submit"
                        style={{ alignSelf: 'flex-end' }}
                        disabled={
                          !formik.isValid ||
                          !formik.values.invitedUserName ||
                          !formik.values.email
                        }
                      >
                        Send
                      </button>
                    </form>
                  )
                }}
              </Formik>
            ) : null}
            {isInput ? null : isEmpty(user) ? (
              <span>Please log in to share referral codes</span>
            ) : (
              <>
                <span>
                  {isInput
                    ? 'Add in your friend or friends email address:'
                    : 'Generate your Maion share code:'}
                </span>
                <button onClick={openInput}>Share Code</button>
              </>
            )}
            {isInput ? (
              <>
                <span>or create a sharable link:</span>
                <button style={{ alignSelf: 'flex-end' }} onClick={openReferralShareModal}>
                  Generate Link
                </button>
              </>
            ) : null}
          </div>
          {withQrCode && (
            <>
              {isInput ? (
                <div />
              ) : (
                <div className={styles.qr_wrapper}>
                  <QrCode
                    link={
                      isEmpty(user)
                        ? `${currentProtocol}//${currentHost}${APP_PATHS.home}`
                        : `${currentProtocol}//${currentHost}${APP_PATHS.registrationStep1}?referralToken=${referralCode}`
                    }
                    loggedIn={!isEmpty(user)}
                  />
                  <span className={styles.under_qr_text}>
                    {isEmpty(user)
                      ? ''
                      : 'Share this QR code to unlock the power of weight loss.'}
                  </span>
                </div>
              )}
            </>
          )}
          <Modal
            modalIsOpen={referralModalIsOpen}
            onConfirm={() => sendEmails(inviteData)}
            close={closeReferralModal}
            type={'referrals'}
          />
          <Modal
            modalIsOpen={shareModalIsOpen}
            close={closeReferralShareModal}
            type={'referralCode'}
            referralCode={referralCode}
          />
        </div>
      </div>
      {withTreatments && <TreatmentProductItemsItems />}
    </div>
  )
}
