import React from 'react'

import styles from './styles.module.css'
import HomeImg from 'shared/icons/misc/homeBig.svg'
import HomeOutlineImg from 'shared/icons/misc/homeOutline.svg'
import HumanOutlineImg from 'shared/icons/misc/humanDefault.svg'
import HumanImg from 'shared/icons/misc/humanActive.svg'
import SearchImg from 'shared/icons/misc/searchActive.svg'
import SearchOutlineImg from 'shared/icons/misc/searchBig.svg'
import BookImg from 'shared/icons/misc/book.svg'
import BookOutlineImg from 'shared/icons/misc/bookOutline.svg'
import HeartOutlineImg from 'shared/icons/misc/heart.svg'
import HeartImg from 'shared/icons/misc/heartActive.svg'
import { Link } from 'react-router-dom'
import { APP_PATHS } from '../../../paths'
const bottomNavItems = [
  {
    icon: HomeOutlineImg,
    iconActive: HomeImg,
    link: APP_PATHS.home,
  },
  {
    icon: HeartOutlineImg,
    iconActive: HeartImg,
    link: APP_PATHS.whyMaion,
  },
  {
    icon: BookOutlineImg,
    iconActive: BookImg,
    link: APP_PATHS.howitWorks,
  },
  {
    icon: SearchOutlineImg,
    iconActive: SearchImg,
    link: APP_PATHS.faq,
  },
  {
    icon: HumanOutlineImg,
    iconActive: HumanImg,
    link: APP_PATHS.myProfile,
  },
]

export const BottomNavigation = () => {
  return (
    <div className={styles.wrapper}>
      {bottomNavItems.map((item, index) => (
        <Link key={index} to={item.link}>
          <img
            src={window.location.pathname === item.link ? item.iconActive : item.icon}
            alt={''}
          />
        </Link>
      ))}
    </div>
  )
}
