import React from 'react'

export const RxIcon = ({ color = 'white' }) => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.50406 12.1748L4.29232 6.95937L3.30798 6.99024L3.30798 10.2769C3.30798 11.5576 3.51775 11.7582 4.88939 11.8508L4.88939 12.1748L0.0160437 12.1748L0.0160436 11.8508C1.33927 11.7428 1.51678 11.5576 1.51678 10.0763L1.51678 2.85492C1.51678 1.60507 1.37154 1.40448 0.0160427 1.29647L0.0160427 0.972431L4.82484 0.972431C6.50308 0.972431 7.76176 1.37362 8.5202 1.9754C9.10113 2.4383 9.39159 3.25611 9.39159 3.99676C9.39159 5.46264 8.40724 6.45017 6.19648 6.77421L9.73047 11.0484M3.30798 6.37302C5.00235 6.34216 5.63169 6.21872 6.43854 5.8021C7.08401 5.46264 7.45516 4.72198 7.45516 3.87332C7.45516 2.34572 6.40626 1.60507 4.21164 1.60507C3.61457 1.60507 3.30798 1.72851 3.30798 2.31486L3.30798 6.37302Z"
      fill={color}
    />
    <path
      d="M9.26352 10.7244C9.44103 10.5084 9.61853 10.2306 9.79603 9.98376C10.1833 9.39741 10.4577 9.08881 10.4577 8.88821C10.4577 8.65676 10.2801 8.54875 9.74763 8.51789L9.74763 8.25557L12.5393 8.25557L12.5393 8.51789C11.9745 8.53332 11.442 8.81106 11.1031 9.25854L9.69922 11.1565L12.2488 14.474C12.7491 15.1992 13.1525 15.4924 13.7012 15.4924L13.7012 15.7547L10.0704 15.8781L10.0704 15.6158C10.6513 15.5849 10.7804 15.5078 10.7804 15.2146C10.7804 15.0912 10.7481 15.014 10.6674 14.906L8.76327 12.5452L7.35936 14.6283C7.11731 14.9986 7.00435 15.1992 7.00435 15.3226C7.00435 15.5387 7.198 15.6312 7.71438 15.6312L7.71438 15.8936L5.14861 15.8936L5.14861 15.6312C5.72954 15.585 5.93931 15.4615 6.43956 14.7671L8.45667 11.9743"
      fill={color}
    />
  </svg>
)
