import React, { useRef, useState } from 'react'

import styles from './styles.module.css'

import Logo from 'shared/icons/logo.svg'
import LogoWhite from 'shared/icons/logo-white.png'
import Figure from 'shared/icons/figures/header-fig.svg'
import { Link, useNavigate } from 'react-router-dom'

import Human from 'shared/icons/misc/human.svg'
import HumanFilled from 'shared/icons/misc/humanFilled.svg'
import Bag from 'shared/icons/misc/bag.svg'
import BagWhite from 'shared/icons/misc/bag-white.svg'
import { HomeSvg } from 'shared/icons/misc/home'
import { LogoGradient } from 'components/elements/logoGradient'

import { APP_PATHS } from 'paths'
import { useBasketStore } from 'shared/stores/basketStore'
import { Badge } from 'shared/components'
import { useUserContextState } from 'shared/contexts/user-context-provider'
import { isEmpty } from 'lodash'
import { HamburgerMenu } from './hamburger-menu'
import { MobileMenu } from '../mobileMenu'

const MainLinks = [
  {
    label: HomeSvg,
    link: APP_PATHS.home,
  },
  {
    label: 'Treatment Plans',
    link: APP_PATHS.treatmentPlans,
  },
  {
    label: 'How it All Works',
    link: APP_PATHS.howitWorks,
  },
  {
    label: 'FAQ',
    link: APP_PATHS.faq,
  },
  {
    label: 'Why Maion',
    link: APP_PATHS.whyMaion,
  },
  {
    label: 'Contact',
    link: APP_PATHS.contact,
  },
  // {
  //   label: 'Referrals ',
  //   link: APP_PATHS.referrals,
  // },
]

const getRightLinks = (loggedIn) => [
  {
    label: loggedIn ? HumanFilled : Human,
    link: loggedIn ? APP_PATHS.myProfile : APP_PATHS.registration,
    title: 'Profile',
  },
  {
    label: Bag,
    link: APP_PATHS.basket,
    title: 'Basket',
  },
]

const hideOn = ['/registration']

export const Header = () => {
  const { items } = useBasketStore()
  const { user } = useUserContextState()
  const navigate = useNavigate()
  const bannerClassnameRef = useRef()

  const goMainPage = () => navigate(APP_PATHS.home)
  const goBasketPage = () => navigate(APP_PATHS.basket)
  const goFaqPage = () => navigate(APP_PATHS.faq)

  const notVisible = hideOn.includes(window.location.pathname)
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)

  const onAskBotHover = () => {
    const element = document.getElementById('banner')

    if (element) {
      if (!bannerClassnameRef.current) {
        bannerClassnameRef.current = element.className
      }
      element.className = `${element.className} ${styles.stop_animation}`
    }
  }

  const onAskBotOut = () => {
    const element = document.getElementById('banner')
    if (element) {
      element.className = bannerClassnameRef.current
    }
  }

  if (notVisible) return <></>

  return (
    <>
      <MobileMenu isOpen={isMobileMenuOpened} onClose={() => setIsMobileMenuOpened(false)} />
      <div style={{ position: 'relative' }}>
        <img src={Figure} className={styles.figure} alt={''} />
        <div className={styles.wrapper}>
          <div onClick={goMainPage} className={styles.left}>
            <img src={Logo} alt={'Maion Ai Logo'} className={styles.logo} />
          </div>
          <div className={styles.left_mobile}>
            <HamburgerMenu externalState={isMobileMenuOpened} onChange={setIsMobileMenuOpened} />
          </div>
          <div className={styles.main_mobile} onClick={goMainPage}>
            <img src={LogoWhite} alt={'Maion Ai Logo'} />
          </div>
          <div className={`${styles.links} ${styles.main}`}>
            {MainLinks.map((item, index) => (
              <Link
                to={item.link}
                key={index}
                active={window.location.pathname === item.link ? 'active' : undefined}
              >
                {typeof item.label === 'string' ? item.label : <item.label />}
              </Link>
            ))}
          </div>
          <div className={`${styles.links} ${styles.right}`}>
            {getRightLinks(!isEmpty(user)).map((item, index) => (
              <Badge key={index} amount={item.link === APP_PATHS.basket && items.length}>
                <Link to={item.link} title={item.title}>
                  <img src={item.label} alt={item.title} title={item.title} />
                </Link>
              </Badge>
            ))}
          </div>
          <div className={styles.right_mobile}>
            <Badge amount={items.length}>
              <img src={BagWhite} alt={'basket'} title="Basket" onClick={goBasketPage} />
            </Badge>
          </div>
        </div>
        <div className={styles.help} onMouseEnter={onAskBotHover} onMouseLeave={onAskBotOut}>
          <LogoGradient onClick={goFaqPage} size="small" />
          <div className={styles.text}>
            <p>Need some help?</p>
            <p className={styles.blue} style={{ cursor: 'pointer' }} onClick={goFaqPage}>
              Ask Maion
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
