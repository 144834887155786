import React from 'react'
import { Layout } from '../layout'
import { Label } from '../../../components/layout/label'
import styles from '../profile.module.css'

export const MessagesPage = () => {
  return (
    <Layout>
      <div className={styles.page_wrapper}>
        <Label>Messages</Label>
      </div>
    </Layout>
  )
}
