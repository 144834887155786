import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import 'yup-phone'

import {
  Box,
  Button,
  Link as ChakraLink,
  FormControl,
  FormLabel,
  Text,
  VStack,
  useMediaQuery,
  Flex,
} from '@chakra-ui/react'

import { APP_PATHS } from 'paths'
import { Label } from 'components/layout/label'
import { Input } from 'components/elements/input'
import { useResendVerificationEmailMutation } from 'shared/mutations/user'

export const SentVerifyEmailPage = () => {
  const [isMobile] = useMediaQuery('(max-width: 800px)')
  const [resendVerificationEmailTimer, setResendVerificationEmailTimer] = useState(0)

  const { mutate: resendVerificationEmail, isLoading: isResendVerificationMutationLoading } =
    useResendVerificationEmailMutation()

  useEffect(() => {
    if (resendVerificationEmailTimer <= 0) {
      clearInterval(resendVerificationEmailTimer)
    }
  }, [resendVerificationEmailTimer])

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Email Address is required'),
    }),
    onSubmit: async (values) => {
      setResendVerificationEmailTimer(15)

      await resendVerificationEmail({
        ...values,
      })

      setInterval(() => {
        setResendVerificationEmailTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    },
  })

  return (
    <Box
      as="form"
      onSubmit={formik.handleSubmit}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      gap="30px"
      padding="7% 8% 60px 8%"
    >
      <Label>Email Verification Sent</Label>

      <VStack
        width={isMobile ? '100%' : '450px'}
        alignItems="flex-start"
        spacing={8}
        textAlign="start"
        fontSize="14px"
        lineHeight={'normal'}
        fontWeight={'400'}
      >
        <Text>
          Please check your email for a verification message from{' '}
          <Text as="span" color="var(--help-color) !important" textDecoration="underline">
            info@maion.ai
          </Text>{' '}
          <Text as={'i'}>(don’t forget to check your SPAM folder too).</Text>
        </Text>
        <Text as={'i'}>
          If you haven’t received a verification email, please enter email address and click the
          ‘Resend verification email’ link below.
        </Text>
        <FormControl>
          <FormLabel fontWeight="450" fontSize="17px" lineHeight={'22px'} color={'#4F5B76'}>
            Email Address
          </FormLabel>
          <Input
            name="email"
            // label="Email Address"
            placeholder="Email"
            required
            type="email"
            color="white"
            value={formik.values.email}
            onChange={formik.handleChange}
            errorMessage={formik.errors.email}
          />
        </FormControl>
        <Flex justifyContent="center" width={isMobile ? '100%' : '400px'} mt={4} w={'100%'}>
          <Button
            type="submit"
            colorScheme="pink"
            bg={'var(--accent)'}
            color="white"
            fontSize={'16px'}
            size="lg"
            width={isMobile ? '100%' : '265px'}
            h={'36px'}
            isDisabled={
              !isEmpty(formik.errors) ||
              !formik.dirty ||
              isResendVerificationMutationLoading ||
              resendVerificationEmailTimer > 0
            }
          >
            Resend verification email
          </Button>
        </Flex>
      </VStack>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent={isMobile ? 'center' : 'flex-end'}
        gap="20px"
        width={isMobile ? '100%' : '450px'}
        mt={'200px'}
      >
        <Text fontSize="22px">
          If you have an account please{' '}
          <ChakraLink
            color={'var(--help-color)'}
            as={Link}
            to={`${APP_PATHS.registration}`}
            fontWeight={'semibold'}
            textDecoration={'underline'}
          >
            sign in
          </ChakraLink>
        </Text>
      </Box>
    </Box>
  )
}

SentVerifyEmailPage.propTypes = {
  isFromScheduler: PropTypes.bool,
}
