import { Label } from 'components/layout/label'
import React, { useRef, useState } from 'react'
// import MainPageImage from 'shared/images/mainPageImage.png'
import styles from './styles.module.css'
import Icon from 'shared/images/whyMaionMainPage.svg'
import Icon1 from 'shared/images/whyMaionMainPage2.svg'
import Icon2 from 'shared/images/whyMaionMainPage3.svg'
import Logos from 'shared/images/whyMaionLogos.svg'

import { TrustBox } from '..'
import { useMediaQuery } from 'shared/hooks/useMediaQuery'

import RSSlider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Box } from '@chakra-ui/react'

export const WhyMaion = () => {
  return (
    <section style={{ width: '100%' }}>
      <Label className={styles.label}>Why Maion</Label>
      <div className={styles.section}>
        <Slider />
        <div
          className="wistia_responsive_padding"
          style={{
            padding: '56.25% 0 0 0',
            position: 'relative',
            width: '100%',
          }}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}
          >
            <div
              className="wistia_embed wistia_async_ypg2lf7s9b seo=true videoFoam=true"
              style={{ height: '100%', position: 'relative', width: '100%' }}
            >
              <div
                className="wistia_swatch"
                style={{
                  height: '100%',
                  left: 0,
                  overflow: 'hidden',
                  position: 'absolute',
                  top: 0,
                  transition: 'opacity 200ms',
                  width: '100%',
                }}
              >
                <img
                  src="https://fast.wistia.com/embed/medias/ypg2lf7s9b/swatch"
                  style={{
                    filter: 'blur(5px)',
                    height: '100%',
                    objectFit: 'contain',
                    width: '100%',
                  }}
                  alt=""
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <img src={MainPageImage} /> */}
      </div>
      <div className={styles.bottom}>
        <div className={styles.logos}>
          <TrustBox />
          <p className={styles.trust_box_label}>
            Rating for GoGoMeds - <br />
            Maion&apos;s Pharmacy Provider
          </p>
        </div>
        <div className={styles.logos}>
          <p>AS SEEN ON:</p>
          <img src={Logos} />
        </div>
      </div>
    </section>
  )
}

const items = [
  {
    id: 1,
    icon: Icon,
    label: 'The Highest Medical Assurance',
    text: 'Our pharmacy partners are NABP® affiliated sterile pharmacy facility and provider which adheres to strict US Pharmacopeia standards and product testing (USP).',
  },
  {
    id: 2,
    icon: Icon1,
    label: 'Customer Policy',
    text: 'We allow you to cancel at any time. We have a fair and transparent refund policy.',
  },
  {
    id: 3,
    icon: Icon2,
    label: 'Care and Support',
    text: 'We make sure there is a health care professional on hand for your entire journey and will be there to support you at each dosage increase at no additional cost. Our AI powered adherence platform is designed to help you at every step of your journey and is free at the point of use.',
  },
]

const Slider = () => {
  const isMobile = useMediaQuery('(max-width: 800px)')
  const isTablet = useMediaQuery('(max-width: 1200px)')
  const isSmallDesktop = useMediaQuery('(max-width: 1400px)')

  let sliderRef = useRef(null)
  const [slideIndex, setSlideIndex] = useState(0)
  const settings = {
    className: 'center',
    centerMode: false,
    infinite: true,
    centerPadding: '0px',
    slidesToShow: 1,
    speed: 500,
    nextArrow: null,
    prevArrow: null,
    beforeChange: (current, next) => setSlideIndex(next),
    autoplay: true,
  }

  // Optimized slider width selection
  const sliderWidth = isMobile
    ? '320px'
    : isTablet
      ? '240px'
      : isSmallDesktop
        ? '360px'
        : '470px'

  const goTo = (index) => {
    sliderRef.slickGoTo(index)
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        width: sliderWidth,
        marginInline: isMobile ? 'auto' : undefined,
      }}
    >
      <Box
        sx={{
          '.slick-slider': {
            width: sliderWidth,
          },
          '.slick-slide': {
            marginBlock: 0,
            borderRadius: '15px',
            transition: '0.8s ease',
          },
          '.slick-track': {
            display: 'flex',
            gap: '20px',
          },
          '.slick-center': {
            zIndex: 100,
            opacity: 1,
            position: 'relative',
            background: 'white',
            boxShadow: '6px 6px 12px 0 rgba(0, 0, 0, 0.12)',
          },
          '.slick-list': {
            overflow: 'hidden',
          },
          '.slick-arrow': {
            display: 'none!important',
            color: 'white',
            transition: '0.2s',
            _hover: {
              color: 'white',
            },
            _focus: {
              color: 'white',
            },
            _before: {
              transition: '0.2s',
            },
          },
          '.slick-prev': {
            display: 'none',
            left: '-40px',
          },
          '.slick-next': {
            display: 'none',

            right: '-40px',
            transform: 'scale(-1, 1)',
          },
        }}
      >
        <RSSlider
          ref={(slider) => {
            sliderRef = slider
          }}
          {...settings}
        >
          {items.map((item, index) => (
            <div key={index + Math.random()} className={styles.item}>
              <img src={item.icon} />
              <p>{item.label}</p>
              <span>{item.text}</span>
            </div>
          ))}
        </RSSlider>
      </Box>
      <div className={styles.bottom_indicators}>
        {items.map((i, index) => (
          <div
            key={i.id}
            onClick={() => goTo(index)}
            className={`${styles.indicator} ${slideIndex === index && styles.active}`}
          />
        ))}
      </div>
    </div>
  )
}
