import React, { useCallback, useState } from 'react'
import { useIndexQuestionnaires } from 'shared/queries/questionaries'
import { useFormSave } from 'services/forms'
import { QUESTION_TYPES, CALCULATED_BMI } from 'shared/constants/common'
import { useMediaQuery } from 'shared/hooks/useMediaQuery'
import { init, Form } from '@feathery/react'
import { environment } from 'environments'
import styles from './styles.module.css'
import { useIndexProducts } from 'shared/queries/product'
import { useNavigate } from 'react-router-dom'
import { APP_PATHS } from 'paths'
import { sleep } from 'shared/utils/sleep'
import { useUserContextState } from 'shared/contexts/user-context-provider'
import { useRefundMyOrderAndCancelSubscriptionMutation } from 'shared/mutations/order'
import { CircularProgress, Flex } from '@chakra-ui/react'
import { RefundMyOrderAndCancelSubscriptionFailed } from '../complete/refundMyOrderAndCancelSubscriptionFailed'
import { RefundMyOrderAndCancelSubscriptionComplete } from '../complete/refundMyOrderAndCancelSubscriptionComplete'

export const Questionnaires = ({
  productId,
  orderId,
  type = QUESTION_TYPES.QUESTIONNAIRE,
  handleComplete,
  handleFiles,
}) => {
  const [orderAndSubscriptionCanceled, setOrderAndSubscriptionCanceled] = useState(false)
  const [error, setError] = useState(null)
  const { data, isLoading } = useIndexQuestionnaires({
    status: 'ACTIVE',
    productId,
  })

  const { mutateAsync: refundAndCancel } = useRefundMyOrderAndCancelSubscriptionMutation({
    onSuccess: () => {
      setOrderAndSubscriptionCanceled(true)
    },
    onError: (error) => {
      setError(error.message)
    },
  })
  const { data: products } = useIndexProducts({})
  const product = products?.find((i) => i.id === productId)
  const navigate = useNavigate()

  const formName =
    type === QUESTION_TYPES.PRESCRIPTION
      ? product?.followUpQuestionnaireFormId
      : product?.initialQuestionnaireFormId

  const questions = data ? [data.find((item) => item.type === type)] : undefined
  const { onSubmit, finish } = useFormSave()

  const onFormSubmit = useCallback(
    async (formData) => {
      const bmi = formData?.fields?.[CALCULATED_BMI]
      if (bmi) {
        onSubmit({
          [CALCULATED_BMI]: {
            value: bmi.value,
            type: bmi.type,
            displayText: bmi.displayText,
          },
        })
      }
      if (
        Object.keys(formData.submitFields).some((key) =>
          key.includes('{{initialQuestionaryError}}')
        )
      ) {
        refundAndCancel({ orderId })
        return
      }
      if (
        Object.keys(formData.submitFields).some((key) =>
          key.includes('{{followUpQuestionaryError}}')
        )
      ) {
        navigate(APP_PATHS.home)
        return
      }
      if (!formData.isLastStep()) {
        onSubmit(formData.submitFields)
        return
      }
      const responses = finish(formData.submitFields)

      const { result, files } = await extractFiles(responses)

      const questionnairesResponses = [
        {
          questionnaireId: formName,
          responses: Object.entries(result)
            .map(([questionId, response]) => ({
              questionId,
              response: formatResponseValue(response.value),
              options: response.options?.map((o) => o.value) || [],
            }))
            .filter((i) => Boolean(i.response)),
        },
      ]
      handleComplete(questionnairesResponses)
      handleFiles(files)
    },
    [finish, onSubmit, questions, formName]
  )

  if (isLoading || !product) {
    return (
      <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} py="9%" px="8%">
        <CircularProgress isIndeterminate color="var(--accent)" />
      </Flex>
    )
  }
  if (error) {
    return <RefundMyOrderAndCancelSubscriptionFailed orderId={orderId} message={error} />
  }
  if (orderAndSubscriptionCanceled) {
    return <RefundMyOrderAndCancelSubscriptionComplete orderId={orderId} />
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper_data}>
        <FormComponent formName={formName} onSubmit={onFormSubmit} />
      </div>
    </div>
  )
}

const FormComponent = ({ formName, onSubmit }) => {
  const { user, isUserLoading } = useUserContextState()
  const isMobile = useMediaQuery('(max-width: 800px)')

  if (isUserLoading) return null
  init(environment.FEATHERY_SDK_KEY, {
    userId: user.id,
  })

  return (
    <div
      style={
        isMobile
          ? { position: 'relative' }
          : { maxWidth: '50%', marginBottom: '100px', position: 'relative' }
      }
    >
      <Form formName={formName} onSubmit={onSubmit} className={styles.form_wrapper} />
    </div>
  )
}

const formatResponseValue = (value) => {
  let _value = value
  if (Array.isArray(value)) {
    _value = value[0]
  }
  if (_value instanceof Promise) {
    return undefined
  }

  if (_value instanceof File || _value instanceof Blob) {
    return undefined
  }

  return String(_value)
}

const extractFiles = async (values) => {
  let files = []
  let result = {}

  for (const [key, val] of Object.entries(values)) {
    if (Array.isArray(val.value) && val.value[0] instanceof Promise) {
      const promisedFile = await val.value[0]
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        files.push({
          base64Image: reader.result,
          isId: key === '{{photoId}}',
        })
      }
      reader.readAsDataURL(promisedFile)
      await sleep(1_000)
    } else {
      result[key] = val
    }
  }

  if (
    files.every((item) => item.isId === false) ||
    files.filter((item) => item.isId).length > 1
  ) {
    files = files.map((file, index) =>
      index === 0 ? { ...file, isId: true } : { ...file, isId: false }
    )
  }

  return { result, files }
}
