import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Label } from 'components/layout/label'

import { APP_PATHS } from 'paths'
import InfoIcon from 'shared/icons/misc/info.svg'
import styles from './styles.module.css'

export const UploadIdFailed = ({ handleBack }) => {
  const navigate = useNavigate()
  return (
    <div className={styles.wrapper}>
      <Label>Confirmation</Label>
      <div className={styles.inner_wrapper}>
        <p className={styles.label}>Upload Id Failed</p>
        <p className={styles.body}>Your payment occurred an error.</p>

        <hr />
        <img color="white" src={InfoIcon} alt="Info" />
        <p className={styles.sublabel}> Do not worry 😉</p>

        <div className={styles.buttons}>
          <button className={styles.back} onClick={handleBack}>
            Try again
          </button>
          <button
            className={styles.continue}
            onClick={() => {
              navigate(APP_PATHS.home)
            }}
          >
            Upload Id Later
          </button>
        </div>
      </div>
    </div>
  )
}
