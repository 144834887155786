import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { APP_PATHS } from '../../paths'
import styles from './styles.module.css'
import { Label } from '../../components/layout/label'
import { workingTimes } from '../../shared/constants'
import { Calendar } from './components/calendar/calendar'

export const PickDate = () => {
  const [activeTime, setActiveTime] = useState('')

  const handleTime = (time) => {
    return () => setActiveTime(time)
  }

  return (
    <div className={styles.wrapper}>
      <Label>Available Times</Label>
      <span>Select an appointment time with your provider</span>
      <div className={styles.pick_date_wrapper}>
        <div>
          <Calendar />
        </div>
        <div className={styles.time}>
          <span>Select an appointment time with your provider</span>
          <div className={styles.items_wrapper} style={{ padding: 0 }}>
            {workingTimes.map((i) => (
              <div
                onClick={handleTime(i)}
                key={i}
                className={`${styles.item} ${i === activeTime ? styles.item_active : {}}`}
              >
                {i}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Link to={APP_PATHS.scheduleDetails}>Next</Link>
    </div>
  )
}
