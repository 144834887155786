// import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'

export const useCreatePaymentMethodMutation = (options) => {
  const toast = useToast()
  return useMutation(
    async (referenceId) => axios.post('/createPaymentMethod', { referenceId }),
    {
      onError: (error) => {
        toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
      },
      ...options,
    }
  )
}

export const useDeleteMyPaymentMethodMutation = (options) => {
  const toast = useToast()
  return useMutation(async (id) => axios.delete(`/deleteMyPaymentMethod?id=${id}`), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}
