import React from 'react'
import { Layout } from '../layout'
import { Page } from '../../referrals/page'

export const ReferralPage = () => {
  return (
    <Layout>
      <Page
        withQrCode={true}
        withTreatments={false}
        style={{
          paddingLeft: '1%',
          paddingRight: '2%',
        }}
      />
    </Layout>
  )
}
