import React from 'react'
import styles from './styles.module.css'
import LogoWhite from '../../../shared/icons/logo-white.png'

export const LogoGradient = ({ onClick, type, style, size = 'big' }) => {
  return (
    <button
      className={`${styles.wrapper} ${size === 'small' && styles.small}`}
      onClick={onClick}
      type={type}
      style={style}
    >
      <img alt={'logo'} src={LogoWhite} className="logo" />
    </button>
  )
}
