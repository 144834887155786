import React, { useEffect, useState } from 'react'

import styles from './styles.module.css'

export const HamburgerMenu = ({ externalState, onChange }) => {
  const [isOpen, setIsOpen] = useState(externalState || false)

  const onClick = () => {
    setIsOpen((prevState) => {
      onChange?.(!prevState)
      return !prevState
    })
  }

  useEffect(() => setIsOpen(externalState), [externalState])

  return (
    <div className={styles.hamburger_icon} id="icon" onClick={onClick}>
      <div className={`${styles.icon_1} ${isOpen ? styles.a : ''}`} />
      <div className={`${styles.icon_2} ${isOpen ? styles.c : ''}`} />
      <div className={`${styles.icon_3} ${isOpen ? styles.b : ''}`} />
      <div className={styles.clear} />
    </div>
  )
}
