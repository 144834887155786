import React from 'react'

import InjectionImage from 'shared/icons/tiles/injection.svg'
import HappyMoodImage from 'shared/icons/tiles/happyMood.svg'
import EducationImage from 'shared/icons/tiles/education.svg'
import { Button } from 'shared/components'
import GreenArrow from 'shared/icons/misc/profilePage/greenArrow.svg'
import YellowArrow from 'shared/icons/misc/profilePage/yellowArrow.svg'
import styles from '../../profile.module.css'
import stylesTiles from './styles.module.css'

import { Area, AreaChart, ResponsiveContainer } from 'recharts'
import { shortMonthDayFormatter } from 'shared/constants/calendarHelpers'
import { Box } from '@chakra-ui/react'

const mockChart = [
  {
    dateView: new Date() - 6_000_000,
    price: 10,
  },
  {
    dateView: new Date() - 5_000_000,
    price: 20,
  },
  {
    dateView: new Date() - 4_000_000,
    price: 40,
  },
  {
    dateView: new Date() - 3_000_000,
    price: 45,
  },
  {
    dateView: new Date() - 2_000_000,
    price: 50,
  },
  {
    dateView: new Date() - 1_000_000,
    price: 80,
  },
]

const NextMedication = ({ days, available }) => {
  return (
    <>
      <div>
        <p>Next Medication</p>
        {available ? <span>{days} Days</span> : <span>No active subscription</span>}
      </div>
      <img src={InjectionImage} alt={'Injection'} />
    </>
  )
}

const Mood = ({ mood }) => {
  return (
    <>
      <div>
        <p>Current Mood</p>
        <span>{mood}</span>
      </div>
      <img src={HappyMoodImage} alt={'Sun'} />
    </>
  )
}

const Education = ({ isAble }) => {
  return (
    <>
      <div>
        <p>Current Mood</p>
        <Button variant={isAble ? 'accent' : 'grey'} disabled={!isAble}>
          Today’s Tip
        </Button>
      </div>
      <img src={EducationImage} alt={'Book'} />
    </>
  )
}

const iconsMap = {
  down: GreenArrow,
  top: YellowArrow,
}
const Chart = ({ chart, available }) => {
  const renderChart = (chart.data || mockChart).map((item) => ({
    ...item,
    dateView: shortMonthDayFormatter.format(item.dateView),
  }))

  return (
    <>
      <div>
        <p>{chart.name}</p>
        <div className={stylesTiles.chart_row}>
          {available ? (
            <>
              <span className={stylesTiles.chart_amount}>{chart.amount}%</span>
              <span
                className={`${styles.chart_percent} ${
                  chart.direction === 'down' ? styles.chart_green : styles.chart_yellow
                }`}
              >
                <img src={iconsMap[chart.direction]} alt={''} />
                {chart.percent}%
              </span>
            </>
          ) : (
            <span className={stylesTiles.chart_amount}>N/A</span>
          )}
        </div>
        <p className={stylesTiles.chart_bottom_text}>{chart.bottomText}</p>
      </div>
      <Box width={{ base: '80px', md: '100px' }} height={{ base: '80px', md: '100px' }}>
        <ResponsiveContainer>
          <AreaChart data={renderChart}>
            <defs>
              <linearGradient id="chartColor" x1=".5" x2=".5" y2="1">
                <stop stopColor="#4648FF" />
                <stop offset="0.931471" stopColor="#4648FF" stopOpacity="0" />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="price"
              stroke="#4648FF"
              fillOpacity={1}
              fill="url(#chartColor)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </>
  )
}

export const TilesMap = {
  education: Education,
  nextMedication: NextMedication,
  mood: Mood,
  chart: Chart,
}
