import React, { useState } from 'react'
import ArrowRight from 'shared/icons/misc/arrowRight.svg'

import cls from 'classnames'

import styles from './styles.module.css'
import {
  CALENDAR_MONTHS_BY_ID,
  getMonthDays,
  getNextMonth,
  THIS_MONTH,
  WEEK_DAYS,
} from 'shared/constants/calendarHelpers'

export const Calendar = () => {
  const [chosenDate, setChosenDate] = useState({})
  const [activeMonth, setActiveMonth] = useState(THIS_MONTH)
  const [monthDates, setMonthDates] = useState(Array(getMonthDays(activeMonth)).fill(undefined))

  const nextMonth = () => {
    const month = getNextMonth(activeMonth)
    setActiveMonth(month.month)
    setMonthDates(Array(getMonthDays(month.month)).fill(undefined))
  }

  const handleDatePick = (day) => {
    return () => {
      setChosenDate({
        day: day,
        month: activeMonth,
      })
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span className={styles.label}>Pick a date</span>
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <div className={styles.monthChip}>{CALENDAR_MONTHS_BY_ID[activeMonth]}</div>
          <img
            src={ArrowRight}
            alt={'Arrow right'}
            onClick={nextMonth}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
      <div className={styles.dates}>
        {Object.values(WEEK_DAYS).map((day) => (
          <span key={day}>{day}</span>
        ))}
        {monthDates.map((_, index) => (
          <div
            key={index}
            className={cls(styles.item, {
              [styles.active_date]:
                chosenDate.month === activeMonth && chosenDate.day === index + 1,
            })}
            onClick={handleDatePick(index + 1)}
          >
            {index + 1}
          </div>
        ))}
      </div>
    </div>
  )
}
