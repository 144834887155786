import { Text } from '@chakra-ui/react'
import { FormikInput } from '../../../components/elements/input'
import PropTypes from 'prop-types'
import React from 'react'

export const FirstStep = ({ formik, fieldErrors }) => (
  <>
    <FormikInput
      name="firstName"
      label="Legal First Name"
      placeholder="First Name"
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
      color="white"
    />
    <FormikInput
      name="lastName"
      label="Legal Last Name"
      placeholder="Last Name"
      required
      type="text"
      formik={formik}
      errors={fieldErrors}
      color="white"
    />
    <FormikInput
      name="phoneNumber"
      label="Cell Number"
      required
      type="tel"
      placeholder={'123-456-7890'}
      formik={formik}
      errors={fieldErrors}
      color="white"
    />
    <Text fontSize="12px" mt={1}>
      <strong>Example: 123-456-7890</strong> (please don’t include ‘-’ or ‘+1’)
    </Text>
  </>
)

FirstStep.propTypes = {
  formik: PropTypes.object.isRequired,
}
