// import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'

export const useCreateOrderMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/createOrder', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useCancelMyOrderMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put('/cancelMyOrder', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useUpdateOrderStatusMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put('/updateOrderStatus', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useAttachPhotosToOrderMutation = (id, options) => {
  const toast = useToast()
  return useMutation(
    async (data) => axios.put(`/attachPhotosToOrderPrescription?id=${id}`, data),
    {
      onError: (error) => {
        toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
      },
      ...options,
    }
  )
}

export const useRenewPrescriptionMutation = (id, options) => {
  const toast = useToast()
  return useMutation(
    async (data) => axios.put(`/attachQuestionnaireResponsesToOrderPrescription?id=${id}`, data),
    {
      onError: (error) => {
        toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
      },
      ...options,
    }
  )
}

export const useRefundMyOrderAndCancelSubscriptionMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put(`/refundMyOrderAndCancelSubscription`, data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useSyncFirstOrderPaymentStatusMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/syncFirstOrderPaymentStatus', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}
