import React, { useEffect } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Label } from 'components/layout/label'
import { APP_PATHS } from 'paths'
import { environment } from 'environments'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'

import {
  Box,
  VStack,
  Text,
  Button,
  Link as ChakraLink,
  useMediaQuery,
  UnorderedList,
  ListItem,
  HStack,
} from '@chakra-ui/react'
import { getLastSymbols } from 'shared/constants/helpers'

export const PaymentComplete = ({
  orderId,
  userId,
  profile,
  totalPrice,
  product,
  isPhoneNumberRequired = false,
}) => {
  const { email, firstName, lastName } = profile
  const { id: productId, isPrescriptionRequired } = product

  const token = localStorage.getItem('AUTH_TOKEN') || localStorage.getItem('REGISTRATION_TOKEN')

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: environment.GOOGLE_TM_SUCCESS_PAYMENT,
      dataLayerName: 'SuccessPayment',
      dataLayer: {
        userId: userId,
        orderId: orderId,
        email,
        firstName,
        lastName,
      },
    }
    TagManager.initialize(tagManagerArgs)

    const conversionEventData = {
      send_to: environment.GOOGLE_TM_SUCCESS_PAYMENT_CONVERSION,
      value: totalPrice / 100,
      currency: 'USD',
      transaction_id: orderId,
    }
    ReactGA.event('conversion', conversionEventData)
  }, [])

  const navigate = useNavigate()
  const [isMobile] = useMediaQuery('(max-width: 800px)')

  let redirectPath = generatePath(APP_PATHS.home)

  if (isPhoneNumberRequired) {
    redirectPath = generatePath(APP_PATHS.setPhoneNumber, { token })
  }

  if (isPrescriptionRequired && !isPhoneNumberRequired) {
    const path = generatePath(APP_PATHS.myProfileInitialPrescription, { id: productId })
    const searchParams = new URLSearchParams({ orderId })

    redirectPath = `${path}?${searchParams.toString()}`
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      gap="30px"
      padding="7% 8% 60px 8%"
    >
      <Label>Payment Succesful</Label>

      <VStack
        width={isMobile ? 'auto' : '400px'}
        margin="0 auto"
        spacing={4}
        align="flex-start"
        fontSize={'14px'}
        fontWeight={'400'}
        textAlign={'start'}
      >
        <Text mb={4}>Thank you for your order and welcome to the Maion community!</Text>

        <Text fontWeight="600">
          Your order ID is:{' '}
          <Text as="span" color={'var(--accent)'}>
            {getLastSymbols(orderId, 8)}
          </Text>
        </Text>

        <Text>Next we&apos;ll ask you to:</Text>

        <UnorderedList pl={'5'}>
          <ListItem>
            Enter and verify your phone number - This will be used by our physicians to
            communicate with you
          </ListItem>
          <ListItem>Collect your delivery address information</ListItem>
          <ListItem>
            Complete the confidential medical information eligibility form which will be passed
            to our team of physicians to review. In the majority of cases, indicated eligibility
            will result in a prescription being issued.
          </ListItem>
        </UnorderedList>

        <Text>
          If the clinician chooses to prescribe, you should expect your medication within 72
          hours of the confirmed prescription.
        </Text>
        <Text>
          If you have any questions or feedback, please contact us via email{' '}
          <ChakraLink
            color="var(--help-color)"
            fontWeight="600"
            href="mailto:help@maion.ai"
            textDecor={'underline'}
          >
            help@maion.ai
          </ChakraLink>
        </Text>
      </VStack>
      <HStack
        width={isMobile ? 'auto' : '400px'}
        mt={10}
        justifyContent={'center'}
        w={'100%'}
        spacing={5}
      >
        <Button
          colorScheme="pink"
          bg={'var(--accent)'}
          width="160px"
          borderRadius="8px"
          isDisabled={true}
          _disabled={{ backgroundColor: 'var(--secondary-gray)', pointerEvents: 'none' }}
        >
          Back
        </Button>
        <Button
          colorScheme="pink"
          bg={'var(--accent)'}
          width="160px"
          borderRadius="8px"
          onClick={() => {
            navigate(redirectPath)
          }}
        >
          {isPhoneNumberRequired || isPrescriptionRequired ? 'Next' : 'Home'}
        </Button>
      </HStack>
    </Box>
  )
}
