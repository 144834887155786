import React from 'react'

export const PaperFlightSvg = ({ ...args }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...args}
    >
      <path
        d="M16.1681 1.71253L4.68279 11.2773M7.38861 13.9932L10.0102 16.6009C10.1712 16.7658 10.3724 16.8858 10.5941 16.9491C10.8158 17.0124 11.0501 17.0167 11.274 16.9617C11.4991 16.9093 11.7069 16.7999 11.8774 16.644C12.048 16.4881 12.1755 16.291 12.2478 16.0716L16.552 3.17755C16.6421 2.93606 16.6608 2.67379 16.6059 2.42196C16.5511 2.17014 16.4249 1.93938 16.2426 1.75715C16.0602 1.57491 15.8293 1.44888 15.5773 1.39406C15.3253 1.33924 15.0628 1.35793 14.8212 1.44792L1.918 5.75036C1.6909 5.82769 1.4886 5.96424 1.332 6.14589C1.17541 6.32754 1.07022 6.54768 1.0273 6.78357C0.982963 6.99826 0.992754 7.22059 1.05579 7.43056C1.11882 7.64052 1.23312 7.83153 1.38839 7.98638L4.68279 11.2785L4.57446 15.4474L7.38861 13.9932Z"
        stroke="currentColor"
        strokeWidth="1.66"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
