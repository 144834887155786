import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  useColorModeValue,
  Icon,
  Flex,
  FormLabel,
  FormControl,
  Stack,
  VisuallyHidden,
  Text,
  Image,
} from '@chakra-ui/react'

export const FileUpload = ({ image, onChange, formControlProps }) => {
  const handleImageUpload = async (files) => {
    try {
      const file = files[0]

      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        onChange(reader.result)
      }
      reader.readAsDataURL(file)
    } catch (err) {
      console.log(err)
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    handleImageUpload(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
  })

  const activeBg = useColorModeValue('gray.100', 'gray.600')

  return (
    <>
      <FormControl {...formControlProps}>
        {!image && (
          <Flex
            mt={1}
            height="100%"
            justify="center"
            px={6}
            pt={5}
            pb={6}
            borderWidth={2}
            borderStyle="dashed"
            rounded="md"
            transition="background-color 0.2s ease"
            _hover={{ bg: activeBg }}
            bg={isDragActive ? activeBg : 'transparent'}
            {...getRootProps()}
          >
            <Stack spacing={1} textAlign="center" justifyContent="center">
              <Icon
                mx="auto"
                boxSize={12}
                color="gray.400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </Icon>
              <Flex fontSize="sm" color="gray.600" alignItems="baseline">
                <FormLabel
                  htmlFor="file-upload"
                  cursor="pointer"
                  rounded="md"
                  fontSize="md"
                  color="brand.600"
                  pos="relative"
                  _hover={{
                    color: 'brand.400',
                  }}
                >
                  <Text
                    fontSize="2xl"
                    color="gray.500"
                    fontFamily="Futura"
                    fontStyle="italic"
                    textAlign={'center'}
                  >
                    Drag and drop or click to upload
                  </Text>
                  <VisuallyHidden>
                    <input {...getInputProps()} />
                  </VisuallyHidden>
                </FormLabel>
              </Flex>
            </Stack>
          </Flex>
        )}
        {image && (
          <Image
            {...getRootProps()}
            alt="Id Photo"
            fit="contain"
            src={image ?? undefined}
            w="100%"
            h="100%"
          />
        )}
      </FormControl>
    </>
  )
}
