import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Questionnaires } from 'features/payment/components/questionnaries'
import { QUESTION_TYPES } from 'shared/constants/common'
import { useReadMyOrder } from 'shared/queries/order'
import {
  useAttachPhotosToOrderMutation,
  useRenewPrescriptionMutation,
} from 'shared/mutations/order'

import { isEmpty } from 'lodash'
import { useUserContextState } from 'shared/contexts/user-context-provider'
import { APP_PATHS } from 'paths'
import { CircularProgress, Flex } from '@chakra-ui/react'
import { InitialPrescriptionFailed } from 'features/payment/components/complete/InitialPrescriptionFailed'
import { InitialPrescriptionComplete } from 'features/payment/components/complete/initialPrescriptionComplete'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import { useReadMyUserProfile } from 'shared/queries/userProfile'
import { environment } from 'environments'

export const InitialPrescriptionPage = () => {
  const { isUserLoading, user } = useUserContextState()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const orderId = params.get('orderId')
  const { data: order, isLoading } = useReadMyOrder({ id: orderId })
  const { data: profile, isLoading: isProfileLoading } = useReadMyUserProfile()
  const [orderStatus, setOrderStatus] = useState(null)
  const [error, setError] = useState(null)
  const [files, setFiles] = useState(undefined)
  const [questionnairesResponsesSend, setQuestionnairesResponsesSend] = useState(false)

  const { mutate: uploadPhotos } = useAttachPhotosToOrderMutation(orderId, {
    onSuccess: () => {
      setOrderStatus('completed')
    },
    onError: (error) => {
      setOrderStatus('error')
      setError(error)
    },
  })

  const { mutate } = useRenewPrescriptionMutation(orderId, {
    onSuccess: () => {
      setQuestionnairesResponsesSend(true)
    },
    onError: (error) => {
      setOrderStatus('error')
      setError(error)
    },
  })

  useEffect(() => {
    if (!isUserLoading && isEmpty(user)) {
      navigate(APP_PATHS.registration)
    }
  }, [isUserLoading, user])

  useEffect(() => {
    if (files && orderStatus === 'loading' && questionnairesResponsesSend) {
      uploadPhotos({ photos: files })
    }
  }, [files, orderStatus, questionnairesResponsesSend])

  useEffect(() => {
    const totalPrice = order?.totalPriceInCents
    const productId = order?.items[0].productId

    if (questionnairesResponsesSend) {
      const tagManagerArgs = {
        gtmId: environment.GOOGLE_TM_SUCCESS,
        dataLayerName: 'SubscriptionInitialQuestionnaires',
        dataLayer: {
          userId: profile.id,
          productId: productId,
        },
      }
      const conversionEventData = {
        send_to: environment.GOOGLE_TM_SUCCESS_PAYMENT_CONVERSION,
        value: totalPrice / 100,
        currency: 'USD',
        transaction_id: orderId,
      }

      TagManager.initialize(tagManagerArgs)
      ReactGA.event('conversion', conversionEventData)
    }
  }, [questionnairesResponsesSend])

  const handleComplete = async (questionnairesResponses) => {
    setOrderStatus('loading')
    mutate({
      questionnairesResponses,
    })
  }
  if (isUserLoading || !user) {
    return null
  }
  if (orderStatus === 'completed') {
    return <InitialPrescriptionComplete orderId={orderId} />
  }
  if ((orderStatus === 'error' && !!error) || (!isLoading && !order)) {
    return (
      <InitialPrescriptionFailed
        orderId={orderId}
        message={error?.message || 'Could not load data'}
      />
    )
  }
  if (isLoading || isProfileLoading || !order || orderStatus === 'loading') {
    return (
      <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} py="9%" px="8%">
        <CircularProgress isIndeterminate color="var(--accent)" />
      </Flex>
    )
  }

  const productId = order.items[0].productId
  const packOfferId = order.packOfferIds[0]

  return (
    <Questionnaires
      productId={packOfferId ?? productId}
      orderId={orderId}
      type={QUESTION_TYPES.QUESTIONNAIRE}
      handleComplete={handleComplete}
      handleFiles={setFiles}
    />
  )
}
