import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Text,
  useToast,
  Flex,
  FormErrorMessage,
} from '@chakra-ui/react'
import { environment } from 'environments'

const isDevEnv = () => {
  return environment.ENVIRONMENT === 'development'
}

const isDevLoggedInChecker = () => {
  const isLoggedIn = localStorage.getItem('DEV_LOGGED_IN')
  return isLoggedIn === 'true'
}

const LoginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
})

const LoginDev = ({ siteName, onLogin }) => {
  const toast = useToast()

  const handleSubmit = (values, actions) => {
    if (isDevEnv()) {
      if (
        values.username === environment.DEV_USERNAME &&
        values.password === environment.DEV_PASSWORD
      ) {
        localStorage.setItem('DEV_LOGGED_IN', 'true')
        onLogin()
        toast({
          title: 'Login successful',
          status: 'success',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        })
      } else {
        toast({
          title: 'Invalid credentials',
          status: 'error',
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        })
      }
    } else {
      console.log('Development login not available in this environment')
    }
    actions.setSubmitting(false)
  }

  return (
    <Flex mt={8} mx={2} justifyContent="center">
      <Formik
        initialValues={{ username: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <VStack spacing={4} align="stretch">
              {siteName && (
                <Heading as="h1" size="xl" textAlign="center">
                  {siteName}
                </Heading>
              )}
              <Heading as="h2" size="xl" textAlign="center">
                Development Login
              </Heading>
              <Field name="username">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.username && form.touched.username}>
                    <FormLabel htmlFor="username">Username</FormLabel>
                    <Input {...field} id="username" placeholder="Username" />
                    <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="password">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.password && form.touched.password}>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <Input {...field} id="password" type="password" placeholder="Password" />
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                mt={4}
                colorScheme="pink"
                isLoading={props.isSubmitting}
                type="submit"
                width="full"
              >
                Login
              </Button>
              <Text fontSize="sm" color="gray.500" textAlign="center">
                This login is only for development purposes.
              </Text>
            </VStack>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

export { LoginDev, isDevEnv, isDevLoggedInChecker }
