import React, { useEffect, useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  //Input,
  Text,
  Stack,
} from '@chakra-ui/react'
import { QrCode } from '../../referrals/components/qrCode'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { APP_PATHS } from '../../../paths'

const extractSecretFromURI = (uri) => {
  const queryParams = new URLSearchParams(uri.split('?')[1])
  return queryParams.get('secret')
}

const OtpModal = ({ isOpen, user }) => {
  const navigate = useNavigate()
  const [showSecret, setShowSecret] = useState(false)
  const [timer, setTimer] = useState(0)
  useEffect(() => {
    setTimer(10)

    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1)
    }, 1000)

    return () => clearInterval(countdown)
  }, [isOpen])

  useEffect(() => {
    if (timer <= 0) {
      clearInterval(timer)
    }
  }, [timer])
  /*
  const [code, setCode] = useState()
  const validateSecret = () => {
    // Here you would typically send the secret to your server for validation
    // For this example, we'll just console log
    console.log('Validating secret: ', code, id)
    // Close the modal upon validation
  }
  */
  if (isEmpty(user)) {
    return null
  }
  const { otpAuthProtocolURI, email } = user
  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Google Authenticator 2FA Setup for {email}</ModalHeader>
        <ModalBody>
          <Text>Scan the QR code with your Google Authenticator app:</Text>
          <br />
          <Stack w="100%" alignItems="center">
            <QrCode link={otpAuthProtocolURI} />
          </Stack>
          <br />
          <Text>Or you can try enter secret into Google Authenticator app manually</Text>
          {showSecret && (
            <Text mt="4">Secret Code: {extractSecretFromURI(otpAuthProtocolURI)}</Text>
          )}
          <Button mt="4" onClick={() => setShowSecret(!showSecret)}>
            {showSecret ? 'Hide Secret Code' : 'Show Secret Code'}
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={timer > 0}
            colorScheme="blue"
            mr={3}
            onClick={() => navigate(APP_PATHS.home)}
          >
            Confirm {timer > 0 ? timer : ''}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default OtpModal
