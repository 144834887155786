import React from 'react'

export const ToggleButton = ({ open = false, setOpen }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      onClick={setOpen}
      style={open ? {} : { transform: 'rotate(180deg)' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8961 1.83203C11.7665 1.83203 2.74414 10.8544 2.74414 21.984C2.74414 33.1136 11.7665 42.136 22.8961 42.136C34.0257 42.136 43.0481 33.1136 43.0481 21.984C43.0481 10.8544 34.0257 1.83203 22.8961 1.83203ZM6.40811 21.984C6.40811 31.0901 13.79 38.472 22.8961 38.472C32.0022 38.472 39.3841 31.0901 39.3841 21.984C39.3841 12.8779 32.0022 5.496 22.8961 5.496C13.79 5.496 6.40811 12.8779 6.40811 21.984ZM17.7153 16.802L15.1244 19.3929L22.897 27.1653L30.6694 19.3929L28.0786 16.802L22.897 21.9837L17.7153 16.802Z"
        fill="#171717"
      />
    </svg>
  )
}
