import MasterCard from 'shared/icons/cards/mastercard.svg'
import Visa from 'shared/icons/cards/visa.svg'
import VisaWhite from 'shared/icons/cards/visaWhite.svg'
import AmEx from 'shared/icons/cards/amex.svg'
import Unknown from 'shared/icons/cards/code.svg'
import Discover from 'shared/icons/cards/discover.svg'
import Diners from 'shared/icons/cards/diners.svg'
import JCB from 'shared/icons/cards/jcb.svg'
import UnionPay from 'shared/icons/cards/unionpay.svg'

export const DEFAULT_CARD = {
  name: 'Unknown',
  icon: MasterCard,
  iconLight: MasterCard,
}

const CARD_BRANDS = {
  visa: {
    name: 'Visa',
    icon: Visa,
    iconLight: VisaWhite,
  },
  amex: {
    name: 'American Express',
    icon: AmEx,
    iconLight: AmEx,
  },
  mastercard: {
    name: 'MasterCard',
    icon: MasterCard,
    iconLight: MasterCard,
  },
  discover: {
    name: 'Discover',
    icon: Discover,
    iconLight: Discover,
  },
  diners: {
    name: 'Diners Club',
    icon: Diners,
    iconLight: Diners,
  },
  jcb: {
    name: 'JCB',
    icon: JCB,
    iconLight: JCB,
  },
  unionpay: {
    name: 'UnionPay',
    icon: UnionPay,
    iconLight: UnionPay,
  },
  unknown: {
    name: 'Unknown',
    icon: Unknown,
    iconLight: Unknown,
  },
}

export const getCardBrand = (brand) => CARD_BRANDS[brand] || DEFAULT_CARD
