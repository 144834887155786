import React from 'react'
import { default as ReactModal } from 'react-modal'
import styles from './styles.module.css'
import { ReferralCodeModal, ReferralModal } from './referralsModal'
import { StatesModal } from './statesModal/statesModal'
import { NewsLettersModal } from './newsLettersModal'

ReactModal.setAppElement('#root')

const customStyles = {
  overlay: {
    zIndex: 10,
    background: 'rgba(255, 255, 255, 0.5)',
    overflowY: 'auto',
  },
  content: {
    background: 'transparent',
    border: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}
export const Modal = ({ modalIsOpen, close, onConfirm, type, referralCode }) => {
  return (
    <ReactModal
      isOpen={modalIsOpen}
      style={customStyles}
      onRequestClose={close}
      contentLabel=""
      className={styles.modal}
    >
      {type === 'referrals' && (
        <ReferralModal
          onConfirm={() => {
            close()
            onConfirm()
          }}
        />
      )}
      {type === 'referralCode' && <ReferralCodeModal referralCode={referralCode} />}
      {type === 'states' && <StatesModal />}
      {type === 'newsLetters' && <NewsLettersModal onConfirm={onConfirm} />}
    </ReactModal>
  )
}
