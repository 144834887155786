import React from 'react'
import Doctor from 'shared/images/doctors/doctor1.png'
import Doctor2 from 'shared/images/doctors/doctor2.png'
import Doctor5 from 'shared/images/doctors/doctor5.png'

import styles from './styles.module.css'

import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

const doctors = [
  {
    text: '“At Maion, our goal is to provide superior quality GLP-1 medications, combined with community-centric support and personalized data-driven health care solutions to assist patients on their weight loss journey.”',
    name: 'Dr. David H. Rahm, M.D.',
    image: Doctor,
  },
  {
    text: '“Maion is unique in that it offers complete transparency to empower each user to take ownership of their health. The product granting individuals the ability to focus on what is most important to them wherever they are in their journey to wellbeing.”',
    name: 'Grace Rochman, MSN-NP-PC.',
    image: Doctor2,
  },
  {
    text: '“One of the most compelling things for me about Maion is that it is one of only a small number of compound pharmacies that utilizes board of pharmacy (NABP) and United States pharmacopeia (USP) standards for a safe and effective product.”',
    name: 'Randy Acosta',
    image: Doctor5,
  },
]

const DoctorSlide = ({ text, name, image }) => {
  return (
    <div className={styles.doctor_wrapper}>
      <div className={styles.texts}>
        <p>{text}</p>
        <span>{name}</span>
      </div>
      <img src={image} alt={`${name} Photo`} />
    </div>
  )
}

const indicators = () => <div className="indicator" />

export const MainPageDoctorsSlider = () => {
  return (
    <div className="slide-container" style={{ width: '100%', marginTop: '30px' }}>
      <Slide
        autoplay={doctors.length > 1}
        duration={2500}
        infinite={true}
        canSwipe={false}
        arrows={false}
        indicators={doctors.length > 1 ? indicators : null}
        pauseOnHover
        slidesToScroll={1}
        slidesToShow={1}
        easing={'ease'}
      >
        {doctors.map((doctor) => (
          <DoctorSlide key={doctor.name} {...doctor} />
        ))}
      </Slide>
    </div>
  )
}
