import { UserSvg } from 'shared/icons/misc/user'
import { CalendarSvg } from 'shared/icons/misc/calendar'
import { MoneySvg } from 'shared/icons/misc/money'
import { BlockSvg } from 'shared/icons/misc/block'
// import { CircleSvg } from 'shared/icons/misc/circle'
import { HandsSvg } from 'shared/icons/misc/hands'
import { APP_PATHS } from 'paths'

export const LeftMenuLinks = [
  {
    image: CalendarSvg,
    text: 'My Journey',
    link: APP_PATHS.myProfile,
  },
  {
    image: HandsSvg,
    text: 'Invite a Friend',
    link: APP_PATHS.myProfileReferralPage,
  },
  {
    image: MoneySvg,
    text: 'Billing & Payment Details',
    link: APP_PATHS.myProfileBillingPage,
  },
  {
    image: BlockSvg,
    text: 'Returns & Cancellations',
    link: APP_PATHS.myProfileReturnsPage,
  },
  {
    image: UserSvg,
    text: 'User Profile',
    link: APP_PATHS.myProfileUserPage,
  },
  // {
  //   image: CircleSvg,
  //   text: 'About Maion',
  //   link: APP_PATHS.myProfileAboutPage,
  // },
]
