import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Text, Image, Button, Link as ChakraLink, VStack, Flex } from '@chakra-ui/react'
import { Label } from 'components/layout/label'
import { APP_PATHS } from 'paths'

import CreditCardChecked from 'shared/icons/misc/creditCardChecked.svg'
import { getLastSymbols } from 'shared/constants/helpers'

export const InitialPrescriptionComplete = ({ orderId }) => {
  const navigate = useNavigate()

  return (
    <Box
      p={8}
      maxW="800px"
      mx="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="30px"
      padding="7% 8% 60px 8%"
    >
      <Label>Confirmation</Label>
      <VStack spacing={12} textAlign="center" maxW={'400px'}>
        <Flex bg="var(--accent)" borderRadius="20px" boxSize={'85px'} justifyContent={'center'}>
          <Image src={CreditCardChecked} alt="Renew complete" />
        </Flex>
        <Text fontSize="18px" fontWeight="bold">
          Medical intake questionary succesfully submitted to our team of physicians!
        </Text>
        <Button
          colorScheme="pink"
          bg="var(--accent)"
          width="250px"
          borderRadius="10px"
          fontWeight="600"
          onClick={() => navigate(APP_PATHS.myProfileBillingPage)}
        >
          Review Your Subscription
        </Button>
        <Box textAlign={'start'}>
          <Text fontWeight="600" mb={2}>
            Your order ID is:{' '}
            <Text as="span" color={'var(--accent)'}>
              {getLastSymbols(orderId, 8)}
            </Text>
          </Text>
          <Text fontSize="16px" color="var(--gray-text)" fontWeight="400">
            For any reason you wish to cancel your subscription please email us here{' '}
            <ChakraLink
              href="mailto:help@maion.ai"
              color="var(--help-color)"
              fontWeight="600"
              textDecoration="underline"
            >
              help@maion.ai
            </ChakraLink>
          </Text>
        </Box>
      </VStack>
    </Box>
  )
}
