import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { isEmpty, size } from 'lodash'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'

import { Label } from 'components/layout/label'
import PayPalIcon from 'shared/icons/socials/paypal.svg'
import AppleIcon from 'shared/icons/socials/appleDark.svg'
import RoundArrow from 'shared/icons/misc/roundArrow.svg'

import CreditCardIcon from 'shared/icons/misc/creditCard.svg'
import CheckIconActive from 'shared/icons/misc/paymentCheckActive.svg'
import CheckIconNotActive from 'shared/icons/misc/paymentCheckNotActive.svg'

import { ToggleButton, CardSkeleton } from 'shared/components'
//import { CardData } from 'shared/components/CardData/CardData'
import { useReadMyOrder } from 'shared/queries/order'
import { useReadMyUserProfile } from 'shared/queries/userProfile'
import { useIndexProducts } from 'shared/queries/product'
import { useIndexMyPaymentMethods } from 'shared/queries/paymentMethod'
import { useCreatePaymentMutation } from 'shared/mutations/payment'
import { useCreatePaymentMethodMutation } from 'shared/mutations/paymentMethod'

import { PaymentComplete } from './components/complete/paymentComplete'
import { PaymentFailed } from './components/complete/paymentFailed'
import { StripePayment } from './components/stripe/stripe'

import { environment } from 'environments'
import styles from './styles.module.css'

import { APP_PATHS } from 'paths'

const defaultPaymentsMethods = [
  {
    type: 'paypal',
    image: PayPalIcon,
    name: 'PayPal',
    dataToIdentify: 'NickFroehlich@gmail.com',
  },
  {
    type: 'applePay',
    image: AppleIcon,
    name: 'Apple Pay',
    dataToIdentify: '**** 9000',
  },
]

const stripePromise = loadStripe(environment.STRIPE_PUBLIC_KEY)

const PaymentPage = ({ additionalPaymentsMethods }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)
  const [cardsIsOpen, setCardsIsOpen] = useState(true)
  const [activePaymentType, setActivePaymentType] = useState('')
  const [isAddCard, setIsAddCard] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [selectedCard, setSelectedCard] = useState(0)
  const { id } = useParams()

  const [showPaymentComplete, setShowPaymentComplete] = useState(false)
  const [showPaymentError, setShowPaymentError] = useState(false)
  const { data: profile, isLoading: isProfileLoading } = useReadMyUserProfile()
  const { data: order } = useReadMyOrder({ id })
  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } = useIndexMyPaymentMethods({
    status: 'ACTIVE',
  })
  const { data: products = [], isLoading: isProductsLoading } = useIndexProducts({
    isForIndividualSale: true,
  })
  const { mutateAsync: createPaymentMethod } = useCreatePaymentMethodMutation()
  const { mutateAsync: createPayment, isLoading } = useCreatePaymentMutation()

  const handleMethod = (type) => {
    return () => setActivePaymentType(type)
  }

  const handleSubmit = async (values) => {
    if (isAddCard) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }

      setProcessing(true)
      const card = elements.getElement(CardElement)

      if (card == null) {
        setProcessing(false)
        return
      }

      if (error) {
        setProcessing(false)
        card.focus()
        return
      }
      const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: values.name,
          email: values.email,
        },
      })
      if (paymentError) {
        setProcessing(false)
        setError(paymentError)
        return
      }
      const paymentParams = {
        orderId: order?.id,
      }
      const confirmPaymentParams = {}
      if (values.saveData) {
        const savedPaymentMethod = await createPaymentMethod(paymentMethod.id)
        paymentParams.paymentMethodId = savedPaymentMethod.id
      } else {
        confirmPaymentParams.payment_method = paymentMethod.id
      }
      const data = await createPayment(paymentParams)

      const { error: confirmError } = await stripe.confirmCardPayment(
        data.clientSecret,
        confirmPaymentParams
      )

      if (confirmError) {
        setError(confirmError.message)
        setShowPaymentError(true)
      } else {
        setShowPaymentComplete(true)
      }

      setProcessing(false)
    } else if (selectedCard > 0) {
      if (!stripe) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return
      }

      setProcessing(true)

      if (error) {
        setProcessing(false)
        return
      }
      const savedPaymentMethod = paymentMethods[selectedCard - 1]
      const paymentParams = {
        orderId: order?.id,
        paymentMethodId: savedPaymentMethod.id,
      }
      let data
      try {
        data = await createPayment(paymentParams)
      } catch (e) {
        setError(e.message)
        setShowPaymentError(true)
        return
      }

      const { error: confirmError } = await stripe.confirmCardPayment(data.clientSecret)

      if (confirmError) {
        setError(confirmError.message)
        setShowPaymentError(true)
      } else {
        setShowPaymentComplete(true)
      }
    }
  }

  const cardSchema = Yup.object({
    name: Yup.string()
      .required('Card Holder name is required')
      .max(20, 'Name cannot be longer than 20 characters'),
    email: Yup.string().email('Invalid email').required('Email Address is required'),
    phone: Yup.string(),
  })
  const formik = useFormik({
    initialValues: {
      name: profile ? `${profile.firstName} ${profile.lastName}` : '',
      email: profile?.email || '',
      saveData: false,
    },
    validationSchema: cardSchema,
    onSubmit: handleSubmit,
  })
  useEffect(() => {
    if (!isProfileLoading && !isEmpty(profile)) {
      formik.setFieldValue('name', `${profile.firstName} ${profile.lastName}`)
      formik.setFieldValue('email', profile?.email)
    }
  }, [profile, isProfileLoading])
  const toggleCards = useCallback(() => {
    setCardsIsOpen((prevState) => !prevState)
  }, [cardsIsOpen, setCardsIsOpen])
  if (!order || isProductsLoading || isPaymentMethodsLoading) {
    return <div className={styles.wrapper}>Loading</div>
  }
  const { id: orderId, items, totalPriceInCents } = order
  const { productId, amount } = items[0]
  const product = products.find(({ id }) => id === productId)

  const readyToConfirm = (selectedCard === 0 && isAddCard && formik.isValid) || selectedCard > 0

  if (showPaymentComplete) {
    return <PaymentComplete orderId={orderId} />
  }

  if (showPaymentError) {
    return <PaymentFailed message={error} />
  }
  return (
    <div className={styles.wrapper}>
      <Label>Payment</Label>
      <div className={styles.payment_wrapper}>
        <div className={`${styles.payment_part_column} ${styles.payment_left_part}`}>
          {isAddCard ? (
            <StripePayment
              isLoading={isLoading}
              formik={formik}
              error={error}
              setError={setError}
            />
          ) : (
            <>
              <div className={styles.credit_card_block}>
                <div>
                  <img src={CreditCardIcon} alt={'Credit card icon'} />
                  <p>Credit card</p>
                  <ToggleButton open={!cardsIsOpen} setOpen={toggleCards} />
                </div>
                {cardsIsOpen && (
                  <div className={styles.credit_card_block_card_wrapper}>
                    <div className={styles.card_wrapper}>
                      <div className={styles.card}>
                        {selectedCard === 0 ? (
                          <CardSkeleton totalPriceInCents={totalPriceInCents} />
                        ) : (
                          <CardSkeleton
                            totalPriceInCents={totalPriceInCents}
                            mask={paymentMethods[selectedCard - 1]?.cardNumberMask}
                            saved={true}
                          />
                        )}
                      </div>
                      <div
                        className={styles.card_back}
                        style={{
                          background: 'var(--gray-text-secondary)',
                          right: '-10px',
                          height: '86%',
                          zIndex: 3,
                        }}
                      />
                      <div
                        className={styles.card_back}
                        style={{
                          background: 'var(--secondary-bg)',
                          right: '-20px',
                          height: '70%',
                          zIndex: 2,
                        }}
                      />
                    </div>

                    <div className={styles.switch_method}>
                      {selectedCard > 0 && (
                        <img
                          className={styles.prev_button}
                          src={RoundArrow}
                          alt={'Prev'}
                          onClick={() => setSelectedCard((prevState) => prevState - 1)}
                        />
                      )}
                      Switch Payment Method
                      {size(paymentMethods) > selectedCard && (
                        <img
                          src={RoundArrow}
                          alt={'Next'}
                          onClick={() => setSelectedCard((prevState) => prevState + 1)}
                        />
                      )}
                    </div>
                    {selectedCard === 0 && (
                      <button onClick={() => setIsAddCard(true)}>Add Card</button>
                    )}
                  </div>
                )}
              </div>

              {additionalPaymentsMethods.map((method) => (
                <div key={method.type} onClick={handleMethod(method.type)}>
                  <img src={method.image} alt={method.type + 'icon'} />
                  <div className={styles.payment_method_wrapper}>
                    <span className={styles.payment_method_name}>{method.name}</span>
                    <span className={styles.payment_method_additional_data}>
                      {method.dataToIdentify}
                    </span>
                  </div>
                  <img
                    src={
                      activePaymentType === method.type ? CheckIconActive : CheckIconNotActive
                    }
                    alt={'status'}
                  />
                </div>
              ))}
            </>
          )}
        </div>
        <div className={styles.payment_part_column}>
          <div className={styles.pencil_wrapper}>
            <img src={product.imageUrl} alt={'_'} />
          </div>
          <div className={styles.payment_item_text}>
            <p>{product.title}</p>
            <span>Quantity: {amount}</span>
          </div>
          <div className={styles.payment_disclaimer}>
            You will not be charged if you don’t qualify through the our Physicians assessment
            process. Please follow the link for our full{' '}
            <Link
              to={APP_PATHS.termsAndConditions}
              target={'_blank'}
              referrerPolicy={'no-referrer'}
            >
              Terms & Conditions
            </Link>{' '}
            and <Link to="#">Returns Policy.</Link>
          </div>
          <div className={styles.buttons}>
            <button
              className={styles.back}
              onClick={() => {
                setIsAddCard(false)
                setProcessing(false)
              }}
            >
              Back
            </button>
            <button
              disabled={!!error || !readyToConfirm || processing}
              className={styles.continue}
              onClick={() => handleSubmit(formik.values)}
            >
              {processing ? 'Processing' : 'Confirm'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const Page = ({ additionalPaymentsMethods = defaultPaymentsMethods }) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentPage additionalPaymentsMethods={additionalPaymentsMethods} />
    </Elements>
  )
}
