import React from 'react'

export const HomeSvg = ({ ...args }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      {...args}
    >
      <path
        d="M1.37436 6.74754L7.19038 0.81858L13.0064 6.74754C13.1616 6.90572 13.2499 7.12048 13.2499 7.34879V12.0439C13.2499 12.1703 13.1512 12.25 13.062 12.25H9.66348C9.58051 12.25 9.488 12.1766 9.488 12.0566V9.21675C9.488 7.94532 8.47241 6.88908 7.19038 6.88908C5.90835 6.88908 4.89276 7.94532 4.89276 9.21675V12.0566C4.89276 12.1766 4.80026 12.25 4.71729 12.25H1.31872C1.22956 12.25 1.13086 12.1703 1.13086 12.0439V7.34879C1.13086 7.12048 1.21919 6.90572 1.37436 6.74754Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  )
}
