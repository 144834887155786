import React from 'react'
import styles from './styles.module.css'
import { Label } from 'components/layout/label'

import AppleIcon from 'shared/icons/socials/apple.svg'
import GoogleIcon from 'shared/icons/socials/google.png'
import { useNavigate } from 'react-router-dom'
import { APP_PATHS } from '../../paths'

export const AddToCalendar = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.wrapper}>
      <Label>Your Appointment</Label>
      <div className={styles.wrapper_data_add_to_calendar}>
        <div>
          <p>Add to calendar</p>
          <div>
            <img src={AppleIcon} alt={'Apple icon'} />
            <img src={GoogleIcon} alt={'Google icon'} />
          </div>
        </div>
        <button className={styles.skip_button} onClick={() => navigate(APP_PATHS.home)}>
          Skip
        </button>
      </div>
    </div>
  )
}
