import React from 'react'
import styles from './button.module.css'

const buttonStylesMap = {
  accent: styles.button_accent,
  grey: styles.grey,
}

/**
 * @param {React.ReactChild} children
 * @param {'accent' | 'grey'} variant
 * @param {string} className
 * @param {unknown} rest
 * **/
export const Button = ({ children, variant = 'accent', className = '', ...rest }) => {
  return (
    <button className={`${styles.button} ${buttonStylesMap[variant]} ${className}`} {...rest}>
      {children}
    </button>
  )
}
