import React, { useMemo } from 'react'
import { capitalize, includes } from 'lodash'
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

import styles from './cancellationTable.module.css'
import { USDollar } from 'shared/utils/currenciesFormatters'
import { useIndexActivePackOffers } from 'shared/queries/packOffer'
import { useIndexProducts } from 'shared/queries/product'
import { useCancelMySubscriptionMutation } from 'shared/mutations/subscription'
//import { Button } from 'shared/components'

const columnHelper = createColumnHelper()

const CancelButton = ({ cancelSubscription, info, isLoading }) => {
  const id = info.getValue()
  const { status } = info.row.original
  const { isOpen, onOpen, onClose } = useDisclosure()
  const active = includes(['ACTIVE', 'PENDING'], status)
  if (!active) {
    return null
  }
  const confirmCancellation = () => {
    cancelSubscription(id)
    onClose()
  }
  return (
    <>
      <CancelModal
        isModalOpen={isOpen}
        onClose={onClose}
        confirmCancellation={confirmCancellation}
      />
      <Button colorScheme="gray" disabled={isLoading} onClick={onOpen}>
        Cancel Subscriptions
      </Button>
    </>
  )
}

const CancelModal = ({ isModalOpen, onClose, confirmCancellation }) => (
  <Modal isOpen={isModalOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Confirmation</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        Are you sure you want to cancel subscription? It affects only orders from next month.
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="gray" mr={3} onClick={onClose}>
          Close
        </Button>
        <Button colorScheme="pink" onClick={confirmCancellation}>
          Confirm
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
)

export const CancellationTable = ({ subscriptions, refetchSubscriptions }) => {
  const { mutate: cancelSubscription, isLoading } = useCancelMySubscriptionMutation({
    onSettled: () => refetchSubscriptions(),
  })
  const { data: products = [], isLoading: isProductsLoading } = useIndexProducts({
    isForIndividualSale: true,
  })
  const { data: packOffers = [], isLoading: isPackOffersLoading } = useIndexActivePackOffers()
  const data = useMemo(() => {
    if (isProductsLoading || isPackOffersLoading || (!products && !packOffers)) {
      return []
    }
    return subscriptions.map((subscription) => {
      const { id, status, items, packOfferIds, totalPriceInCents } = subscription
      const productId = items?.[0]?.productId
      const packOfferId = packOfferIds?.[0]
      const item = {
        id,
        planName: 'Maion Semaglutide',
        billingCycle: 'Monthly',
        cost: totalPriceInCents,
        status: status,
      }
      if (packOfferId) {
        const packOffer = packOffers.find(({ id }) => id === packOfferId)
        item.planName = packOffer.title
      } else if (productId) {
        const product = products.find(({ id }) => id === productId)
        item.planName = product?.title ?? 'Unknown product'
      }
      return item
    })
  }, [subscriptions, isPackOffersLoading, isProductsLoading, products, packOffers])
  const columns = [
    columnHelper.accessor('planName', {
      cell: (info) => info.getValue(),
      header: () => <span>PLAN NAME</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('billingCycle', {
      cell: (info) => info.getValue(),
      header: () => <span>BILLING CYCLE</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('cost', {
      header: () => <span>PLAN COST</span>,
      cell: (info) => USDollar.format(info.getValue() / 100),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('status', {
      header: () => <span>STATUS</span>,
      cell: (info) => {
        const status = info.getValue()
        let colorScheme
        if (status === 'ACTIVE') {
          colorScheme = 'green'
        } else if (status === 'CANCELED') {
          colorScheme = 'red'
        } else if (status === 'PENDING') {
          colorScheme = 'yellow'
        }
        return <Badge colorScheme={colorScheme}>{capitalize(status)}</Badge>
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('id', {
      header: () => '',
      cell: (info) => (
        <CancelButton
          isLoading={isLoading}
          cancelSubscription={cancelSubscription}
          info={info}
        />
      ),
      footer: (info) => info.column.id,
    }),
  ]
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className={styles.wrapper}>
      <div className={styles.table_header}>
        <p className={styles.label}>Cancellation</p>
      </div>
      <div className={styles.table_scroll}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
