import { JourneyPage } from './pages/journeyPage'
import { BasketPage } from './basket/page'
import { BillingPage } from './pages/billingPage'
import { AboutPage } from './pages/aboutPage'
import { ReferralPage } from './pages/referralPage'
import { ReturnsPage } from './pages/returnsPage'
import { UserPage } from './pages/userPage'
import { MessagesPage } from './pages/messagesPage'
import { RenewPrescriptionPage } from './pages/renewPrescriptionPage'
import { InitialPrescriptionPage } from './pages/initialPrescriptionPage'

const ProfilePages = {
  JourneyPage,
  BasketPage,
  BillingPage,
  AboutPage,
  ReferralPage,
  ReturnsPage,
  UserPage,
  MessagesPage,
  RenewPrescriptionPage,
  InitialPrescriptionPage,
}

export { JourneyPage as ProfilePage, BasketPage, ProfilePages }
