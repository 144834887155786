import React, { useMemo } from 'react'

import Graph from './assets/graph.svg'
import styles from './UserProgressStatus.module.css'

import CupImage from './assets/cup.svg'
import StarsImage from './assets/stars.svg'
import HumanImage from './assets/human.svg'

import { DirectionArrow } from '../directionArrow/DirectionArrow'

export const UserProgressStatus = ({ data }) => {
  // const { weightData, nextTask, achievements, progress } = data
  const { weightData, progress, achievements } = data
  const progressPercent =
    (weightData.start - weightData.current) / (weightData.start - weightData.goal ?? 1)
  const radius = useMemo(() => (progressPercent ?? 0) * 3.6, [progressPercent])
  return (
    <div className={styles.wrapper}>
      <div className={styles.graph_wrapper}>
        <div className={styles.accent_color} style={{ zIndex: 2 }}>
          <span className={styles.amount}>{Math.abs(progress.amount)}</span>
          <span className={styles.amount_small_text}>lb</span>
        </div>
        <span className={`${styles.text} ${styles.black}`}>Pre Week</span>
        <DirectionArrow direction={progress.direction} />
        <div className={styles.indicator_wrapper} style={{ transform: `rotate(-${radius}deg)` }}>
          <div className={styles.indicator} />
        </div>
        <div className={styles.graph_wrapper_bg} />
        <img src={Graph} alt={''} className={styles.graph} style={{ display: 'none' }} />
      </div>
      <WeightBlock weightData={weightData} />
      <CurrenWeightBlock weightData={weightData} />
      <AchievementsBlock achievements={achievements} />
    </div>
  )
}

const WeightBlock = ({ weightData }) => {
  return (
    <div className={styles.weight_block}>
      <div className={`${styles.gray}`}>
        <div style={{ height: '20px' }}>
          <span className={styles.big_text}>{weightData.start || 'N/A '}</span>
          <span className={styles.small_text}>lb</span>
        </div>
        <span className={styles.small_text}>Starting Weight</span>
      </div>
      <div className={`${styles.black}`}>
        <div style={{ height: '20px' }}>
          <span className={styles.big_text}>{weightData.goal || 'N/A '}</span>
          <span className={styles.small_text}>lb</span>
        </div>
        <span className={styles.small_text}>Goal Weight</span>
      </div>
    </div>
  )
}

const CurrenWeightBlock = ({ weightData }) => {
  return (
    <div className={styles.current_weight_block}>
      <div className={styles.accent_color}>
        <span className={styles.big_text}>{weightData.current || 'N/A '}</span>
        <span className={styles.small_text}>lb</span>
      </div>
      <span className={styles.small_text}>Current Weight</span>
    </div>
  )
}
// const NextTask = ({ nextTask }) => {
//   return (
//     <div className={styles.next_task_block}>
//       <p className={styles.label}>Next Task</p>
//       <img src={nextTask.icon} alt={''} />
//       <span className={styles.text}>{nextTask.text}</span>
//     </div>
//   )
// }

const AchievementsBlock = ({
  achievements = { targetsHit: 0, activeWeeks: 0, weeksToGo: 0 },
}) => {
  return (
    <div className={styles.achievements}>
      <div className={styles.row}>
        <img src={CupImage} alt={'Cup'} />
        <p>
          <span className={styles.amount}>{achievements.targetsHit}</span>
          <span className={styles.text}>TASKS COMPLETE</span>
        </p>
      </div>
      <div className={styles.row}>
        <img src={StarsImage} alt={'Stars'} />
        <p>
          <span className={styles.amount}>{achievements.activeWeeks}</span>
          <span className={styles.text}>WEEKS ACTIVE</span>
        </p>
      </div>
      <div className={styles.row}>
        <img src={HumanImage} alt={'Human'} />
        <p>
          <span className={styles.amount}>{achievements.weeksToGo}</span>
          <span className={styles.text}>
            WEEKS TO NEXT
            <br />
            PRESCRIPTION
          </span>
        </p>
      </div>
    </div>
  )
}
