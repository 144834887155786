import React, { useCallback, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import styles from './subscriptionProductsSlider.module.css'

import { useBasketStore } from 'shared/stores/basketStore'
import {
  Box,
  Text,
  HStack,
  VStack,
  UnorderedList,
  ListItem,
  Image,
  Flex,
  CircularProgress,
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import ReactGA from 'react-ga4'
import { Slide as Slideshow } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import ArrowSlider from 'shared/icons/misc/arrowSlider.svg'
import { useMediaQuery } from '../../../shared/hooks/useMediaQuery'
import { RxIcon } from '../../../shared/icons/rxIcon'
import { StockBadge } from '../StockBadge'
import { APP_PATHS } from '../../../paths'
import { useProcessUserActionMutation } from 'shared/mutations/userActions'
import DOMPurify from 'dompurify'

const ArrowPrev = (
  <Image
    src={ArrowSlider}
    alt={'Arrow prev'}
    boxSize="24px"
    style={{ marginLeft: '-30px', marginTop: 'calc(5rem + 10%)', alignSelf: 'flex-start' }}
  />
)

const ArrowNext = (
  <Image
    src={ArrowSlider}
    alt={'Arrow next'}
    boxSize="24px"
    style={{
      marginRight: '-30px',
      marginTop: 'calc(5rem + 10%)',
      alignSelf: 'flex-start',
      transform: 'scaleX(-1)',
    }}
  />
)

const Slide = ({ card, footnoteIndex }) => {
  const isMobile = useMediaQuery('(max-width: 800px)')
  const { addItem, reduceItemAmount } = useBasketStore()
  const navigate = useNavigate()
  const { mutate: processUserAction } = useProcessUserActionMutation()

  const productItem = (product) => ({
    currency: 'USD',
    value: (product?.priceInCents || product?.totalPriceInCents || 0) / 100,
    items: [
      {
        item_id: product?.id,
        item_name: product?.title,
        affiliation: product?.medName,
        price: (product?.priceInCents || product?.totalPriceInCents || 0) / 100,
        ndc: product?.ndc,
        sku: product?.sku,
      },
    ],
  })

  const addOneItemToBasket = useCallback(() => {
    addItem(card)
    reduceItemAmount(card.id, 1)
    processUserAction({
      eventName: 'ADDED_TO_CART',
      productId: card.id,
    })
    //https://developers.google.com/tag-platform/gtagjs/reference/events#add_to_cart
    ReactGA.event('add_to_cart', productItem(card))
    navigate(APP_PATHS.basket)
  }, [addItem, reduceItemAmount])
  const {
    status,
    id,
    title,
    imageUrl,
    thirdImageUrl,
    isPrescriptionRequired,
    isRegisteredTradeMark,
    description,
    additionalDescriptionText,
    priceInCents,
    defaultCoupon,
    priceLabelBottomText,
  } = card
  const paymentPath = generatePath(APP_PATHS.product, {
    id,
  })
  const originalPriceInDollar = priceInCents / 100

  const priceWithCouponInDollar = () => {
    const discountAmount = defaultCoupon?.discount
      ? originalPriceInDollar * defaultCoupon?.discount
      : defaultCoupon?.discountAmount
    const totalAmountWithDiscount = (priceInCents - discountAmount) / 100
    return Math.round(totalAmountWithDiscount)
  }
  const isCouponDefault = !!defaultCoupon

  const config = {
    ALLOWED_TAGS: ['ul', 'li'],
  }
  const cleanAdditionalDescriptionText = DOMPurify.sanitize(additionalDescriptionText, config)

  return (
    <VStack height={'100%'} w={'100%'} justifyContent={'space-between'}>
      <VStack w={'100%'}>
        <VStack
          rounded={4}
          bgGradient="linear-gradient(0deg, rgba(230, 224, 242, 0.80) 0%, rgba(198, 183, 230, 0.80) 100%)"
          w="100%"
          h={{ base: '400px', md: '450px' }}
          borderRadius="15px"
          cursor="pointer"
          onClick={() => navigate(paymentPath)}
          overflow="hidden"
          position={'relative'}
          mb={'24px'}
        >
          <HStack alignSelf="start" paddingInline={'24px'} paddingTop={'30px'} zIndex={1}>
            <Text color="white" fontSize={28} fontWeight={600} textAlign={'left'}>
              {title}
            </Text>
            {isRegisteredTradeMark && <Text color="white">®</Text>}
            {isPrescriptionRequired && <RxIcon />}
          </HStack>
          <Box paddingInline={'24px'} alignSelf={'start'} zIndex={1} width={'fit-content'}>
            <StockBadge status={status} />
          </Box>
          <Image
            src={thirdImageUrl ?? imageUrl}
            alt={`${title} image`}
            style={{
              objectFit: 'contain',
              position: 'absolute',
              height: '100%',
              maxWidth: '100%',
              bottom: 0,
              right: 0,
            }}
          />
          {isCouponDefault ? (
            <Box
              position="absolute"
              bottom="24px"
              left="0"
              bgColor={status !== 'IN_STOCK' ? '#808080' : '#D537A8'}
              h={{ base: '65px', lg: '65px', xl: '80px' }}
              maxW={{ base: '110px', lg: '120px', xl: '140px' }}
              color="white"
              borderRightRadius={6}
              px={2}
              py={1}
            >
              <VStack alignItems="flex-start" spacing={0} className={styles.price_label}>
                <Text fontSize={{ base: '8px', xl: '10px' }} fontWeight="bold">
                  Was:
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="bold"
                  pl={2}
                  lineHeight="12px"
                  className={styles.cross}
                >
                  ${originalPriceInDollar}
                </Text>
              </VStack>
              <Text
                fontSize={{ base: 'xl', lg: '2xl', xl: '3xl' }}
                fontWeight="bold"
                m={0}
                lineHeight={{ base: '20px', lg: '24px', xl: '30px' }}
              >
                ${priceWithCouponInDollar()}
              </Text>
              <Text
                fontSize={{ base: '11px', xl: 'sm' }}
                lineHeight={{ base: '10px', xl: '12px' }}
              >
                {priceLabelBottomText}
              </Text>
            </Box>
          ) : (
            <VStack
              position="absolute"
              bottom="24px"
              left="0"
              bgColor={status !== 'IN_STOCK' ? '#808080' : '#D537A8'}
              h={{ base: '45px', lg: '55px', xl: '60px' }}
              maxW={{ base: '80px', lg: '90px', xl: '120px' }}
              color="white"
              borderRightRadius={6}
              px={2}
              py={1}
              justifyContent="center"
              spacing={0}
            >
              <Text
                fontSize={{ base: 'xl', lg: '2xl', xl: '3xl' }}
                fontWeight="bold"
                lineHeight={{ base: '20px', lg: '24px', xl: '30px' }}
              >
                ${originalPriceInDollar}
              </Text>
              {priceLabelBottomText && (
                <Text fontSize={{ base: '11px', xl: 'sm' }} lineHeight="14px">
                  {priceLabelBottomText}
                </Text>
              )}
            </VStack>
          )}
        </VStack>
        <Box w={'100%'} style={{ marginBottom: '24px' }}>
          <UnorderedList>
            <ListItem textAlign="start" fontWeight="500">
              {description} {footnoteIndex >= 0 ? '*'.repeat(footnoteIndex + 1) : ''}
            </ListItem>
          </UnorderedList>
          {!isEmpty(cleanAdditionalDescriptionText) && (
            <Box
              className={styles.insert_text}
              dangerouslySetInnerHTML={{ __html: cleanAdditionalDescriptionText }}
            />
          )}
        </Box>
      </VStack>
      <HStack
        mb={4}
        gap={{ base: '15px', xl: '30px' }}
        justifyContent={isMobile ? 'center' : 'start'}
        w={'100%'}
        marginTop={'auto'}
      >
        <button
          className={styles.button}
          // eslint-disable-next-line react/no-unknown-property
          variant={'outline'}
          onClick={() => navigate(paymentPath)}
        >
          Find Out More
        </button>
        <button
          // eslint-disable-next-line react/no-unknown-property
          variant="contain"
          className={styles.button}
          onClick={addOneItemToBasket}
          disabled={status !== 'IN_STOCK'}
        >
          Choose Plan
        </button>
      </HStack>
    </VStack>
  )
}

export const Slider = ({ items, footnotes }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const isMobile = useMediaQuery('(max-width: 800px)')
  const isSmallerThan700 = useMediaQuery('(max-width: 832px)')

  const onStartChange = useCallback(
    (from, to) => {
      typeof activeIndex === 'number' && setActiveIndex(to)
    },
    [items, footnotes]
  )

  const properties = {
    duration: 5000,
    autoplay: false,
    transitionDuration: 1000,
    arrows: items.length > (isMobile ? 1 : 3),
    infinite: true,
    easing: 'ease',
    prevArrow: ArrowPrev,
    nextArrow: ArrowNext,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  if (items.length === 0) {
    return (
      <Flex w={'100%'} alignItems={'center'} justifyContent={'center'} py="9%" px="8%">
        <CircularProgress isIndeterminate color="var(--accent)" />
      </Flex>
    )
  }

  return (
    <Box mt={'30px'} maxW={'84vw'} id={'product-slider'}>
      <Box className="slide-container" w="100%" minW={'1vw'}>
        <Slideshow {...properties} onChange={onStartChange}>
          {items?.map((card) => (
            <Box
              key={card?.id}
              px={isSmallerThan700 ? 0.5 : 2.5}
              display="flex"
              h="100%"
              justifyContent="center"
            >
              <Slide
                key={card?.id}
                card={card}
                footnoteIndex={footnotes.find(({ id }) => id === card?.id)?.index}
              />
            </Box>
          ))}
        </Slideshow>
      </Box>
    </Box>
  )
}
